import React, { FC } from "react";

import { SxProps, Theme } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { ReactComponent as IconSearch } from "assets/images/icon_search.svg";
import { ReactComponent as DeleteInput } from "assets/images/icon_delete_input.svg";

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  clearValue: () => void;
  placeholder?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}

const SearchInput: FC<Props> = (props) => {
  const {
    value,
    fullWidth,
    placeholder,
    sx,
    onChange,
    onBlur,
    onKeyDown,
    clearValue,
  } = props;

  return (
    <TextField
      fullWidth={fullWidth}
      sx={{ ...styles.input, ...sx }}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      InputProps={{
        endAdornment: !value ? (
          <InputAdornment position="end">
            <IconSearch />
          </InputAdornment>
        ) : (
          <DeleteInput style={{ cursor: "pointer" }} onClick={clearValue} />
        ),
      }}
      {...(onBlur && { onBlur })}
      {...(onKeyDown && { onKeyDown })}
    />
  );
};

const styles = {
  input: {
    backgroundColor: "white",
  },
};

export default SearchInput;
