import React, { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Paper } from "./styles";
import { ReactComponent as IconRemove } from "assets/images/icon_remove.svg";
import { ReactComponent as IconAdd } from "assets/images/icon_add.svg";

import { getPriceFormat } from "shared/functions";
import CashDialog, {
  CashDialogType,
} from "../RegisterActionsModals/CashDialog";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

const BalanceBlock: React.FC = () => {
  const workShift = useSelector((state) => state.workShift.workShift);
  const cashTotalAmount = getPriceFormat(workShift?.cashTotalAmount || 0);
  const [type, setType] = useState(CashDialogType.addCash);
  const [modalOpen, setModalOpen] = useState(false);

  const onAddCash = () => {
    setType(CashDialogType.addCash);
    analytics().sendEvent(AnalyticsEvents.sales_add_cash_btn);
    setModalOpen(true);
  };

  const onRemoveCash = () => {
    setType(CashDialogType.removeCash);
    analytics().sendEvent(AnalyticsEvents.sales_remove_cash_btn);
    setModalOpen(true);
  };

  const onModalClose = () => {
    analytics().sendEvent(
      type === CashDialogType.addCash
        ? AnalyticsEvents.sales_add_cash_modal_cancel
        : AnalyticsEvents.sales_remove_cash_modal_cancel
    );
    setModalOpen(false);
  };

  return (
    <>
      <Box width={{ xs: "90%", sm: "48%", md: "35%" }}>
        <Paper variant="outlined">
          <Box>
            <Typography variant="body2">Готівка в касі:</Typography>
            <Typography variant="h5">{cashTotalAmount} ₴</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            alignItems={{ xs: "flex-start", lg: "center" }}
            justifyContent="space-between"
            pt={1}
          >
            <Button
              size="medium"
              variant="text"
              color="secondary"
              startIcon={<IconRemove />}
              onClick={() => onRemoveCash()}
            >
              Вилучити готівку
            </Button>
            <Button
              size="medium"
              variant="text"
              color="secondary"
              startIcon={<IconAdd />}
              onClick={() => onAddCash()}
            >
              Внести готівку
            </Button>
          </Box>
        </Paper>
      </Box>
      <CashDialog
        type={type}
        isOpenModal={modalOpen}
        onCloseModal={onModalClose}
      />
    </>
  );
};

export default BalanceBlock;
