/* tslint:disable */
/* eslint-disable */
/**
 * FOPkasa.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CashRegisterMonoQrTerminalDto } from '../models';
import { CreateCashRegisterQrTerminalRequestDto } from '../models';
import { GetCashRegisterQrTerminalsResponseDto } from '../models';
import { GetMonoQrTerminalsResponseDto } from '../models';
import { GetQrTerminalsRequestDto } from '../models';
import { ProblemDetails } from '../models';
import { UpdateCashRegisterQrTerminalRequestDto } from '../models';
/**
 * MonoAcquiringApi - axios parameter creator
 * @export
 */
export const MonoAcquiringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete: async (cashRegisterTerminalUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashRegisterTerminalUid' is not null or undefined
            if (cashRegisterTerminalUid === null || cashRegisterTerminalUid === undefined) {
                throw new RequiredError('cashRegisterTerminalUid','Required parameter cashRegisterTerminalUid was null or undefined when calling apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete.');
            }
            const localVarPath = `/api/acquiring/mono/cash-register-qr-terminals/{cashRegisterTerminalUid}`
                .replace(`{${"cashRegisterTerminalUid"}}`, encodeURIComponent(String(cashRegisterTerminalUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet: async (cashRegisterTerminalUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashRegisterTerminalUid' is not null or undefined
            if (cashRegisterTerminalUid === null || cashRegisterTerminalUid === undefined) {
                throw new RequiredError('cashRegisterTerminalUid','Required parameter cashRegisterTerminalUid was null or undefined when calling apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet.');
            }
            const localVarPath = `/api/acquiring/mono/cash-register-qr-terminals/{cashRegisterTerminalUid}`
                .replace(`{${"cashRegisterTerminalUid"}}`, encodeURIComponent(String(cashRegisterTerminalUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {UpdateCashRegisterQrTerminalRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut: async (cashRegisterTerminalUid: string, body?: UpdateCashRegisterQrTerminalRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashRegisterTerminalUid' is not null or undefined
            if (cashRegisterTerminalUid === null || cashRegisterTerminalUid === undefined) {
                throw new RequiredError('cashRegisterTerminalUid','Required parameter cashRegisterTerminalUid was null or undefined when calling apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut.');
            }
            const localVarPath = `/api/acquiring/mono/cash-register-qr-terminals/{cashRegisterTerminalUid}`
                .replace(`{${"cashRegisterTerminalUid"}}`, encodeURIComponent(String(cashRegisterTerminalUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcquiringMonoCashRegisterQrTerminalsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/acquiring/mono/cash-register-qr-terminals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCashRegisterQrTerminalRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcquiringMonoCashRegisterQrTerminalsPost: async (body?: CreateCashRegisterQrTerminalRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/acquiring/mono/cash-register-qr-terminals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetQrTerminalsRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcquiringMonoQrTerminalsPost: async (body?: GetQrTerminalsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/acquiring/mono/qr-terminals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonoAcquiringApi - functional programming interface
 * @export
 */
export const MonoAcquiringApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete(cashRegisterTerminalUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await MonoAcquiringApiAxiosParamCreator(configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete(cashRegisterTerminalUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet(cashRegisterTerminalUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CashRegisterMonoQrTerminalDto>>> {
            const localVarAxiosArgs = await MonoAcquiringApiAxiosParamCreator(configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet(cashRegisterTerminalUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {UpdateCashRegisterQrTerminalRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut(cashRegisterTerminalUid: string, body?: UpdateCashRegisterQrTerminalRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await MonoAcquiringApiAxiosParamCreator(configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut(cashRegisterTerminalUid, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetCashRegisterQrTerminalsResponseDto>>> {
            const localVarAxiosArgs = await MonoAcquiringApiAxiosParamCreator(configuration).apiAcquiringMonoCashRegisterQrTerminalsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateCashRegisterQrTerminalRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsPost(body?: CreateCashRegisterQrTerminalRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await MonoAcquiringApiAxiosParamCreator(configuration).apiAcquiringMonoCashRegisterQrTerminalsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetQrTerminalsRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoQrTerminalsPost(body?: GetQrTerminalsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetMonoQrTerminalsResponseDto>>> {
            const localVarAxiosArgs = await MonoAcquiringApiAxiosParamCreator(configuration).apiAcquiringMonoQrTerminalsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MonoAcquiringApi - factory interface
 * @export
 */
export const MonoAcquiringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete(cashRegisterTerminalUid: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return MonoAcquiringApiFp(configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete(cashRegisterTerminalUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet(cashRegisterTerminalUid: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CashRegisterMonoQrTerminalDto>> {
            return MonoAcquiringApiFp(configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet(cashRegisterTerminalUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cashRegisterTerminalUid 
         * @param {UpdateCashRegisterQrTerminalRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut(cashRegisterTerminalUid: string, body?: UpdateCashRegisterQrTerminalRequestDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return MonoAcquiringApiFp(configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut(cashRegisterTerminalUid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsGet(options?: AxiosRequestConfig): Promise<AxiosResponse<GetCashRegisterQrTerminalsResponseDto>> {
            return MonoAcquiringApiFp(configuration).apiAcquiringMonoCashRegisterQrTerminalsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCashRegisterQrTerminalRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoCashRegisterQrTerminalsPost(body?: CreateCashRegisterQrTerminalRequestDto, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return MonoAcquiringApiFp(configuration).apiAcquiringMonoCashRegisterQrTerminalsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetQrTerminalsRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcquiringMonoQrTerminalsPost(body?: GetQrTerminalsRequestDto, options?: AxiosRequestConfig): Promise<AxiosResponse<GetMonoQrTerminalsResponseDto>> {
            return MonoAcquiringApiFp(configuration).apiAcquiringMonoQrTerminalsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonoAcquiringApi - object-oriented interface
 * @export
 * @class MonoAcquiringApi
 * @extends {BaseAPI}
 */
export class MonoAcquiringApi extends BaseAPI {
    /**
     * 
     * @param {string} cashRegisterTerminalUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonoAcquiringApi
     */
    public async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete(cashRegisterTerminalUid: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return MonoAcquiringApiFp(this.configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete(cashRegisterTerminalUid, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} cashRegisterTerminalUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonoAcquiringApi
     */
    public async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet(cashRegisterTerminalUid: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CashRegisterMonoQrTerminalDto>> {
        return MonoAcquiringApiFp(this.configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidGet(cashRegisterTerminalUid, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} cashRegisterTerminalUid 
     * @param {UpdateCashRegisterQrTerminalRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonoAcquiringApi
     */
    public async apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut(cashRegisterTerminalUid: string, body?: UpdateCashRegisterQrTerminalRequestDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return MonoAcquiringApiFp(this.configuration).apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut(cashRegisterTerminalUid, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonoAcquiringApi
     */
    public async apiAcquiringMonoCashRegisterQrTerminalsGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<GetCashRegisterQrTerminalsResponseDto>> {
        return MonoAcquiringApiFp(this.configuration).apiAcquiringMonoCashRegisterQrTerminalsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CreateCashRegisterQrTerminalRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonoAcquiringApi
     */
    public async apiAcquiringMonoCashRegisterQrTerminalsPost(body?: CreateCashRegisterQrTerminalRequestDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return MonoAcquiringApiFp(this.configuration).apiAcquiringMonoCashRegisterQrTerminalsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {GetQrTerminalsRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonoAcquiringApi
     */
    public async apiAcquiringMonoQrTerminalsPost(body?: GetQrTerminalsRequestDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetMonoQrTerminalsResponseDto>> {
        return MonoAcquiringApiFp(this.configuration).apiAcquiringMonoQrTerminalsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
