import blog1 from 'assets/images/blog1.jpg';
import blog2 from 'assets/images/blog2.jpg';
import blog3 from 'assets/images/blog3.jpg';
import blog4 from 'assets/images/blog4.jpg';
import blog5 from 'assets/images/blog5.jpg';
import blog7 from 'assets/images/blog7.jpg';
import blog8 from 'assets/images/blog8.jpg';
import blog9 from 'assets/images/blog9.jpg';
import blog10 from 'assets/images/blog10.jpg';
import blog11 from 'assets/images/blog11.jpg';
import blog12 from 'assets/images/blog12.jpg';
import blog13 from 'assets/images/blog13.jpg';
import blog14 from 'assets/images/blog14.jpg';
import blog15 from 'assets/images/blog15.jpg';
import blog16 from 'assets/images/blog16.jpg';
import blog17 from 'assets/images/blog17.jpg';
import blog18 from 'assets/images/blog18.jpg';
import blog19 from 'assets/images/blog19.jpg';
import blog20 from 'assets/images/blog20.jpg';
import blog21 from 'assets/images/blog21.jpg';
import blog22 from 'assets/images/blog22.jpg';

import blogItem1 from 'assets/images/blog1item.jpg';
import blogItem2 from 'assets/images/blog2item.jpg';
import blogItem3 from 'assets/images/blog3item.jpg';
import blogItem4 from 'assets/images/blog4item.jpg';
import blogItem5 from 'assets/images/blog5item.jpg';
import blogItem7 from 'assets/images/blog7item.jpg';
import blogItem8 from 'assets/images/blog8item.jpg';
import blogItem9 from 'assets/images/blog9item.jpg';
import blogItem10 from 'assets/images/blog10item.jpg';
import blogItem11 from 'assets/images/blog11item.jpg';
import blogItem12 from 'assets/images/blog12item.jpg';
import blogItem13 from 'assets/images/blog13item.jpg';
import blogItem14 from 'assets/images/blog14item.jpg';
import blogItem15 from 'assets/images/blog15item.jpg';
import blogItem16 from 'assets/images/blog16item.jpg';
import blogItem17 from 'assets/images/blog17item.jpg';
import blogItem18 from 'assets/images/blog18item.jpg';
import blogItem19 from 'assets/images/blog19item.jpg';
import blogItem20 from 'assets/images/blog20item.jpg';
import blogItem21 from 'assets/images/blog21item.jpg';
import blogItem22 from 'assets/images/blog22item.jpg';

export type Article = {
  articleId: number;
  image: string;
  imageSmall: string;
  title: string;
  date: string;
  description: string;
  chips:  string[]
}

const articles: Article[] = [
  {
    articleId: 22,
    image: blog22,
    imageSmall: blogItem22,
    title: 'Твоя ФОП-історія',
    date: '11 жовтня 2024',
    description: `
    ФОП-історії в 2024 році — це не лише про розвиток бізнесу, масштабування та креативні ідеї. Повномасштабне вторгнення внесло надскладні виклики і для наших клієнтів, що знаходились або перебувають досі в зоні небезпеки, там постало питання виживання не лише бізнесу, але і його безпосередніх учасників.
    
    Розмова з Володимиром Савраном — професійним фотографом та підприємцем вийшла дуже символічною. Він поділився становленням бізнесу в Україні як свідок тих подій, а також розповів про надскладний досвід виживання в оточенні в 2022 році.
    
    Не зважаючи на усі труднощі, сьогодні бізнес пана Володимира працює разом із ФОП-каса. Запрошуємо до прочитання.

     Чи могли б Ви розповісти трохи про свій бізнес — з чого починали, як довго на ринку, у якому місті зараз знаходитесь? 

    — Розпочинав як фотожурналіст — спецкор в зоні Чорнобильської катастрофи з 1986 по 1994 роки. Після цього заснував та став більд редактором журналу «Вогні Славутича» до 2000 року. З 2000 року редакція журналу призупинила свою діяльність, а на його базі було створено фото салон «Kodak Express» з фотолабораторією, фотоательє і магазином фототоварів. Зараз надаємо фото послуги.
    (Бізнес Володимира знаходиться у місті Славутич за 10 км від Білорусі — прим. автора)

    
    — Поділіться, будь ласка, як у ці останні 24 роки розвивалась Ваша справа:

    — Мені довелося бути присутнім на всіх важливих політичних подіях в Україні, зокрема в Києві. Дев'яності роки були особливою епохою в розвитку бізнесу в країні. Це час, про який можна говорити безкінечно. Грошей практично не було, натомість процвітав бартер, взаємозаліки за електроенергію, рекет тощо. Згодом бізнес почав набувати цивілізованих форм. Проте бандитський рекет поступово трансформувався в державний. У будь-який момент на підприємство могла "наїхати" податкова інспекція з обласного центру і накласти штраф. Причини для цього завжди знаходилися.

    Держава не сприяла розвитку бізнесу, натомість лише його грабувала. Бізнес виживав не завдяки державній підтримці, а всупереч їй. Окрім податкової, підприємців "доїли" пожежні інспекції, санепідемстанції, місцеві чиновники тощо. Після запровадження мораторію на перевірки стало трохи легше працювати.

    Таке життя — виживають найсильніші та наймудріші.

    
    — Як Ви почуваєтесь як власник бізнесу зараз у 2024? Що не дає здатись і виїхати (тим паче, якщо локалізація така небезпечна)?
    
    — У 2022 році місто опинилося в оточенні, без світла, але з теплом і водою. Їжу готували на багаттях у дворах. Місто ніколи раніше не було настільки згуртованим, як під час блокади. Люди допомагали один одному, як могли.

    Знаходили можливість заряджати гаджети, пекли хліб із кукурудзяного зерна, змішаного з ячменем. Розподіляли пайок — 250 грамів на сім’ю з трьох людей. Картоплі та молока було достатньо, як і шампіньйонів. У такому режимі місто протрималося понад місяць. Потім, коли окупанти відступили, почали налагоджувати доставку гуманітарної допомоги через партизанські лісові дороги. Головне — ніхто не втрачав духу. Усі працювали, виконували будь-яку роботу, яку могли. Ті, хто не витримував, виїжджали при першій можливості. Моя сім'я теж могла виїхати, але ми одразу відмовилися від цієї ідеї. Єдина мотивація — підтримувати один одного. Ми стали єдиною командою: четверо дорослих і двоє маленьких дітей, молодшій лише чотири місяці.

    Сподівалися лише на одне — що у окупантів вистачить здорового глузду не обстрілювати місто, яке обслуговує Чорнобильську АЕС. Зміну на ЧАЕС переправляли на рибальських човнах через територію Білорусі. Там були адекватні люди, які розуміли ситуацію і допомагали з ротацією персоналу на станції.

    Зараз у місті проживає приблизно третина довоєнного населення, плюс тимчасово переселені із Запорізької АЕС. Переважно це персонал, що обслуговує ЧАЕС і підприємства в зоні відчуження, їхні родини та працівники, які підтримують життєдіяльність міста. Усі чотири школи працюють, дитячі садочки заповнені, функціонують школа мистецтв і спортивні майданчики. Вся інфраструктура міста діє.

    
    — Зараз Ви відновили роботу фотоательє і стали клієнтом ФОПкаси. Як давно Ви з нами і чому вирішили приєднатись саме до цього ПРРО?

    — Став клієнтом ФОПкаси одразу після того, як уряд ухвалив постанову про обов'язкове використання ПРРО для підприємців другої групи. Розглядав різні варіанти, але ФОПкаса виявилася найзручнішою. Вразила швидка і зрозуміла комунікація, а також можливість протестувати сервіс у тестовому режимі. Каса завжди зі мною у телефоні, працювати з нею зручно та комфортно. Мені подобається!

    
    — Чи були у Вас якісь труднощі за цей час, повʼязані із сервісом? Як працювала служба підтримки, якщо таке мало місце?

    — Служба підтримки завжди була вчасно і оперативно, коли виникали якісь питання. Але це було рідко. За ввесь час 2-3 рази. Все доволі ясно і зрозуміло.
    
    
    — Як Ваші клієнти відгукуються про такий сервіс оплати? Чи швидко звикли до таких змін?
    
    — Досить швидко. Чек бачать, але відмовляються від надсилання. Лише двічі надсилав одному і тому ж клієнту, щоб було підтвердження для його дружини.

    `,  
    chips: ['Про наш ПРРО'],
  },  

  {
    articleId: 21,
    image: blog21,
    imageSmall: blogItem21,
    title: 'Основи касової дисципліни для проботи з ПРРО ФОПкаса',
    date: '27 вересня 2024',
    description: `
     Запорука успіху в бізнесі — компетентність. Саме тому ми стараємось інформувати про всі особливості роботи з ПРРО, щоб ви були в контексті змін та адаптовували свої справи відповідно до них.
     
     Згідно з наказом Міністерства фінансів України № 547 "Про затвердження порядків щодо реєстрації реєстраторів розрахункових операцій та книг обліку розрахункових операцій", внесення чи видача готівки з місця проведення розрахунків повинні реєструватися через РРО з використанням операцій «службове внесення» та «службова видача», якщо такі внесення чи видача не пов’язані з проведенням розрахункових операцій:
      — операція «службове внесення» використовується для реєстрації суми готівки, яка зберігається на місці проведення розрахунків на момент реєстрації першої розрахункової операції, що проводиться після виконання Z-звіту;
      — операція «службова видача» використовується для реєстрації суми готівки, яка вилучається з місця проведення розрахунків та/або видається держателям електронних платіжних засобів.
      
    Якщо коротко, то сума готівки в фізичній касі на місці проведення розрахунків має збігатися з сумою готівки в програмній касі. Якщо протягом або в кінці робочого дня готівка з каси забирається, то потрібно зробити службову видачу готівки в ПРРО перед закриттям зміни.

    Функція Вилучення і внесення готівки знаходиться в розділі Операції з касою в мобільному додатку ФОПкаса.

    `,  
    chips: ['Про наш ПРРО'],
  },  
  {
    articleId: 20,
    image: blog20,
    imageSmall: blogItem20,
    title: 'monobank & ФОПкаса - online термінал у ПРРО',
    date: '1 серпня 2024',
    description: `Loading article...`,
    chips: ['Про наш ПРРО'],
  },    
  {
  articleId: 19,
  image: blog19,
  imageSmall: blogItem19,
  title: 'ПРРО - це контроль всіх процесів',
  date: '24 червня 2024',
  description: `
  Історія цього бізнесу розпочалась на домашній кухні за приготуванням обідів для чоловіка та його працівників, сьогодні ж Любов Миколаївна — підприємиця, що вже 15 років забезпечує комплексними обідами корпоративний сектор.
  Так само, як хатня плита змінилась на велику кухню із найновішою технікою, так само і діджиталізувалась робота із видачею чеків.

  З 2023 Любов Миколаївна вирішила перейти на ПРРО та долучилась до користувачів застосунку ФОПкаса:

  «Дуже зручно, що може бути все в телефоні. Так як бізнес в нас мобільний, то стаціонарну касу незручно ставити.»

  Більшість клієнтів підприємиці — це постійні покупці, тож співрозмовниця не приховує:

  «Вони були здивовані. В процесі переходу мене теж найбільше хвилювало, чи зможу я навчитися працювати в ПРРО.»

  Досвідченим підприємцям справді буває складно зважитись на ПРРО, адже протягом десятиліть на ринку, вони стали свідками чималих змін, нововведень та систем.

  Тому для нас важливо створити комфортні умови.

  «Були питання про те, як зареєструвати в податковій, як робити звіти. Але ваші менеджери дуже мені допомогли. Тестовий період пройшов супер, швидко навчилась, було дуже цікаво.»

  Ми запитали Любов Миколаївну про те, які аспекти ФОПкаси найбільш зручні для ведення її підприємницької діяльності:

  «Те, що каса може закриватися автоматично, потрібно лише налаштувати, і те, що легко можна змінювати ціну і додавати продукцію. Нещодавно мене запитали, чи потрібен комп’ютер для того, щоб мати касу. Я сказала, що потрібен лише в деяких аспектах при налаштуванні. І, звичайно, приємна ціна абонементу. Я дуже задоволена: є допомога менеджерів в усьому, швидкість відповіді на всі мої запитання і постійна підтримка.»

  `,
  chips: ['Про наш ПРРО'],
  },  
  { 
    articleId: 18,
    image: blog18,
    imageSmall: blogItem18,
    title: 'ПРРО - тренд в Україні',
    date: '30 квітня 2024',
    description: `
      У новій матриці цінностей українців відбуваються вагомі зміни.

      Волонтерство стає нормою кожного свідомого громадянина, корупційні схеми все частіше стають публічно зруйнованими, а менеджмент поступово діджиталізується — держслужбовців замінюють нові системи.

      Звісно, підприємці теж трансформуються не лише під впливом законодавчих ініціатив, але й ціннісно. Бізнес, що сплачує податки — це новий етикет у соціумі.

      Цей тренд — частина змін суспільства, яке орієнтується на західні практики і усвідомлює, що в часи криз — тільки за нами можлива стабільна підтримка армії, медицини та інших соціально-важливих структур.

      Наш застосунок серед нових інструментів для Ваших цінностей. Ми створюємо найкращий досвід ведення підприємницької діяльності, який не потребує ані великого ресурсу часу, ані високих чеків.

      Ви можете з гордістю управляти власним бізнесом не стресуючи перевірками чи надмірною бюрократією.

      Вибирайте майбутнє. Долучайтесь до клієнтів ПРРО ФОПкаса.
    
    `,
  chips: ['ПРРО'],
  },  
  {
    articleId: 17,
    image: blog17,
    imageSmall: blogItem17,
    title: 'Коли запитують - раджу ФОПкасу',
    date: '28 квітня 2024',
    description: `
      Кожного місяця Анна створює свято щонайменше для сотні людей. У цьому специфіка кондитера — кожен її виріб стає частиною радісної події, а клієнти задоволено залишають позитивні відгуки.

      Невідʼємною частиною роботи є не лише творча складова, але й систематизована робота з оплатами, які надходять від покупців. Ми розпитали Анну про її підприємницький досвід у рубриці «Твоя ФОП-історія» і запрошуємо читати далі.

      — Мій бізнес це — кондитерська-кафе. Працюємо з 2017 року. Коли вийшов закон про те, що потрібно ФОПам використовувати РРО, одразу прийняли рішення про зміни.

      — Чому обрали саме ПРРО «ФОПкаса»? 
      — Тестували різні програми. ФОПкаса сподобалась зручним інтерфейсом і ціна доступна. Користуємось вже приблизно 2 роки.
      
      — Чому підприємцям у Вашій ніші варто працювати з ПРРО? 
      — Це зручно, автоматично рахує дохід і, звісно, вимог законодавства потрібно дотримуватись.
      
      — Як швидко клієнти звикли до такого сервісу? Чи створило це якісь труднощі? 
      — Не відчули різниці.
      
      — Що вас найбільше хвилювало у процесі переходу на ПРРО? 
      — Найбільше хвилювало те, щоб не зробити помилку під час видачі чеку, бо він надійде в ДПС.
      
      — Які аспекти нашого продукту вам здаються найбільш зручними чи корисними? 
      — Якщо з'являється нова позиція чи товар, то її швидко можна додати в базу.
    `,
  chips: ['Про наш ПРРО'],
  },  
  {
    articleId: 16,
    image: blog16,
    imageSmall: blogItem16,
    title: 'Твоя ФОП-історія',
    date: '08 квітня 2024',
    description: `
    Нашим застосунком користуються підприємці з дуже різних та однаково цікавих сфер. Хтось із них прийшов до цієї співпраці одразу, хтось тестував інші можливості, але кожен зацікавлений у веденні легального бізнесу та відповідального ставлення до клієнтів та органів контролю.

    Вважаємо, що такими прикладами варто ділитись, щоб мотивувати інших також долучатись. Тому започатковуємо нову рубрику: «Твоя ФОП-історія», у якій наші клієнти будуть ділитись досвідом та розповідати про усі виклики та здобутки з використанням ПРРО.    

    Історія Артема Классена розпочалась 7 років тому, коли він випадково опинився на сцені, і зрозумів, що хоче на ній залишитись. З того часу він провів вже понад 500 заходів. Бути ведучим — це робота 24/7 у постійній взаємодії з людьми на різних локаціях, тож ведення підприємницької діяльності має також свої особливості.

    — В який момент Ви відчули, що настав час для РРО-? — В 2022 році, коли ФОПів зобов'язали використовувати в роботі РРО, вирішив виконувати вимоги законодавства. До цього в своїй роботі використовував паперовий касовий ордер.

    — Чому обрали саме ФОПкаса? — Спочатку намагався зареєструвати інший ПРРО, але були запитання і підтримка довго не відповідала. В Google ввів „ПРРО”, і натрапив на ФОПкасу. Зв'язався з підтримкою, допомогли налаштувати. Перевага для мене в тому, що ПРРО саме в смартфоні — так його зручно і просто використовувати.

    Для підприємців, які мають постійні зміни локацій, працюють у різних містах, погоджують співпраці віддалено — такий формат must have.

    — Що вас найбільше хвилювало у процесі переходу на ПРРО? — Особливих страхів або переживань не було. Підтримую інновації, тому почати працювати з ПРРО було легко.

    — Як проходив тестовий період? — Певний час пробував тестововий режим. Додаток сподобався через мінімалізм та зручність, тому перейшов на тариф, і продовжую користуватись вже більше 2 років. Найбільш корисним є автоматичне закриття зміни. Можна налаштувати час і каса закриється автоматично, не переживаю про те, що забув закрити зміну і відправити звіт в ДПС.

    — Які питання у вас як підприємця виникали щодо ведення підприємницької діяльності чи введення ПРРО? — Було питання про те, як зареєструвати касира в ДПС та прив'язати його до каси. А також, коли закінчився термін дії КЕП, не знав, як зареєструвати новий ключ. Підтримка допомогла все налаштувати, проконсультували. Особливо сподобалось, що звернувся пізно ввечері, в неробочий час, а мені швидко відповіли.

    — Чому підприємцям у Вашій ніші варто працювати з ПРРО? — Усім підприємцям варто використовувати ПРРО, бо це просто, зручно та клієнтоорієнтовано.

    — Як швидко клієнти звикли до такого сервісу? — Клієнтам важлива якість послуги. А чек — приємний бонус. Вони кажуть, що це прикольно.

    Ось такий досвід Артема, який доводить — з ПРРО стає лише зручніше та комфортніше провадити підприємницьку діяльність. Залишились запитання? Наша служба турботи на звʼязку. Хочеш поділитись своєю ФОП-історією? Напиши нам.

    `,
  chips: ['Про наш ПРРО'],
  }, 
  {
    articleId: 15,
    image: blog15,
    imageSmall: blogItem15,
    title: 'ПРРО-застосунок ФОПкаса для підприємців - зручно та вигідно',
    date: '28 лютого 2024',
    description: `
    ФОПкаса – це зручний помічник для ведення бізнесу у будь-якому гаджеті: ПРРО, комунікації з клієнтами, інтеграція із сервісами та автоматизована взаємодія з ДПС.

    Як це працює?

    Ми зробили наш застосунок інтуїтивно зрозумілим та простим і, навіть якщо ти не ладнаєш із технікою чи бухгалтерією, з ФОПкасою нарешті зможеш розібратися та оптимізувати все це.

    Із нами зміни відкриваються в один клік і закриваються автоматично, помилки у Z-звітах не трапляються, а чеки можна миттєво надіслати клієнтам у повідомлення або підключити принтер та роздрукувати за потреби.
    
    Неважливо, чи ти сам виконуєш касові операції, чи є декілька кас та касирів — жодних обмежень чи додаткових оплат. З ПРРО ФОПкаса ви можете легко:

    - Cкасувати останню операцію;
    - Надати можливість клієнту повернути будь-який товар із чеку;
    - Відсканувати штрих-код товару для розрахунку (товар із даним кодом підтягнеться із бази автоматично);
    - Слідкувати за оновленнями і важливими сповіщеннями через push-повідомлення від застосунку;
    - Обрати будь-яку із представлених одиниць виміру товару.

    Як наш сервіс оцінять твої клієнти?

    Впевнені, їм сподобається можливість провести оплату у два кліки, відсканувавши QR-код. Також ти можеш зміцнювати ваші комунікації і будувати лояльну взаємодію: просто зберігай дані клієнтів та формуй базу, яку можна експортувати та використати для бізнес-розсилок.

    Яка ціна?

    Із тарифом «Успішний рік» ти отримаєш усю потужність застосунку всього за 1320 гривень на рік. З усіма іншими тарифами можеш ознайомитися на нашому сайті: Заощаджуй на ПРРО та інвестуй у свою справу більше.

    ФОПкаса – це зручна база товарів та увесь функціонал РРО в вашому смартфоні.

    Для отримання ПРРО завантаж застосунок за посиланням та отримай 2 місяці повноцінного користування усіма його функціями від нас у подарунок. Пізніше ти зможеш обрати зручний для себе тариф та продовжити нашу співпрацю.

    `,
  chips: ['Про наш ПРРО'],
  },  

  {
    articleId: 14,
    image: blog14,
    imageSmall: blogItem14,
    title: 'Національна стратегія доходів - зміни, які очікують ФОПів у 2024.',
    date: '26 січня 2024',
    description: `
    27 грудня 2023 року уряд ухвалив «Національну стратегію доходів». Основна ціль — допомогти бюджету України зменшити залежність від партнерів та налагодити фінансування потреб за рахунок внутрішніх ресурсів.
    
    Як вона вплине на підприємців?
    
    Попереду численні нововведення — реалізація усіх планується до 2030 року, проте деякі зміни будуть впроваджені вже зараз. Ми відібрали ті, які безпосередньо стосуються саме ФОП-категорії та важливі вже на сьогоднішній день.
    
    Найшвидше зміниться підхід до збору даних про платників податків — нас очікує деперсоніфікація інформації, яка має полегшити моніторинг фінансових операцій. Для більш ефективної роботи буде створено окрему IT-установу в Мінфіні.
    
    – РРО та ведення обліку стануть обовʼязковими для всіх, хто перебуває на спрощеній системі, тому рекомендуємо не зволікати і приймати рішення якнайшвидше, щоб уникнути проблем із відповідними органами і штрафів.
    
    – Перша група ФОПів більше не платитиме фіксовану суму податку, вона буде обчислюватись відсотком від доходу, також планується скорочення видів діяльності.
    
    – Третю групу ФОПів приєднають до другої та відбудеться підвищення диверсифікованих ставок — від 3 до 17% від доходу в залежності від виду діяльності.
    
    – Агровиробникам (ФОПам 4-ї групи) збільшать податок для того, щоб стимулювати перехід на загальну систему.
    
    – На юридичних осіб чекає поступове підняття податку до 18%, а згодом і повна заборона перебувати на спрощеній системі.
    
    – Податок на майно стягуватиметься в залежності від його оціночної вартості. Для цього реєстри поступово наповнюватимуться даними, а саме нововведення стане дієвим з 2027 або 2028 року.
    
    – Відбудеться підвищення акцизів на алкоголь, тютюнові вироби та пальне до мінімального рівня, який діє в ЄС, а також буде вивчатись варіант впровадження акцизу на солодкі напої.
    
    – Пільги та знижки. Підприємці зможуть отримувати відшкодування сплаченого податку на самостійне покращення житлових умов, лікування чи освіту.
    
    Для України такі зміни стануть кроком вперед на шляху вступу до ЄС, а також можливістю стабільного функціонування й налагодженого обліку ринку. І те, що можна зробити вже сьогодні – забезпечити свій бізнес потужним та швидким ПРРО застосунком — ФОПкасою.
    
    Продовжуємо тримати руку на пульсі та будемо інформувати про інші новини українського підприємництва.
    
      `,
  chips: ['Корисно знати'],
  },  
  {
    articleId: 13,
    image: blog13,
    imageSmall: blogItem13,
    title: 'Використовувати ПРРО ФОПкаса дуже просто',
    date: '20 грудня 2023',
    description: `
    Використовувати ПРРО ФОПкаса дуже просто. Наприклад, щоб видати фіскальний чек потрібно лише кілька кліків.

    Спочатку потрібно відкрити зміну та натиснути кнопку "Продаж товару". В пошуку ввести назву товару або послуги, відредагувати кількість та ціну, та обрати "Оплата". 
    - Якщо розрахунок з покупцем готівкою - виберіть спосіб оплати "Готівка", а у поле "Отримано" введіть суму, яку отримали від клієнта. 
    - Якщо розрахунок фізичною банківською карткою, спочатку здійсніть оплату через термінал, а після цього видайте чек в додатку, вибравши форму оплати "Картка". 
    - Також у ФОПкаса дуже зручно приймати безготівкові оплати через функцію MONO QR. Щоб прийняти оплату через MONO QR, натисніть відповідну кнопку. Натисніть “Видати чек”. Запропонуйте покупцю відсканувати QR код з екрану мобільного пристрою. Зауважте, якщо покупець є клієнтом Monobank, потрібно використати сканер у мобільному додатку Mono та підтвердити платіж. У іншому випадку покупець може скористатись камерою смартфону, як сканером. Далі необхідно ввести дані платіжної картки (номер, строк дії та cvc код) та натиснути кнопку “Оплатити”. 
    
    У випадку успішної оплати на екрані пристрою з ПРРО з’явиться фіскальний чек, який можна надіслати покупцю. Електронний чек можна передати клієнту через QR code або надіслати sms повідомленням за номером телефону.
    
    Щоб використати функцію знижок на товар чи послугу, просто додайте цю позицію у чек та натисніть кнопку "Надати знижку". Оберіть яку знижку потрібно зробити, доступні опції у відсотках чи у твердій сумі, зазначивши суму знижки. Зауважте, що товар зі знижкою потрібно продавати окремо. В іншому випадку знижку буде застосовано на всю суму чеку. 
    
    Для вашої зручності ФОПкаса розроблена так, що ви можете додавати новий товар одразу у чек, або ж можна обрати товар або послугу із попередньо заповненої бази товарів. Базу номенклатури товарів можна наповнювати двома способами. 
    - Перший спосіб - поступово, через додаток. Зайдіть у розділ "Товари". Натисніть на "Додати новий товар у базу".  Заповніть поля назва товару, код, ціна, одиниця виміру. Для зручності і класифікації товарів чи послуг ви можете додати власну категорію та властивості товару або обрати "Без категорії".
    - Другий спосіб - це в особистому кабінеті на сайті ФОПкаса імпортувати перелік товарів чи послуг з файлу. Для цього зайдіть в особистий кабінет на сайті ФОПкаса в розділ «Товари» та натисніть «Експорт товарів». В файлі, що завантажився заповніть усі поля, крім першої колонки «Ідентифікатор». Після цього, в кабінеті натисніть «Імпорт товарів» та завантажте заповнений файл.
    
    Іноді в роботі з ПРРО можуть виникати помилки. Якщо було видано не правилно чек, вказали не ту вартість, кількість чи помилились з формою оплати, скористайтесь функцією "Відміна останньої операції". У розділі "Операції з касою" оберіть "Скасувати операцію". Невірний чек буде скасовано, а ви зможете видати відкорегований ще раз.
    
    Повернення коштів за товар чи послугу також потрібно провести через ПРРО. У розділі "Операції з касою" виберіть "Повернення товару", вкажіть фіскальний номер чеку, з якого ви повертаєте позицію товару. Якщо сума повернення перевищує 100 грн. покупець повинен написати заяву на повернення товару, а ви повинні скласти відповідний акт. 
    
    Нагадуємо, що у ФОПкаса завжди доступний тестовий (нефіскальний) режим, де ще перед реєстрацією ПРРО на сайті ДПС, ви можете потренуватись у налаштуваннях чи навчити касирів оперувати потрібними функціями, переконавшись у простоті та зручності сервісу.
    
      `,
  chips: ['Про наш ПРРО'],
  },  
  {
    articleId: 12,
    image: blog12,
    imageSmall: blogItem12,
    title: 'Як використовувати в бізнесі інтеграцію ФОПкаса & Telegram?',
    date: '18 грудня 2023',
    description: `
    У цій статті хочемо продемонструвати на досвіді наших клієнтів — мережі кавʼярень KREDENS, яким чином працює інтеграція Telegram з ФОПкаса.
  
    Не секрет, що у бізнесі такого формату процеси розділені за різними людьми, відповідно до посади.

    Комунікація під час активної роботи потребує максимальної оптимізації, тож розповідаємо, як цього досягнути:
    
    1. Реєструємося в Telegram чи використовуємо вже існуючий профіль.
    2. Створюємо групу (канал), визначаємо її ID, додаємо в Telegram групу бота @fopkasabot.
    3. За потреби можемо створити декілька груп, що дасть можливість розподіляти продукти за типом, наприклад: їжа і напої, кухня і бар, і т.п.
    4. На сайті fopkasa.com у розділі "Канали" створюємо налаштування для кожної з груп: додаємо ID групи та обираємо категорії товарів, які будуть надсилатись в дану групу.
    
    Готово! Тепер, коли касир пробиває замовлення, кожен працівник зміни отримує про це повідомлення в Telegram і може відразу приступати до втілення.
    
    Додаткова перевага у тому, що до кожного замовлення касир може залишати додаткове повідомлення для команди, яке не буде відображатись гостям.

    Як розповідає команда KREDENS: «Таке рішення дуже спрощує комунікацію між Касою та Кухнею/Баром. Застосунок можна встановити на будь-якому ґаджеті і більше не писати замовлення на листочку чи передавати вголос, хвилюючись, чи все вірно почуто і чи про додаткові побажання гостя памʼятатимуть».
    
    Тепер кожного дня усі працівники без додаткових розмов знають свої завдання — щойно у додатку з’являється новий чек, у застосунку ФОПкаса вже формується відповідна інформація для кожної групи у Telegram.
    
    Бажаєте спробувати? Наша служба турботи на звʼязку, якщо знадобиться допомога.
          
  `,
  chips: ['Про наш ПРРО'],
  },  
  {
    articleId: 11,
    image: blog11,
    imageSmall: blogItem11,
    title: 'Додаткове поле у чеку для важливої інформації: як його використовують наші клієнти?',
    date: '28 листопада 2023',
    description: `
    Коли ми створювали цю функцію, то мали лише кілька ідей про те, яким чином можна використати цей простір — ми уявляли як це працюватиме у закладах харчування, де касир, що приймає замовлення може залишити нотатку про особливі побажання гостя про алергени, додаткові або зайві інгредієнти і т.д.
  
    Проте це додаткове порожнє поле, яке відображається лише касиру,  застосовується нашими клієнтами в абсолютно різних напрямах, тому вирішили поділитись прикладами:
    
    - У медичних закладах/клініках/кабінетах можна залишати інформацію про регулярність процедур, вподобання клієнта, обрані косметичні засоби чи препарати, або повторний огляд, про який варто нагадати пацієнту за певний час;
    - В освітніх закладах/репетиторства/курсах можна залишати інформацію про рівень студента та нагадування про комунікацію з ним пізніше щодо інших форматів навчання (спікінг-клаби, інтенсиви і т.д.);
    - В сфері краси/салонів/кабінетів можна позначати, скільки часу триватиме ефект від процедури, щоб нагадати клієнту про повторний запис;
    - Кав'ярні/бари або інші заклади, де є високий відсоток постійних клієнтів, можуть фіксувати уподобання клієнта, щоб щоразу не уточнювати деталі;
    - Івент-сфера може використовувати ці нотатки також у маркетингово-комунікаційних цілях – зафіксувати замовлення, яке було здійснене до Дня Народження, щоб через рік нагадати про сервіс, після зйомки заручин нагадувати про можливість весільної фотосесії і т.д., відповідно до сезонності;
    - У спортивних закладах у порожнє поле можна занотувати, за яке тренування/курс/абонемент здійснена оплата і до якого терміну погашена оплата;
    
    Яким чином усі ці дані можуть позитивно вплинути на ваші стосунки з клієнтами? Достатньо завантажити усі ці нотатки у таблицю, щоб налагодити індивідуальний підхід до кожного клієнта окремо, адже враховані побажання та зауваження ніколи не залишаються поза увагою споживача, тож це інструмент вибудови системної лояльності, водночас, якщо ви проаналізуєте дані і помітите схожості у виборі та вподобаннях аудиторії, то зможете не лише точково вдосконалювати обслуговування, але й змінити продукт під потреби користувача.
    
    Це лише кілька варіантів, завдяки яким ви можете зростати в очах клієнта як бізнес і надавати йому щоразу вищий сервіс, виявляти увагу до його потреб та вражати влучністю комунікацій.
      
  `,
  chips: ['Про наш ПРРО'],
  },  
  {
    articleId: 10,
    image: blog10,
    imageSmall: blogItem10,
    title: 'Яку інформацію має містити фіскальний чек, виданий ПРРО?',
    date: '15 листопада 2023',
    description: `
    Багатьох підприємців турбує питання, чи вважається фіскальний чек дійсним,якщо в ньому не відображено реквізити платіжного засобу, при оплаті карткою через платіжний термінал.
  
    Справді, є вимоги щодо того які реквізити повинен містити фіскальний чек. Вони перелічені у Наказі №13 від 21.01.2016 Міністерства фінансів України "Про Положення про форму та зміст розрахункових документів/електронних розрахункових документів".  І серед них такі як: 
      - ідентифікатор еквайра та торгівця або інші реквізити, що дають змогу їх ідентифікувати (рядок 11); 
      - ідентифікатор платіжного пристрою (рядок 12); 
      - сума комісійної винагороди (у разі наявності) (рядок 13); вид операції (рядок 14);
      - реквізити електронного платіжного засобу (платіжної картки), допустимі правилами безпеки платіжної системи, перед якими друкуються великі літери «ЕПЗ» (рядок 15);
      - напис «ПЛАТІЖНА СИСТЕМА» (найменування платіжної системи, платіжний інструмент якої використовується, код авторизації або інший код, що ідентифікує операцію в платіжній системі та/або код транзакції в платіжній системі, значення коду) (рядок 16);
      - підпис касира (якщо це передбачено правилами платіжної системи), перед яким друкується відповідно напис «Касир» (рядок 17);
      - підпис держателя електронного платіжного засобу (платіжної картки) (якщо це передбачено правилами платіжної системи) в окремих рядках, перед якими друкуються відповідно написи «Касир» та «Держатель ЕПЗ» (рядок 18).
  
    Однак, у п.4 розділ ІІ цього документу зазначається "Рядки 11-18 фіскального чека заповнюються у разі застосування під час проведення розрахунків з використанням електронного платіжного засобу (платіжної картки) платіжного терміналу, з’єднаного або поєднаного з реєстратором розрахункових операцій."  Законодавство не зобов'язує підприємців поєднувати ПРРО та платіжний термінал.
    
    Отже, якщо ПРРО поєднано з терміналом рядки 11-18 мають бути заповнені у фіскальному чеку. Якщо ж ПРРО не поєднано з терміналом, то ці реквізити можна не вказувати і такий фіскальний чек вважається дійсним.
    У серпні 2021 року набули чинності вимоги до форми та змісту фіскальних чеків. Тепер, незалежно від форми реєстратора який їх видає — апаратний  чи програмний, вони мають обов’язково містити: 
    - Назву суб’єкта господарювання;
    - Зазначену валюту розрахункової операції;
    - Назву платіжної системи;
    - QR-коду (не обов’язково).
  
    QR значно полегшують надсилання чеків покупцям. Клієнт може зісканувати код з телефону продавця чи отримати код у повідомленні. 
  
    На чеках, які видає ФОПкаса є вся необхідна інформація, а QR-коди містять обов’язкові: 
    - код автентифікації повідомлення (МАС) цього чеку; 
    - дату та час здійснення розрахункових операцій; 
    - фіскальний номер касового чека/фіскальний номер електронного касового чека в межах зміни; 
    - суму розрахункової операції; 
    - фіскальний номер ПРРО, на якому він сформований.
  
    ПРРО ФОПкаса не поєднується з платіжними терміналами. Це дає можливість підприємцю самостійно обирати який платіжний термінал обрати для роботи та використовувати паралельно з ПРРО.
  
    Важливо! Продаючи товар, обов’язково зазначте його повну назву. Чек, який не містить правильної назви товару, вважається невиданим, а ви ризикуєте отримати штраф на його повну вартість. 
     
      
    `,
    chips: ['Корисно знати'],
    },
    {
    articleId: 9,
    image: blog9,
    imageSmall: blogItem9,
    title: 'ФОПкаса & Monobank: онлайн термінал у ФОПкаса без додаткової плати',
    date: '10 листопада 2023',
    description: `
    Раніше для проведення безготівкової оплати, користувачам доводилось здійснювати цю операцію через термінал або шляхом здійснення платежу на рахунок підприємця, але тепер ми оптимізували цю функцію шляхом інтеграції застосунку ФОПкаса з Монобанк.

    Тепер можна легко оплачувати товари та послуги через онлайн-термінал Монобанку за допомогою QR-коду та одержувати миттєвий електронний фіскальний чек.

    Оплата через mono QR-код максимально інтуїтивна та не потребує спеціальної підготовки: потрібно додати товар або послугу у чек, обрати кількість, натиснути “Оплата” та обрати “МОНО QR”. 
    Використання цієї системи не вимагає додаткової щомісячної оплати за оренду терміналу (лише плата за еквайринг згідно тарифів монобанку, вартість якого ідентична до еквайрингу в звичайних терміналах). 

    Цей спосіб працює з карткою будь-якого банку, кошти швидко надходять на рахунок, а ви одразу отримуєте про це сповіщення. 
    Оскільки іноді фізичні банківські термінали з різних причин не працюють, такий онлайн-спосіб – це додаткова зручність для бізнесу. Ніяких додаткових оплат, проте завжди є план “Б” для роботи.

    І головне: вам не доведеться довго очікувати на впровадження цього оновлення — воно вже доступне у застосунку і налаштування займають лічені хвилини онлайн. 
    ФОПкаса — зручні рішення з ПРРО для українського малого бізнесу. 

  `,
    chips: ['Про наш ПРРО'],
  },
  {    
    articleId: 8,
    image: blog8,
    imageSmall: blogItem8,
    title: 'Переваги використання хмарної каси перед стаціонарними касовими апаратами',
    date: '01 листопада 2023',
    description: `
    У світі бізнесу постійно відбуваються зміни, і важливо пристосовуватися до них, щоб зберегти конкурентоспроможність і покращити ефективність роботи. Однією з таких змін є перехід до нових технологій у галузі обліку та фінансового управління. 

    Хмарна каса, онлайн каса, програмний реєстратор розрахункових операцій(ПРРО) -  це все назви однієї і тієї ж технології ведення обліку без використання стаціонарного касового апарату.  Дана технологія стала важливим інструментом для підтримки бізнесу, а також для спрощення процесу обліку та звітності. У цій статті ми розглянемо переваги використання цих продуктів порівняно зі стаціонарними касовими апаратами.
    
    1. Гнучкість та мобільність:
       Хмарна каса дозволяє здійснювати розрахункові операції звідусіль, де є доступ до Інтернету. Це особливо важливо для бізнесів, які працюють на виїзді, на ринках або на ярмарках. За допомогою онлайн каси, можна легко виписувати чеки та здійснювати фіскальні операції в реальному часі та незалежно від місця перебування.
    
    2. Зменшення адміністративних витрат:
       За допомогою програмного реєстратора розрахункових операцій можна автоматизувати процеси обліку та звітності. Це допомагає підприємцям зменшити витрати на обслуговування касового обладнання та утримання персоналу. 
    
    3. Оновлення та забезпечення безпеки даних:
       В світі технологій зміни відбуваються дуже швидко, і хмарні сервіси автоматично оновляються для вдосконалення їх функціональності. Інформація, збережена у хмарній касі, захищена від втрати та несанкціонованого доступу завдяки сучасним методам шифрування.
    
    4. Зручність для клієнтів:
       Клієнти можуть сплачувати за товари та послуги безпосередньо через інтернет, одразу отримуючи електронні квитанції та рахунки. Це дозволяє підвищити якість та швидкість обслуговування.
    
    5. Можливість інтеграції з іншими системами:
       Багато програмних рішень для реєстрації розрахункових операцій підтримують інтеграцію з іншими системами, такими як облік товарів, складський облік, бухгалтерський облік та інші. Це дозволяє оптимізувати бізнес-процеси та зменшити ризик помилок в обліку.
    
    6. Віддалений доступ та контроль:
       Завдяки віддаленому доступу до хмарної каси, власник може контролювати свій бізнес, не перебуваючи безпосередньо в офісі чи торговій точці. Наприклад, перебуваючи у відпустці чи відрядження, можна перевірити всі розрахункові операції та звітність, відкрити чи закрити зміну за декілька кліків у смартфоні. 
    Всі дані зберігаються в електронному вигляді, тож доступні для перегляду та аналізу в будь-який момент.
    
    У підсумку, використання хмарної каси (програмного реєстратора розрахункових операцій, онлайн каси) має численні переваги порівняно зі стаціонарними касовими апаратами. Вона допомагає бізнесу зберегти конкурентоспроможність, зменшити витрати та підвищити зручність для клієнтів. 
    
    Хмарна каса -  необхідний та простий інструмент для будь-якого сучасного підприємства.
    
`,
    chips: ['Корисно знати'],
  },
  {
    articleId: 0,
    image: blog7,
    imageSmall: blogItem7,
    title: 'Додаток для IOS/Android та веб-сайт ФОПкаса. Як вони повʼязані?',
    date: '19 травня 2023',
    description: `
Чому крім встановленого на ваш мобільний пристрій додатку деякі функції доступні лише через веб-сайт?               

Назагал, програмний РРО ФОПкаса складається з двох основних частин — мобільного додатку для Android чи iPhone (iPad) та Особистого кабінету на сайті ФОПкаси https://fopkasa.com/.

Нагадаємо, що для реєстрації будь-якого ПРРО необхідно, щоб дані підприємця в додатку ФОПкаса синхронізувались  із даними в кабінеті платника податку на сайті ДПС. Для цього використовується Кваліфікований Електронний Підпис (КЕП). Через Особистий кабінет клієнти ФОПкаси - підприємці - найперше — завантажують свій КЕП для інтеграції з ДПС та подальшого миттєвого передавання даних з додатка на сервер податкової. Тобто, для роботи у фіскальному режимі.

Після  процедури реєстрації ПРРО в електронному кабінеті платника податку та встановлення додатку ФОПкаса на мобільний пристрій підприємець може розпочинати роботу з продажу товарів та послуг.  

Додаток необхідно встановити на пристрій, яким користуватиметься касир в місці здійснення продажу товарів або послуг. Певною мірою, це аналог апаратного реєстратора розрахункових операцій (касового апарата). Тобто за допомогою мобільного додатку підприємці можуть:
- видавати фіскальні чеки;
- додавати приватні коментарі до чеків;
- надавати знижку в чеку;
- змінювати ціну товару при формуванні чеку, не змінюючи ціни товару в базі;
- надсилати електронні чеки в смс та за QR кодом, та в месенджерах;
- відкривати та закривати робочі зміни;
- формувати та надсилати на сервер ДПС обов’язкові щоденні Z-звіти;
- додавати нові товари вручну, категорії та фільтри у базу товарів;
Nota Bene! імпортувати перелік товарів з excel документу можна через веб-сайт;
- переглядати звіти щодо важливих показників бізнесу. 

Загалом, мобільний додаток, дає можливість здійснювати всі необхідні операції, щоб повністю виконувати вимоги ДПС щодо фіскалізації розрахункових операцій. 

Для чого ж тоді потрібен Особистий кабінет окрім початкової реєстрації бізнесу? 

В Особистому кабінеті зручно переглядати та завантажувати для роздруку всю звітність по продажах для аналізу та подальшого застосування у маркетингових цілях. 
Загалом, робота із завантаження великої номенклатури товарів, зміни їхньої ціни чи рекатегоризації може відбуватись за допомогою вивантаження/редагування/завантаження excel файлів через веб-сайт.

Для структури бізнесу, в якого замовлення товару чи послуги опрацьовується у кілька етапів (напр., у випадку, кавʼярні - кухня-каса-бар), ФОПкаса надає можливість налаштувати  обмін інформацією стосовно замовлення через Telegram канал. Ця функція теж доступна в Особистому кабінеті.
В Особистому кабінеті також доступна функція консолідації фінансових показників одного бізнесу з відокремленими торговими точками. 

`,
    chips: ['Про наш ПРРО'],
  },
  {
    articleId: 1,
    image: blog1,
    imageSmall: blogItem1,
    title: 'Реєстрація ПРРО через Електронний кабінет платника податків',
    date: '05 травня 2023',
    description: `
    Сьогодні ми розповімо про те, як зареєструвати програмний РРО ФОПкаса, не відвідуючи податкову, а онлайн через Електронний кабінет платника податків. Насправді тут нічого складного.

    Для авторизації в кабінеті і підписання електронних документів потрібно мати Кваліфікований електронний підпис (КЕП) З його допомогою увійдіть до приватної частини Електронного кабінету, який розміщено за посиланням: https://cabinet.tax.gov.ua/login

    Якщо не зареєстрована господарська одиниця (об’єкт оподаткування, перед реєстрацією ПРРО потрібно  її зареєструвати. Господарська одиниця - це місце, де здійснюється продаж товарів чи надання послуг. Це може бути ваш магазин, офіс, ваш дім чи автомобіль. Реєстрація відбувається шляхом подання  форми №20-ОПП "Повідомлення про об'єкти оподаткування або об'єкти пов'язані з оподаткуванням або через які провадиться діяльність"

    Щодо деталей заповнення. 
    В розділі 3 «Дані щодо господарської одиниці» зазначається:
- тип об'єкта оподаткування - це код з довідника на сайті ДПС (https://tax.gov.ua/dovidniki--reestri--perelik/dovidniki-/127294.html). Потрібно обрати той, що найбільше відповідає вашій діяльності. Наприклад 321 - магазин, 292 - косметичний салон, 378 - офіс
- найменування об’єкта оподаткування – назва магазину, офісу, салону. 
- ідентифікатор об’єкта оподаткування - код повинен складатися з восьми цифр. Перші три цифри такі ж, як тип об’єкта оподаткування з колонки №3, наступні — з внутрішнього ідентифікатора (наприклад: для магазину це код 32100001)
- код за КОАТУУ/КАТОТТГ - Код КОАТУУ заповнювати не потрібно, а код АТОТТГ потрібно вказати з довідника (https://dovidnyk.in.ua/directorie), той що відповідає населеному пункту, де розташована ГО.

В наступних рядках потрібно написати адресу розміщення ГО. Стан суб’єкта оподаткування та вид права на об’єкт оподаткування – потрібно обрати з запропонованих варіантів з пам'ятки під таблицею.

Важливо пам’ятати, що кожну окрему господарську одиницю треба реєструвати окремо, як і подавати заяви на реєстрацію ПРРО для них. Тобто, якщо у вас два магазини, потрібно заповнити дві заяви на реєстрацію господарської одиниці і дві заяви на реєстрацію ППРО.

Тоді кожна окрема господарська одиниця (ГО) матиме зареєстрований на неї програмний РРО і у фіскальному чеку будуть вказуватися її назва та адреса, що є обов’язковим для фіскальних чеків.

Для реєстрації ПРРО потрібно заповнитии і подати форму №1-ПРРО «Заява про реєстрацію програмного реєстратора розрахункових операцій». 
Що там потрібно вказати?
У розділі 1 «Дія» необхідно обирати комірку «Реєстрація». 

У розділі 2 «Дані щодо суб’єкта господарювання» зазначити:
- найменування або прізвище, ім’я, по батькові ФОП;
- податковий номер/серія (за наявності) та номер паспорта;
- ІПН (для платників ПДВ)

У розділі 3 "Дані щодо господарської одиниці" – Ідентифікатор об'єкта оподаткування, КАТОТТГ, назва ГО, адреса розміщення ГО - потрібно копіювати з закладки в електронному кабінеті платника податків "Облікові дані платника податків", розділ Відомості про об'єкти оподаткування. Ці дані повинні співпадати з даними, які було вказано в 20-ОПП.

У розділі 4 Дані щодо ПРРО 
• Тип ПРРО обирати з огляду на місце проведення розрахунків. Якщо фіксована торговаточка – Стаціонарний, якщо не фіксована – Пересувний
• Назва ПРРО – Каса
• Локальний номер – 1 (для першої каси або єдиної каси)
• Фіскальний номер – не потрібно заповнювати, його присвоює ДПС

У розділі 5  У разі відсутності зв’язку…– позначка «Здійснюється»

У розділі 6 Підписант– позначка «підприємець», РНОКПП, ПІБ, дата


Щоб здійснювати реєстрацію розрахункових операцій на ПРРО, потрібно зареєструвати касира. Завдяки кваліфікованому електронному підпису (КЕП) касир може відкривати зміну, видавати фіскальні чеки та щоденні фіскальні звітні чеки (Z-звіти). Суб’єкт господарювання (підприємець) може проводити розрахункові операції на ПРРО самостійно або визначати уповноважених осіб (касирів). Для реєстрації потрібно заповнити та подати форму №5-ПРРО "Повідомлення про надання інформації щодо кваліфікованого сертифіката відкритого ключа для повідомлень щодо сертифікатів відкритих ключів, які застосовуються в ПРРО"
Як заповнювати цю форму?

Дата – фактична дата подання звіту № - порядковий номер подання звіту в поточному періоді.

У розділі 1 "Дані про суб’єкта господарювання" – РНОКПП (податковий номер) та ПІБ підприємця, зазавичай, заповнюється автоматично.

У розділі 2 "Дані про осіб, яким делеговано право підпису електронних документів" – ПІБ підприємця, якщо він особисто буде здійснювати реєстрацію розрахункових операцій, або ПІБ найманого працівника.
РНОКПП (податковий номер) касира.

Ідентифікатор ключа cуб'єкта господарювання - це унікальне значення сертифіката безпеки сформованого до кожного кваліфікованого електронного підпису. Вказане значення відображається в параметрах самого сертифіката безпеки.

Тип підпису -  "старший касир" або "касир". Якщо підприємець особисто буде здійснювати реєстрацію розрахункових операцій, тип підпису потрібно вказати - "Старший касир"

Кожен касир на підприємстві, який здійснює реєстрацію розрахунковий документів повинен мати КЕП та бути зареєстрований в ДПС по формі 5-ПРРО.

`,
    chips: ['Корисно знати'],
  },
  {
    articleId: 2,
    image: blog2,
    imageSmall: blogItem2,
    title: 'Офіс вдома. Де реєструвати ПРРО?',
    date: '27 квітня 2023',
    description: `
    Ось ви вже визначилися з тим, що вам потрібен програмний РРО, встановили ФОПкасу та вже випробували її в тестовому режимі. Тепер прийшов час переводити додаток у фіскальний режим. Щоб здійснити це потрібно зареєструвати ПРРО через Електронний кабінет платника податків на сайті Державної податкової служби. І найпершим етапом реєстрації ПРРО є реєстрація Господарської одиниці, яка здійснюється шляхом заповнення Форми 20-ОПП. 

Якщо ж “головний офіс” вашого підприємства знаходиться за місцем вашого проживання можуть з’явитися питання — а чи треба заповнювати цю форму взагалі та що ж туди вписувати? 

Відповідь на “чи треба” на 100% ствердна. Відповідно до вимог пункту 63.3 статті 63 Податкового кодексу України, ФОП має подати повідомлення про об’єкти оподаткування або об’єкти, пов’язані з оподаткуванням (Форма 20-ОПП), повідомивши про місце проживання як про об’єкт оподаткування, після чого на такий об’єкт може бути зареєстроване РРО та/або ПРРО. 

Давайте розбиратися, що ж вписувати у Повідомлення у нашому випадку. 
У графі 3 Форми 20-ОПП потрібно вказати тип об’єкта оподаткування у кодовому форматі, який можна взяти з довідника на сайті ДПС. У випадку з житловими об’єктами це може бути: «682 квартира», «685 житловий будинок», «293 котедж» тощо. 
У полі 5 потрібно вказати Ідентифікатор об’єкта, який має складатися з 8 цифр. Перші три — код типу об’єкта оподаткування, який ви зазначили, а інших 5 — з вашого внутрішнього ідентифікатора об’єктів (наприклад 00001, якщо це ваш єдиний об’єкт оподаткування).
У графі 9 вкажіть фактичний стан — експлуатується, орендується, здається в оренду тощо (дивіться у Пам’ятку, яка знаходиться під формою). 
У 10 графі вкажіть право власності на житло, в якому здійснюєте підприємницьку діяльність. 
Це і все! Надішліть вашу форму та очікуйте квитанцію про успішне її прийняття. Далі можна переходити до наступних кроків реєстрації. 

`,
    chips: ['Про наш ПРРО'],
  },
  {
    articleId: 3,
    image: blog3,
    imageSmall: blogItem3,
    title: 'Чи потрібно використовувати ПРРО при оплаті на ключ-карту ФОПа?',
    date: '10 квітня 2023',
    description: `
Відповідно до законодавства, підприємці можуть отримувати оплату безпосередньо на розрахунковий рахунок без застосування (П)РРО. Це значно спрощує бізнес-процеси та знижує операційні витрати для власників бізнесу. 

Однак, цей спосіб має свої незручності для клієнтів: вони повинні самостійно та без помилок вводити всі реквізити, включаючи найменування ФОП, код IBAN, ЄДРПОУ, суму та призначення платежу.

Щоб полегшити цей процес, багато підприємців обирають використовувати ключ-карту, яка прив'язана до рахунку, та приймати оплату за номером цієї картки. Проте, виникало питання: "Якщо продавець отримує оплату на ключ-карту, наприклад, Приватбанку, чи потрібно фіскалізувати такі операції через ПРРО?"

Відповідно до роз'яснень ДПС, оплата на карту-ключ розглядається як розрахункова операція, а отже, вимагає видачі фіскальних чеків. 

Сучасне рішення, яке дозволяє приймати швидкі оплати без потреби у фіскальних чеках – сервіс IBAN oplata.

Як це працює?

У мобільному додатку ФОПкаса підприємець одноразово вводить свої реквізити, суму покупки та призначення платежу. Після цього генерується QR-код, який можна надіслати покупцю. Клієнт лише переходить за посиланням та підтверджує оплату у своєму банківському додатку, наприклад, у Приват24 чи monobank. Це економить час і знижує ризик помилок у реквізитах.

Для наступних операцій потрібно лише оновити суму покупки та призначення платежу, оскільки реквізити ФОП зберігаються під час першої реєстрації. Важливо: сервіс не передбачає жодної комісії для ФОПа при прийомі платежів.

Спробуйте безкоштовно IBAN oplata навіть без реєстрації (П)РРО та дозвольте платити вам швидше!

`,
    chips: ['Новини від ДПС'],
  },
  {
    articleId: 4,
    image: blog4,
    imageSmall: blogItem4,
    title: 'Чи потрібен РРО для продажів в Інтернеті?',
    date: '4 березня 2023',
    description: `
    Чи потрібен РРО для продажів в інтернеті? Таке питання виникло не з пустого місця, адже найчастіше за умов продажу онлайн оплата здійснюється також онлайн, тобто без участі паперових грошей, тобто безготівково. Тож, здавалося б, реєстратор не потрібний.
    Давайте розбиратися, чи це дійсно так.     

    Насамперед хочеться зазначити, що реєстратор розрахункових операцій (РРО) потрібно застосовувати за умови наявності розрахункових операцій. Вони можуть бути у готівковій (за участі паперових грошей і монет) та безготівковій формі. Розрахункові операції у безготівковій формі — це всі продажі, за які клієнт розраховується карткою, готівкою або карткою через небанківський термінал, через платіжні системи, карткою через банківський термінал тощо. Всі (!) такі операції мають бути проведені через РРО.
    Зазвичай, інтернет-магазини проводять розрахунок на сайті з використанням платіжних систем як от LiqPay, Portmone, WayForPay або будь-якого іншого сервісу онлайн-оплати. Як ми вже зазначили вище, цей розрахунок вважається розрахунковою операцією у безготівковій формі, відповідно, застосування РРО є обов’язковим.
    Після отримання оплати продавець має надіслати фіскальний чек покупцю, не важливо електронний чи друкований (додати до посилки з товаром).
    Якщо ж клієнти сплачують вам на реквізити вашого поточного рахунку (у форматі ІВАN), така операція не є розрахунковою, тож РРО можна не застосовувати. 
    
    Також, якщо ви здійснюєте онлайн-торгівлю з післяплатою на відділення поштового оператора (наприклад, УкрПошта), то ви повинні разом з товаром надіслати видаткову накладну, а фіскальний чек на товар видасть оператор поштового зв'язку. Оплата за товар надходить з рахунку компанії перевізника на ваш поточний розрахунковий рахунок, тож операція не є розрахунковою для вас, тому застосування РРО не потрібне. Однак, згідно з останнього розяснення ДПС, це не стосується "Нової Пошти". Нова пошта має статус експедитора, а не кур'єрської служби. Компанія не є учасником відносин купівлі-продажу та не бере участі в розрахунку за нього. Тому фіскальний чек повинен видати продавець та надіслати його разом з товаром. 

    Якщо підприємець надає послуги, то може не застововувати РРО, за умови проведення розрахунків виключно за допомогою банківських систем дистанційного обслуговування або сервісів переказу коштів.

`,
    chips: ['Реєстрація ПРРО'],
  },
  {
    articleId: 5,
    image: blog5,
    imageSmall: blogItem5,
    title: 'Ніколи не використовували РРО?',
    date: '05 лютого 2023',
    description: `
Як почати і не нашкодити собі? 

Отже, якщо згідно законодавства ви повинні використовувати РРО, то радимо не зволікати і розпочинати процес навчання  та впровадження якнайшвидше. Усі послаблення для бізнесу, що були запроваджені на час війни будуть скасовані вже в скорому часі. Але є й добрі новини. Закон дозволяє використовувати замість дорогих та незручних касових апаратів прості та дешеві в обслуговуванні програмні РРО, частина з яких мають тестовий режим. Зазвичай, тестовий режим є безкоштовним та не вимагає синхронізації з даними підприємця в кабінеті податкової.
Програмний РРО ФОПкаса у тестовому режимі теж встановлюється на телефон чи планшет, оновлюється автоматично та підтримує всі функції фіскального ПРРО за винятком надсилання інформації про проведені трансакції на сервер податкової.

Можливості тестового режиму ФОПкаса у смартфоні не обмежуються створенням чеків, ви також зможете покращувати показники, переглядаючи звітність щодо всіх життєво важливих для вашого бізнесу процесів, як от ціноутворення, статистики продажу товарів, середнього чеку, ефективності роботи касирів, відкриття та закриття змін та інше.
Що важливо, помилки, котрі виникають у початківців легко можна виправляти, в процесі навчання персоналу без шкоди для бізнесу. 

`,
    chips: ['Корисно знати'],
  },

];

export default articles;
