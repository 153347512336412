import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { FormCashInput, FormInput } from "components/FormInput";
import { actionCreators } from "store/WorkShift";
import { ApplicationState } from "store";
import LoadingButton from "@mui/lab/LoadingButton";
import { CashDialogType } from "./CashDialog";
import { WorkShiftCashRequest, WorkShiftResponse } from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

type FormValues = {
  workShiftId: number;
  amount: string;
  comment?: string;
};

interface ReduxProps {
  getWorkShift: (workShiftId: number) => void;
  getWorkShiftOperations: (workShiftUid: string) => void;
  addCash: (
    data: WorkShiftCashRequest,
    onSucess?: (workShiftId?: number) => void,
    onError?: () => void
  ) => void;
  removeCash: (
    data: WorkShiftCashRequest,
    onSucess?: (workShiftId?: number) => void,
    onError?: () => void
  ) => void;
  workShift: WorkShiftResponse | null;
}

interface Props {
  onCloseModal: () => void;
  type: CashDialogType;
}

const CashDialogContent: FC<Props & ReduxProps> = (props) => {
  const { onCloseModal, type } = props;
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<FormValues>({
    mode: "onChange",
  });
  const amount = methods.watch("amount");

  useEffect(() => {
    return () => {
      methods.reset({ workShiftId: 0, amount: "0", comment: "" });
    };
  }, []);

  const renderBtnText = () => {
    switch (type) {
      case CashDialogType.addCash:
        return "Внести готівку в касу";
      case CashDialogType.removeCash:
        return "Вилучити готівку з каси";
    }
  };

  const onSubmit = (data: FormValues) => {
    const {
      addCash,
      removeCash,
      getWorkShift,
      getWorkShiftOperations,
      workShift,
    } = props;
    const workShiftId = workShift?.workShiftId || 0;
    const workShiftUid = workShift?.workShiftUid || "";

    const amount = parseFloat(data.amount);

    setIsLoading(true);
    switch (type) {
      case CashDialogType.addCash:
        analytics().sendEvent(AnalyticsEvents.sales_add_cash_modal_confirm);
        addCash(
          { ...data, amount, workShiftId },
          () => {
            getWorkShift(workShiftId);
            getWorkShiftOperations(workShiftUid);
            onCloseModal();
            setIsLoading(false);
            methods.reset({ workShiftId: 0, amount: "0", comment: "" });
          },
          () => {
            methods.reset({ amount: "0", comment: "" });
            setIsLoading(false);
          }
        );
        return;
      case CashDialogType.removeCash:
        analytics().sendEvent(AnalyticsEvents.sales_remove_cash_modal_confirm);
        removeCash(
          { ...data, amount, workShiftId },
          () => {
            getWorkShift(workShiftId);
            getWorkShiftOperations(workShiftUid);
            onCloseModal();
            setIsLoading(false);
            methods.reset({ workShiftId: 0, amount: "0", comment: "" });
          },
          () => {
            methods.reset({ amount: "0", comment: "" });
            setIsLoading(false);
          }
        );
        return;
    }
  };

  const onCancel = () => {
    onCloseModal();
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={0.5} variant="body2">
              КІЛЬКІСТЬ ГОТІВКИ:
            </Typography>
            <FormCashInput fullWidth name="amount" autoFocus={true} />
          </Box>
          <Box mt={2}>
            <Typography mb={0.5} variant="body2">
              КОМЕНТАР:
            </Typography>
            <FormInput fullWidth name="comment" />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          size="large"
          variant="contained"
          color="inherit"
          disabled={isLoading}
        >
          Відмінити
        </Button>
        <LoadingButton
          size="large"
          onClick={methods.handleSubmit(onSubmit)}
          loading={isLoading}
          disabled={
            !amount ||
            amount === "0" ||
            amount.startsWith(".") ||
            amount === "0." ||
            !!Object.keys(methods.formState.errors).length
          }
        >
          {renderBtnText()}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  workShift: state.workShift.workShift,
});

const mapDispatchToProps = {
  getWorkShift: actionCreators.getWorkShift,
  getWorkShiftOperations: actionCreators.getWorkShiftOperations,
  addCash: actionCreators.addCash,
  removeCash: actionCreators.removeCash,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashDialogContent);
