import { Action, Reducer } from "redux";
import { WorkShiftApi, WorkShiftOperationApi } from "../api";
import {
  CancelWorkShiftOperationRequest,
  WorkShiftCashRequest,
  WorkShiftOperationResponse,
  WorkShiftReportResponse,
  WorkShiftResponse,
} from "../api/models";
import { AppThunkAction } from "./";
import { ApiError } from "types";
import {
  actionCreators as notificationActions,
  SetNotificationAction,
} from "./Notification";

const workShiftApi = new WorkShiftApi();
const workShiftOperationsApi = new WorkShiftOperationApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WorkShiftState {
  workShift: WorkShiftResponse | null;
  operations: WorkShiftOperationResponse[];
  selectedOperation: WorkShiftOperationResponse | null;
}

enum WorkShiftActionTypes {
  SET_WORK_SHIFT = "SET_WORK_SHIFT",
  SET_WORK_SHIFT_OPERATIONS = "SET_WORK_SHIFT_OPERATIONS",
  SET_SELECTED_OPERATION = "SET_SELECTED_OPERATION",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetWorkShiftAction {
  type: WorkShiftActionTypes.SET_WORK_SHIFT;
  payload: WorkShiftResponse | null;
}

export interface SetWorkShiftOperationsAction {
  type: WorkShiftActionTypes.SET_WORK_SHIFT_OPERATIONS;
  payload: WorkShiftOperationResponse[];
}

export interface SetSelectedOperationAction {
  type: WorkShiftActionTypes.SET_SELECTED_OPERATION;
  payload: WorkShiftOperationResponse | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | SetWorkShiftAction
  | SetWorkShiftOperationsAction
  | SetSelectedOperationAction
  | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// TODO: add error handling
export const actionCreators = {
  setWorkShift: (payload: WorkShiftResponse | null): SetWorkShiftAction => ({
    type: WorkShiftActionTypes.SET_WORK_SHIFT,
    payload,
  }),
  setWorkShiftOperations: (
    payload: WorkShiftOperationResponse[]
  ): SetWorkShiftOperationsAction => ({
    type: WorkShiftActionTypes.SET_WORK_SHIFT_OPERATIONS,
    payload,
  }),
  setSelectedOperation: (
    payload: WorkShiftOperationResponse | null
  ): SetSelectedOperationAction => ({
    type: WorkShiftActionTypes.SET_SELECTED_OPERATION,
    payload,
  }),
  getWorkShift:
    (
      workShiftId: number,
      onSuccess?: (workShift?: WorkShiftResponse) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await workShiftApi.apiWorkshiftGet(workShiftId);

        dispatch(actionCreators.setWorkShift(data));

        if (onSuccess) {
          onSuccess(data);
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  getWorkShiftOperations:
    (workShiftUid: string): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await workShiftOperationsApi.apiWorkshiftOperationGet(
          workShiftUid
        );

        dispatch(
          actionCreators.setWorkShiftOperations(data.workShiftOperations)
        );
      } catch ({ response }) {
        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  openWorkShift:
    (
      cashRegisterId: number,
      onSucess?: (workShiftId?: number) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await workShiftApi.apiWorkshiftOpenPost({
          cashRegisterId,
        });
        if (onSucess) {
          onSucess(data.workShiftId);
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  closeWorkShift:
    (
      workShiftId: number,
      onSucess?: (workShiftId?: number) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await workShiftApi.apiWorkshiftClosePost({ workShiftId });
        if (onSucess) {
          onSucess();
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  addCash:
    (
      data: WorkShiftCashRequest,
      onSucess?: (workShiftId?: number) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await workShiftApi.apiWorkshiftAddCashPost(data);
        if (onSucess) {
          onSucess();
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  removeCash:
    (
      data: WorkShiftCashRequest,
      onSucess?: (workShiftId?: number) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await workShiftApi.apiWorkshiftGetCashPost(data);
        if (onSucess) {
          onSucess();
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  cancelOperation:
    (
      data: CancelWorkShiftOperationRequest,
      onSucess?: (workShiftId?: number) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await workShiftOperationsApi.apiWorkshiftOperationPost(data);
        if (onSucess) {
          onSucess();
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  getXReport:
    (
      workShiftUid: string,
      onSuccess?: (report?: WorkShiftReportResponse) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await workShiftApi.apiWorkshiftXReportGet(
          workShiftUid
        );

        if (onSuccess) {
          onSuccess(data);
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  getZReport:
    (
      workShiftUid: string,
      onSuccess?: (report?: WorkShiftReportResponse) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await workShiftApi.apiWorkshiftReportGet(workShiftUid);

        if (onSuccess) {
          onSuccess(data);
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: WorkShiftState = {
  workShift: null,
  operations: [],
  selectedOperation: null,
};

export const reducer: Reducer<WorkShiftState> = (
  state: WorkShiftState = defaultState,
  incomingAction: Action
): WorkShiftState => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case WorkShiftActionTypes.SET_WORK_SHIFT:
      return { ...state, workShift: action.payload };
    case WorkShiftActionTypes.SET_WORK_SHIFT_OPERATIONS:
      return { ...state, operations: action.payload };
    case WorkShiftActionTypes.SET_SELECTED_OPERATION:
      return { ...state, selectedOperation: action.payload };
    default:
      return state;
  }
};
