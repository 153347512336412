import React, { useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import CustomPatternFormat from "components/FormInput/PatternFormat";
import CallModal from "containers/Home/components/CallForm/components/Modal";

import UAFlag from "assets/images/ua-flag.jpg";
import ContuctUsBg from "assets/images/contact-us-bg.jpg";

import { ApplicationState } from "store";
import { actionCreators } from "store/PreOrder";

import { grey000, mainStyles, red100, red800 } from "../styles";

interface ReduxProps {
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  preOrderIban: (
    phoneNumber: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
}

const ContactUs: React.FC<ReduxProps> = (props) => {
  const { phoneNumber, setPhoneNumber, preOrderIban } = props;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const isXs = useMediaQuery("(max-width: 600px)");

  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value.length === 10 && setError(false);
    setPhoneNumber(event.target.value);
  };

  const onClick = () => {
    if (!phoneNumber || phoneNumber.length < 10) {
      setError(true);
      return;
    }

    setLoading(true);
    const phone = "38" + phoneNumber;

    preOrderIban(
      phone,
      () => {
        setIsModalOpen(true);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setPhoneNumber("");
  };

  return (
    <>
      <Box sx={styles.block1}>
        <Container>
          <Typography sx={[mainStyles.h2, { color: grey000 }]} mb={2}>
            Не знайшли відповіді на своє запитання?
          </Typography>
          <Typography sx={[mainStyles.h5, { color: grey000 }]}>
            Залишіть контактний номер і ми зв’яжемось з вами.
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="center"
            mt={4}
            mb={4}
          >
            <Box sx={[styles.inputWrapper, error && styles.error]}>
              <Box sx={styles.imageWrapper}>
                <img src={UAFlag} />
              </Box>
              <TextField
                variant="standard"
                placeholder="+38 (000) 000-0000"
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: CustomPatternFormat as any,
                  disableUnderline: true,
                  inputProps: {
                    format: "+38 (###) ### ####",
                  },
                }}
                onChange={onChangePhone}
                value={phoneNumber}
                sx={styles.input}
              />
              {!isXs && (
                <LoadingButton
                  sx={styles.contactBtn}
                  onClick={onClick}
                  loading={loading}
                >
                  Зателефонуйте мені
                </LoadingButton>
              )}
            </Box>
            {isXs && (
              <LoadingButton
                fullWidth
                sx={[styles.contactBtn, { marginTop: "8px" }]}
                onClick={onClick}
                loading={loading}
              >
                Зателефонуйте мені
              </LoadingButton>
            )}
          </Box>
        </Container>
      </Box>
      <CallModal
        isOpen={isModalOpen}
        closeModal={onCloseModal}
        phone={`38${phoneNumber}`}
      />
    </>
  );
};

const styles = {
  block1: {
    backgroundImage: `url(${ContuctUsBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: { xs: "56px", sm: "96px" },
    paddingBottom: { xs: "56px", sm: "96px" },
    textAlign: "center",
  },
  inputWrapper: {
    backgroundColor: grey000,
    display: "flex",
    justifyContent: { xs: "start", sm: "center" },
    alignItems: "center",
    borderRadius: "28px",
    margin: "1px 0",
    padding: "4px",
  },
  error: {
    border: `1px solid ${red800}`,
    backgroundColor: red100,
    margin: "0",
  },
  input: {
    marginLeft: "12px",
    minWidth: "240px",
    "& .MuiInputBase-input": {
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "2px",
      minWidth: "240px",
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0 8px 15px",
    paddingRight: "12px",
    borderRight: "1px solid #A6A7AD",
  },
  contactBtn: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    borderRadius: "28px",
    letterSpacing: "2px",
    padding: "16px",
  },
  btnWrapper: {
    marginTop: "32px",
  },
  androidApp: {
    marginRight: "32px",
  },
  image: {
    cursor: "pointer",
  },
};

const mapStateToProps = ({ preOrder }: ApplicationState) => ({
  phoneNumber: preOrder.phoneNumber,
});

const mapDispatchToProps = {
  preOrderIban: actionCreators.preOrderIban,
  setPhoneNumber: actionCreators.setPhoneNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
