import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ReportTable, HeadCell } from '../../components/Table';
import { useReportColumns } from '../../hooks';
import { roundNumber } from 'shared/functions';

const headers: HeadCell[] = [
  {
    id: 'name',
    label: 'Назва товару',
  },
  {
    id: 'properties',
    label: 'Властивості',
  },
  {
    id: 'code',
    label: 'Код товару',
    align: 'right',
  },
  {
    id: 'count',
    label: 'Кількість',
    align: 'right',
    cell: (count, { productUnitSymbol }) => productUnitSymbol.isDiscrete ? Number(count).toFixed(2) : Math.round(count),
  },
  {
    id: 'productUnitSymbol',
    label: 'Од. виміру',
    cell: ({ symbol }) => symbol,
  },
  {
    id: 'amount',
    label: 'Сума до знижки, ₴',
    align: 'right',
  },
  {
    id: 'amountAfterDiscount',
    label: 'Сума після знижки, ₴',
    align: 'right',
  },
  {
    id: 'discountAmount',
    label: 'Сума знижок, ₴',
    align: 'right',
  },
];

const ProductReport: React.FC = () => {
  const rows = useSelector(state => state.reports.productReport);
  const columns = useReportColumns(headers, rows);
  const { amountSum, amountAfterDiscount, discountAmount } = useMemo(() => ({
    amountSum: roundNumber(rows.reduce((sum, { amount = 0 }) => sum + amount, 0)),
    amountAfterDiscount: roundNumber(rows.reduce((sum, { amountAfterDiscount = 0 }) => sum + amountAfterDiscount, 0)),
    discountAmount: roundNumber(rows.reduce((sum, { discountAmount = 0 }) => sum + discountAmount, 0)),
  }), [rows]);

  return (
    <ReportTable
      idName='order'
      rows={rows}
      columns={columns}
      footer={() => (
        <TableRow>
          <TableCell colSpan={columns.length - 3} />
          <TableCell align='right'>{amountSum}</TableCell>
          <TableCell align='right'>{amountAfterDiscount}</TableCell>
          <TableCell align='right'>{discountAmount}</TableCell>
        </TableRow>
      )}
    />
  )
};

export default ProductReport;
