import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Table from "containers/Sales/WorkShift/Table";

import BalanceBlock from "./BalanceBlock";
import ShiftBlock from "./ShiftBlock";
import ReceiptBlock from "./ReceiptBlock";

import { Routes } from "shared/constants/routes";

import { actionCreators } from "store/WorkShift";
import { actionCreators as cashRegisterActions } from "store/CashRegisterOverview";
import { WorkShiftResponse } from "api";

interface Params {
  id: string;
}

const OpenWorkShift: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<Params>();
  const cashRegisterOverview = useSelector(
    (state) => state.cashRegistersOverview.cashRegisterOverview
  );
  const workShift = useSelector((state) => state.workShift.workShift);
  const operations = useSelector((state) => state.workShift.operations);

  useEffect(() => {
    if (Number(id)) {
      dispatch(
        actionCreators.getWorkShift(
          Number(id),
          (data?: WorkShiftResponse) => {
            if (!data?.isOpen) {
              history.replace(Routes.sales);
              return;
            }
          },
          () => {
            history.replace(Routes.sales);
          }
        )
      );
    }

    return () => {
      dispatch(actionCreators.setWorkShift(null));
      dispatch(actionCreators.setWorkShiftOperations([]));
      dispatch(cashRegisterActions.setCashRegisterOverview(null));
    };
  }, []);

  useEffect(() => {
    workShift?.workShiftUid &&
      dispatch(actionCreators.getWorkShiftOperations(workShift?.workShiftUid));

    if (Number(workShift?.cashRegisterId)) {
      dispatch(
        cashRegisterActions.getCashRegisterOverview(
          Number(workShift?.cashRegisterId)
        )
      );
    }
  }, [workShift?.workShiftUid, workShift?.cashRegisterId]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="start"
      >
        <Typography variant="body2" mt={3}>
          Каса:
        </Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h4" component="h1" mr={1}>
            {cashRegisterOverview?.name}
          </Typography>
          {cashRegisterOverview && !cashRegisterOverview?.fiscalNumber && (
            <Chip label="Нефіскальна" size="small" />
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <BalanceBlock />
        <ShiftBlock />
        <ReceiptBlock />
      </Box>
      <Box mt={3}>
        <Typography variant="body2" mb={1}>
          Операції з касою
        </Typography>
        <Table operations={operations} />
      </Box>
    </>
  );
};

export default OpenWorkShift;
