import React, {FC, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { actionCreators } from 'store/Acquiring';

import Table from './Table';
import QRTerminalsModal from './QRTerminal';

const Acquiring: FC = () => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const onCloseModal = () => {
    dispatch(actionCreators.removeAcquiringMonoQrTerminal());
    dispatch(actionCreators.removeAcquiringErrors());
    setIsOpenModal(false);
  };
  const onOpenModal = () => setIsOpenModal(true);

  useEffect(() => {
    dispatch(actionCreators.getAcquiringMonoCashRegisterQrTerminals());
  }, []);

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'>
        <Typography variant='h4' mt={3} mb={2} component='h1'>Еквайринг</Typography>
        <Button
          onClick={onOpenModal}
          size='large'
        >
          Додати QR термінал
        </Button>
      </Box>
      <QRTerminalsModal
        {...{ onCloseModal, isOpenModal }}
      />
      <Table {...{ onOpenModal } } />
    </>
  );
};

export default Acquiring;
