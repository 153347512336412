import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@mui/material/Link';


import { ReportTable, HeadCell } from 'components/Table';
import { actionCreators } from 'store/Acquiring';
import { CashRegisterMonoQrTerminalDto } from 'api';

interface Props {
  onOpenModal: () => void
}

const Table: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const cashRegisterTerminals = useSelector(state => state.acquiring.cashRegisterQrTerminals.cashRegisterTerminals) || [];

  const changeStructureCashRegisterTerminals = (terminal: CashRegisterMonoQrTerminalDto) => {
    const { pointOfSale = {}, cashRegister = {} } = terminal;

    return {
      pointOfSaleName: pointOfSale.name,
      cashRegisterName: cashRegister.name,
      ...terminal
    };
  }
  const updatedCashRegisterTerminals = cashRegisterTerminals.map(changeStructureCashRegisterTerminals);

  const columns: HeadCell[] = [
    {
      id: 'terminalShortId',
      label: 'QR Термінал',
      cell: (cell) => cell || '–',
    },
    {
      id: 'pointOfSaleName',
      label: 'Торгова точка',
      cell: (cell) => cell || '–',
    },
    {
      id: 'cashRegisterName',
      label: 'Підключена каса',
      cell: (cell) => cell || '–',
    },
    {
      id: 'edit',
      label: '',
      cell: (cell, row) => (
        <Link onClick={async () => {
          await dispatch(actionCreators.getAcquiringMonoQrTerminals({ monoAccessToken: row.monoAccessToken }));
          dispatch(actionCreators.setAcquiringMonoQrTerminal(row));
          props.onOpenModal();
        }} component='button'>
         Редагувати
        </Link>
      ),
    }
  ];

  return (
    <ReportTable
      idName='uid'
      enablePagination={false}
      rows={updatedCashRegisterTerminals}
      columns={columns}
    />
  )
};

export default Table;
