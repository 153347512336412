import { Action, Reducer } from "redux";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DrawerState {
  drawerOpen: boolean;
}

enum DrawerActionTypes {
  DRAWER_SET = "DRAWER_SET",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface DrawerSetAction {
  type: DrawerActionTypes.DRAWER_SET;
  payload: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = DrawerSetAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setDrawer: (payload: boolean): DrawerSetAction => ({
    type: DrawerActionTypes.DRAWER_SET,
    payload,
  }),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: DrawerState = {
  drawerOpen: true,
};

export const reducer: Reducer<DrawerState> = (
  state: DrawerState = defaultState,
  incomingAction: Action
): DrawerState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case DrawerActionTypes.DRAWER_SET:
      return { ...state, drawerOpen: action.payload };
    default:
      return state;
  }
};
