import { createContext, Dispatch, SetStateAction } from "react";

enum Steps {
  info,
  create,
}

interface AccountantContextInterface {
  setStep: Dispatch<SetStateAction<Steps>>;
  onCloseModal: () => void;
}

const AccountantContext = createContext<AccountantContextInterface>({
  setStep: () => null,
  onCloseModal: () => null,
});

export { Steps, AccountantContext };
