import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionPaymentPlan } from 'containers/Subscription/Subscription';


const useGetSubscriptionPlanButtonText = (isLanding: boolean, paymentPlan: SubscriptionPaymentPlan): string => {
  const [buttonText, setButtonText] = useState('');

  const paymentPlanId = useSelector(state => state.subscription.subscription.paymentPlan.paymentPlanId);
  const isCancelled = useSelector(state => state.subscription.subscription.isCancelled);
  const authorized = useSelector(state => state.user.authorized);

  const isCurrentPlanSelected = paymentPlanId === paymentPlan;

  useEffect(() => {
    if (isCancelled && isCurrentPlanSelected) setButtonText('Активувати')
    else setButtonText('Обрати тариф');
  }, [paymentPlanId, isCancelled, authorized, isLanding]);

  return buttonText;
}

export default useGetSubscriptionPlanButtonText;