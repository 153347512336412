import { ProductsByCategories } from "store/Product";

export const filterProducts = (
  products: ProductsByCategories | null,
  selectedTab: string,
  searchText: string
) => {
  if (products && products[selectedTab]) {
    return products[selectedTab].filter((product) => {
      const productName = product.name?.toLowerCase();
      const productCode = product.code?.toLowerCase();
      searchText = searchText.trim().toLowerCase();
      return (
        productName?.includes(searchText) || productCode?.includes(searchText)
      );
    });
  }

  return [];
};
