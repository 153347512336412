import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ReportTable, HeadCell } from 'components/Table';
import { useReportColumns } from 'hooks';
import { roundNumber } from 'shared/functions';

const headers: HeadCell[] = [
  {
    id: 'dateTimeUtc',
    label: 'Дата',
    cell: (value) => dayjs.utc(value).local().format('DD/MM/YYYY'),
  },
  {
    id: 'receiptsCount',
    label: 'Чеки',
    align: 'right',
  },
  {
    id: 'totalAmount',
    label: 'Сума, ₴',
    align: 'right',
  },
  {
    id: 'cashAmount',
    label: 'Готівка, ₴',
    align: 'right',
  },
  {
    id: 'cardAmount',
    label: 'Картка, ₴',
    align: 'right',
  },
  {
    id: 'averageReceiptAmount',
    label: 'Середня сума чеку, ₴',
    align: 'right',
  },
];

const ProductReport: React.FC = () => {
  const rows = useSelector(state => state.reports.incomeReport);
  const columns = useReportColumns(headers, rows);
  const { receiptsCountSum, totalAmountSum, cashAmountSum, cardAmountSum, averageReceiptAmountSum } = useMemo(() => ({
    receiptsCountSum: rows.reduce((sum, { receiptsCount = 0 }) => sum + receiptsCount, 0),
    totalAmountSum: roundNumber(rows.reduce((sum, { totalAmount = 0 }) => sum + totalAmount, 0)),
    cashAmountSum: roundNumber(rows.reduce((sum, { cashAmount = 0 }) => sum + cashAmount, 0)),
    cardAmountSum: roundNumber(rows.reduce((sum, { cardAmount = 0 }) => sum + cardAmount, 0)),
    averageReceiptAmountSum: roundNumber(rows.reduce((sum, { averageReceiptAmount = 1 }) => sum + averageReceiptAmount, 0)),
  }), [rows]);

  return (
    <ReportTable
      idName='order'
      rows={rows}
      columns={columns}
      footer={() => (
        <TableRow>
          <TableCell colSpan={columns.length - 5} />
          <TableCell align='right'>{receiptsCountSum}</TableCell>
          <TableCell align='right'>{totalAmountSum}</TableCell>
          <TableCell align='right'>{cashAmountSum}</TableCell>
          <TableCell align='right'>{cardAmountSum}</TableCell>
          <TableCell align='right'>{averageReceiptAmountSum}</TableCell>
        </TableRow>
      )}
    />
  )
};

export default ProductReport;
