import React from 'react';

import { alpha, createTheme } from '@mui/material/styles';
import { ukUA } from '@mui/material/locale';
import iconCheck from './assets/images/icon_check.svg';

const getStylesForButton = (
    color: React.CSSProperties['color'],
    backgroundColor: React.CSSProperties['backgroundColor'],
    opacity?: number
) => ({ color, backgroundColor, opacity, boxShadow: 'none' });

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      primaryGrey: React.CSSProperties['color'],
      secondaryGrey: React.CSSProperties['color'],
      backgroundLight: React.CSSProperties['color'],
      dropShadow: React.CSSProperties['color'],
      secondaryBlue:React.CSSProperties['color'],
      primaryBlue: React.CSSProperties['color'],
      tertiaryBlue: React.CSSProperties['color'],
      lightBlue: React.CSSProperties['color'],
      strokeLight: React.CSSProperties['color'],
      body: React.CSSProperties['color'],
      blueWhite: React.CSSProperties['color'],
      lightBG: React.CSSProperties['color'],
      secondaryRed: React.CSSProperties['color'],
      lightGreen: React.CSSProperties['color'],
      appGradient: React.CSSProperties['color'],
      tariffStartGradient: React.CSSProperties['color'],
      tariffSuccessfulGradient: React.CSSProperties['color'],
      tariffPremiumGradient: React.CSSProperties['color'],
    },
  }

  interface ThemeOptions {
    colors: {
      primaryGrey: React.CSSProperties['color'],
      secondaryGrey: React.CSSProperties['color'],
      backgroundLight: React.CSSProperties['color'],
      dropShadow: React.CSSProperties['color'],
      secondaryBlue: React.CSSProperties['color'],
      primaryBlue: React.CSSProperties['color'],
      tertiaryBlue: React.CSSProperties['color'],
      lightBlue: React.CSSProperties['color'],
      strokeLight: React.CSSProperties['color'],
      body: React.CSSProperties['color'],
      blueWhite: React.CSSProperties['color'],
      lightBG: React.CSSProperties['color'],
      secondaryRed: React.CSSProperties['color'],
      lightGreen: React.CSSProperties['color'],
      appGradient: React.CSSProperties['color'],
      tariffStartGradient: React.CSSProperties['color'],
      tariffSuccessfulGradient: React.CSSProperties['color'],
      tariffPremiumGradient: React.CSSProperties['color'],
    },
  }
}

let theme = createTheme({
  colors: {
    primaryGrey: '#A4C7D0',
    secondaryGrey: '#5F6273',
    backgroundLight: '#DCEEF6',
    dropShadow: 'rgba(95, 98, 115, 0.24)',
    secondaryBlue: '#0570E3',
    primaryBlue: ' #478AFF',
    tertiaryBlue: '#005DCC',
    lightBlue: '#F6F9FF',
    strokeLight: '#E6EEF4',
    body: '#202331',
    blueWhite: '#F5F7F9',
    lightBG: '#DCEEF7',
    secondaryRed: '#D72F58',
    lightGreen: '#DEFFEC',
    appGradient: 'linear-gradient(292.37deg, #FF5375 0%, #478AFF 100%)',
    tariffStartGradient: 'linear-gradient(85.42deg, #ECFAE7 0%, #DCF4FF 100%)',
    tariffSuccessfulGradient: 'linear-gradient(94.56deg, #0570E3 1.23%, #41E6F0 108.74%)',
    tariffPremiumGradient: 'linear-gradient(85.42deg, #FFD2E5 0%, #FCF6E3 100%)',
  },
  palette: {
    primary: {
      main: '#0570E3',
      light: '#478AFF',
      dark: '#005DCC'
    },
    success: {
      main: '#27AE60',
      light: '#44CB7D',
      dark: '#008C41',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1370,
      xl: 1536,
    },
  },
}, ukUA);

theme = createTheme(theme, {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: theme.colors.strokeLight,
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: theme.colors.primaryGrey,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: 40,
          ':hover': {
            backgroundColor: theme.colors.backgroundLight,
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            ':hover': {
              backgroundColor: theme.colors.backgroundLight,
            },
            ':before': {
              content: `url(${iconCheck})`,
              position: 'absolute',
              height: 23,
              left: 12,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...getStylesForButton(theme.palette.common.white, theme.colors.secondaryBlue),
          ':disabled': getStylesForButton(theme.colors.blueWhite, theme.colors.primaryGrey),
          ':hover': getStylesForButton(theme.palette.common.white, theme.colors.primaryBlue),
          ':active': getStylesForButton(theme.palette.common.white, theme.colors.tertiaryBlue),
          textTransform: 'none',
          fontSize: '1rem',
          letterSpacing: '0.2px',
          lineHeight: '24px',
        },
        containedSecondary: {
          ...getStylesForButton(theme.colors.body, theme.colors.lightBG),
          ':disabled': getStylesForButton(theme.colors.body, theme.colors.strokeLight),
          ':hover': getStylesForButton(theme.palette.common.white, theme.colors.primaryBlue),
          ':active': getStylesForButton(theme.palette.common.white, theme.colors.tertiaryBlue),
        },
        containedInherit: {
          ...getStylesForButton(theme.colors.secondaryBlue, 'transparent'),
          ':disabled': getStylesForButton(theme.colors.body, theme.colors.strokeLight),
          ':hover': getStylesForButton(theme.palette.common.white, theme.colors.secondaryBlue),
          ':active': getStylesForButton(theme.palette.common.white, theme.colors.tertiaryBlue),
        },
        textSecondary: {
          ...getStylesForButton(theme.colors.secondaryBlue, 'transparent'),
          ':disabled': getStylesForButton(theme.colors.body, theme.colors.strokeLight),
          ':hover': getStylesForButton(theme.colors.secondaryBlue, theme.colors.backgroundLight),
          ':active': getStylesForButton(theme.colors.secondaryBlue, theme.colors.lightBlue),
        },
        textError: {
          ...getStylesForButton(theme.colors.secondaryRed, 'transparent'),
          ':disabled': getStylesForButton(theme.colors.secondaryRed, theme.colors.secondaryRed),
          ':hover': getStylesForButton(theme.palette.common.white, theme.colors.secondaryRed),
          ':active': getStylesForButton(theme.palette.common.white, theme.colors.secondaryRed),
        },
        containedError: {
          ...getStylesForButton(theme.colors.secondaryRed, theme.colors.backgroundLight),
          ':disabled': getStylesForButton(theme.colors.secondaryRed, theme.colors.strokeLight, 0.5),
          ':hover': getStylesForButton(theme.palette.common.white, theme.colors.secondaryRed),
          ':active': getStylesForButton(theme.palette.common.white, theme.colors.secondaryRed),
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            boxShadow: `${alpha('rgba(95, 98, 115, 0.24)', 0.25)} 0px 4px 8px`,
            border: `1px solid ${theme.colors.body}`,
            transition: theme.transitions.create([
              'border-color',
              'background-color',
              'box-shadow',
            ]),
          },
          '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.colors.secondaryRed,
            },
            '.MuiOutlinedInput-input': {
              color: theme.colors.secondaryRed,
            }
          }
        }
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          marginTop: 8,
          marginLeft: 0,
          '&.Mui-error': {
            color: theme.colors.secondaryRed,
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: theme.colors.body,
          borderRadius: 4,
          textTransform: 'none',
          minHeight: 40,
          padding: '11px 16px',
          '&.Mui-selected': {
            color: theme.colors.body,
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.main}`,
          },
          '&.Mui-focusVisible': {
            backgroundColor: theme.colors.strokeLight,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput': {
            '&-input': {
              paddingTop: '9.95px',
              paddingBottom: '9.95px',
              fontWeight: 300,
              color: theme.colors.secondaryGrey,
              fontSize: 14,
              '&:focus': {
                color: theme.colors.body
              },
            },
            '&-notchedOutline': {
              borderColor: theme.colors.primaryGrey,
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: {
          fontSize: '1.1428571428571428rem',
        },
        h4: {
          fontSize: '2rem',
          lineHeight: '40px',
          fontWeight: 500,
        },
        body2: {
          fontSize: '0.8571428571428571rem',
          color: theme.colors.secondaryGrey,
          letterSpacing: '0.029em',
          textTransform: 'uppercase',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          color: theme.colors.body,
          fontWeight: 300,
          paddingLeft: 0,
          paddingRight: 0,
          '& .MuiAlert-action': {
            marginRight: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        colorPrimary: {
          backgroundColor: theme.colors.backgroundLight,
          color: theme.colors.body,
        },
        colorSecondary: {
          backgroundColor: theme.colors.lightBlue,
          color: theme.colors.secondaryGrey,
          "&:hover": {
            backgroundColor: theme.colors.lightBlue,
            filter: "brightness(95%)",
          },
        },
        colorSuccess: {
          backgroundColor: theme.colors.lightGreen,
          color: theme.palette.success.dark,
        },
      }
    }
  },
});

export default theme;
