import * as React from "react";
import Phone from "assets/images/phone-black.png";
import "./index.scss";

const optionsList: Array<string> = [
  "Безкоштовне програмне забезпечення від Державної податкової служби України з базовими функціями.",
  "Комерційні програмні реєстратори від українських компаній-розробників, які інтегруються з обліковими програмами підприємця та різняться функціями.",
  "Рішення від розробників POS-систем.",
];

const Options: React.FC = () => (
  <div className="options">
    <div className="options__text-wrapper">
      <h2 className="header-normal options__header">
        Які ПРРО є доступні на ринку?
      </h2>
      <ul className="options__list">
        {optionsList.map((item) => (
          <li key={item} className="text-normal options__item">
            {item}
          </li>
        ))}
      </ul>
    </div>
    <img className="options__image" alt="Phone" src={Phone} />
  </div>
);

export default Options;
