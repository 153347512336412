import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ReportTable, HeadCell } from "components/Table";

import CancelOperationModal from "./CancelOperationModal";
import { ReactComponent as IconCancelOperation } from "assets/images/icon_cancel_operation.svg";

import { WorkShiftOperationResponse } from "api";
import { actionCreators } from "store/WorkShift";

import dayjs from "dayjs";
import { getPriceFormat } from "shared/functions";
import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface IProps {
  operations: WorkShiftOperationResponse[];
}

const operationType: Map<string, string> = new Map([
  ["CashReplenish", "Внесення готівки"],
  ["CashWithdrawal", "Вилучення готівки"],
  ["ReceiptReturn", "Повернення"],
  ["ReceiptSale", "Продаж"],
]);

const Table: React.FC<IProps> = ({ operations }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const onModalOpen = (operation: WorkShiftOperationResponse) => {
    analytics().sendEvent(AnalyticsEvents.sales_cancel_operation_btn);
    dispatch(actionCreators.setSelectedOperation(operation));
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const columns: HeadCell[] = [
    {
      id: "operationType",
      label: "Тип операції",
      width: "15%",
      cell: (cell, row) => (
        <Typography variant="inherit">
          {operationType.get(row.operationType)}
        </Typography>
      ),
    },
    {
      id: "localNumber",
      label: "Номер чеку",
      align: "right",
      width: "15%",
      cell: (cell, row) => (
        <Link underline="none" target="_blank" href={row.receiptUrl}>
          {row.localNumber}
        </Link>
      ),
    },
    {
      id: "fiscalNumber",
      label: "Фіскальний номер",
      width: "20%",
      align: "right",
    },
    {
      id: "amount",
      label: "Сума, ₴",
      width: "20%",
      align: "right",
      cell: (cell, row) => (
        <Typography variant="inherit">{getPriceFormat(row.amount)}</Typography>
      ),
    },
    {
      id: "dateTimeUtc",
      label: "Дата і час",
      width: "15%",
      cell: (value) => dayjs.utc(value).local().format("DD/MM/YYYY HH:mm"),
    },
    {
      id: "cancelOperation",
      label: "",
      align: "right",
      width: "10%",
      cell: (cell, row) => {
        return row.isCancellationAllowed ? (
          <Link
            underline="none"
            component="button"
            onClick={() => onModalOpen(row as WorkShiftOperationResponse)}
            display="flex"
            alignItems="center"
          >
            <IconCancelOperation />
            <Typography pl={1}>Скасувати</Typography>
          </Link>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <>
      <ReportTable idName="order" rows={operations} columns={columns} />
      <CancelOperationModal open={modalOpen} onClose={onModalClose} />
    </>
  );
};

export default Table;
