import React, { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { FormAutocomplete } from 'components/FormInput';
import DeleteButton from 'components/DeleteButton';

import { AcquiringContext, Steps } from 'contexts/AcquiringContext';
import { actionCreators } from 'store/Acquiring';
import { actionCreators as actionCreatorsCashRegister } from 'store/CashRegister';

type FormValues = {
  terminalId: string;
  cashRegisterId: number;
  pointOfSaleId: number;
};

const TerminalConfig: FC = () => {
  const dispatch = useDispatch();
  const methods = useForm<FormValues>({ mode: 'onChange' });
  const { onCloseModal, setStep } = useContext(AcquiringContext);
  const monoAccessTokenError = useSelector(state => state.acquiring.errors.monoAccessToken);
  const isEdit = !!useSelector(({ acquiring }) => acquiring.cashRegisterQrTerminal.uid);
  const points = useSelector(({ pointOfSale }) => pointOfSale.points);
  const registers = useSelector(({ cashRegister }) => cashRegister.registers);
  const terminals = useSelector(({ acquiring }) => acquiring.terminals.terminals) || [];

  const {
    cashRegister = {}, pointOfSale = {},
    monoAccessToken, ...cashRegisterQrTerminal
  } = useSelector(({ acquiring }) => acquiring.cashRegisterQrTerminal);

  const pointOfSaleId = methods.watch('pointOfSaleId');
  const cashRegisterId = methods.watch('cashRegisterId');
  const terminalId = methods.watch('terminalId');

  useEffect(() => {
    dispatch(actionCreatorsCashRegister.getCashRegisters(pointOfSaleId));
  }, [pointOfSaleId]);


  useEffect(() => {
    const setCashRegisterId = (cashRegisterId: number) => methods.setValue('cashRegisterId', cashRegisterId);
    const selectedCashRegister = registers.find(({ cashRegisterId }) => cashRegisterId == cashRegister.cashRegisterId);

    if (!selectedCashRegister) {
      const [firstCashRegister] = registers.filter(({ cashRegisterId }) => cashRegisterId);

      setCashRegisterId(Number(firstCashRegister?.cashRegisterId));
    } else {
      setCashRegisterId(Number(cashRegister.cashRegisterId));
    }

  }, [registers]);

  useEffect(() => {
    if (monoAccessTokenError) {
      methods.setError('terminalId', { message: monoAccessTokenError })
    }
    dispatch(actionCreators.removeAcquiringErrors());
  }, [monoAccessTokenError]);

  const onCreateAcquiringMonoCashRegisterQrTerminals = (data: FormValues) => {
    dispatch(actionCreators.createAcquiringMonoCashRegisterQrTerminals({
      ...data,
      monoAccessToken: String(monoAccessToken),
    }, () => {
      onCloseModal();
      setStep(Steps.monobankToken);
    }));
  };

  const onUpdateAcquiringMonoCashRegisterQrTerminals = (data: FormValues) => {
    dispatch(actionCreators.updateAcquiringMonoCashRegisterQrTerminals({
      ...data,
      monoAccessToken: String(monoAccessToken),
    }, onCloseModal));
  };

  const onSubmit = (data: FormValues) => {
    if (isEdit) {
      onUpdateAcquiringMonoCashRegisterQrTerminals(data);
    } else {
      onCreateAcquiringMonoCashRegisterQrTerminals(data);
    }
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Typography ml={1} mb={.5} color={({ colors }) => colors.secondaryGrey} fontSize='0.8571428571428571rem'>
            MONOBANK ТОКЕН:
          </Typography>
          <Box ml={1} display='flex' flexDirection='row' alignItems='center'>
            <Typography fontWeight={300}>
              {monoAccessToken}
            </Typography>
            <Link
              sx={{ cursor: 'pointer' }}
              fontWeight='300'
              ml={1}
              onClick={() => setStep(Steps.monobankToken)}
              underline='hover'>Змінити</Link>
          </Box>
          <Typography ml={1} mt={2} color={({ colors }) => colors.secondaryGrey} fontSize='0.8571428571428571rem'>
            QR ТЕРМІНАЛ:
          </Typography>
          <FormAutocomplete
            name='terminalId'
            placeholder='Оберіть qr термінал'
            disableClearable
            rules={{ required: true }}
            defaultValue={terminals.length ? String(cashRegisterQrTerminal.terminalId) : ''}
            options={terminals.map(({ id }) => id)}
            getOptionLabel={(terminalId) => {
              const option = terminals.find(({ id }) => id == terminalId)

              return option ? String(option.shortId) : '-'
            }}
          />
          <Typography ml={1} mt={2} color={({ colors }) => colors.secondaryGrey} fontSize='0.8571428571428571rem'>
            ТОРГОВА ТОЧКА:
          </Typography>
          <FormAutocomplete
            name='pointOfSaleId'
            placeholder='Оберіть торгову точку'
            disableClearable
            defaultValue={pointOfSale.pointOfSaleId}
            options={points.map(({ pointOfSaleId }) => pointOfSaleId)}
            getOptionLabel={(pointOfSaleId) => {
              const option = points.find((point) => point.pointOfSaleId == pointOfSaleId)

              return option ? String(option.name) : '-'
            }}
          />
          <Typography ml={1} mt={2} color={({ colors }) => colors.secondaryGrey} fontSize='0.8571428571428571rem'>
            КАСА:
          </Typography>
          <FormAutocomplete
            name='cashRegisterId'
            placeholder='Оберіть касу'
            disableClearable
            defaultValue={cashRegisterId || cashRegister.cashRegisterId}
            options={registers.filter(({ cashRegisterId }) => cashRegisterId).map(({ cashRegisterId }) => cashRegisterId)}
            getOptionLabel={(cashRegisterId) => {
              const option = registers.find((register) => register.cashRegisterId == cashRegisterId);

              return option ? String(option.name) : '-'
            }}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        {isEdit && (
          <DeleteButton onClick={() => {
            dispatch(actionCreators.deleteAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUid(onCloseModal))
          }}>
            Видалити
          </DeleteButton>
        )}
        <Box marginLeft='auto'>
          <Button
            size='large'
            onClick={methods.handleSubmit(onSubmit)}
            disabled={
              !methods.formState.isDirty ||
              !pointOfSaleId ||
              !terminalId ||
              !cashRegisterId
            }
          >
            {isEdit ? 'Зберегти' : 'Додати' }
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default TerminalConfig;
