/* tslint:disable */
/* eslint-disable */
/**
 * FOPkasa.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CancelWorkShiftOperationRequest } from '../models';
import { GetWorkShiftOperationResponse } from '../models';
/**
 * WorkShiftOperationApi - axios parameter creator
 * @export
 */
export const WorkShiftOperationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [workshiftUid] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftOperationGet: async (workshiftUid?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift-operation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (workshiftUid !== undefined) {
                localVarQueryParameter['workshiftUid'] = workshiftUid;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelWorkShiftOperationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftOperationPost: async (body?: CancelWorkShiftOperationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift-operation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkShiftOperationApi - functional programming interface
 * @export
 */
export const WorkShiftOperationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [workshiftUid] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftOperationGet(workshiftUid?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetWorkShiftOperationResponse>>> {
            const localVarAxiosArgs = await WorkShiftOperationApiAxiosParamCreator(configuration).apiWorkshiftOperationGet(workshiftUid, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CancelWorkShiftOperationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftOperationPost(body?: CancelWorkShiftOperationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await WorkShiftOperationApiAxiosParamCreator(configuration).apiWorkshiftOperationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkShiftOperationApi - factory interface
 * @export
 */
export const WorkShiftOperationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [workshiftUid] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftOperationGet(workshiftUid?: string, filter?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetWorkShiftOperationResponse>> {
            return WorkShiftOperationApiFp(configuration).apiWorkshiftOperationGet(workshiftUid, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CancelWorkShiftOperationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftOperationPost(body?: CancelWorkShiftOperationRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return WorkShiftOperationApiFp(configuration).apiWorkshiftOperationPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkShiftOperationApi - object-oriented interface
 * @export
 * @class WorkShiftOperationApi
 * @extends {BaseAPI}
 */
export class WorkShiftOperationApi extends BaseAPI {
    /**
     * 
     * @param {string} [workshiftUid] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftOperationApi
     */
    public async apiWorkshiftOperationGet(workshiftUid?: string, filter?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetWorkShiftOperationResponse>> {
        return WorkShiftOperationApiFp(this.configuration).apiWorkshiftOperationGet(workshiftUid, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CancelWorkShiftOperationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftOperationApi
     */
    public async apiWorkshiftOperationPost(body?: CancelWorkShiftOperationRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return WorkShiftOperationApiFp(this.configuration).apiWorkshiftOperationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
