/* tslint:disable */
/* eslint-disable */
/**
 * FOPkasa.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * PreOrderApi - axios parameter creator
 * @export
 */
export const PreOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preOrderAddContactPost: async (phoneNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PreOrder/AddContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preOrderPost: async (phoneNumber?: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PreOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreOrderApi - functional programming interface
 * @export
 */
export const PreOrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preOrderAddContactPost(phoneNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PreOrderApiAxiosParamCreator(configuration).preOrderAddContactPost(phoneNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preOrderPost(phoneNumber?: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PreOrderApiAxiosParamCreator(configuration).preOrderPost(phoneNumber, source, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PreOrderApi - factory interface
 * @export
 */
export const PreOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preOrderAddContactPost(phoneNumber?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PreOrderApiFp(configuration).preOrderAddContactPost(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preOrderPost(phoneNumber?: string, source?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PreOrderApiFp(configuration).preOrderPost(phoneNumber, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreOrderApi - object-oriented interface
 * @export
 * @class PreOrderApi
 * @extends {BaseAPI}
 */
export class PreOrderApi extends BaseAPI {
    /**
     * 
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOrderApi
     */
    public async preOrderAddContactPost(phoneNumber?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PreOrderApiFp(this.configuration).preOrderAddContactPost(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [phoneNumber] 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOrderApi
     */
    public async preOrderPost(phoneNumber?: string, source?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PreOrderApiFp(this.configuration).preOrderPost(phoneNumber, source, options).then((request) => request(this.axios, this.basePath));
    }
}
