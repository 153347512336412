import React, { FC } from "react";
import { connect } from "react-redux";

import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { ReportTable, HeadCell, Row } from "components/Table";

import { ProductResponse } from "api";
import { actionCreators } from "store/Product";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface ReduxProps {
  setSelectedProduct: (product: ProductResponse) => void;
}

interface Props {
  products: ProductResponse[];
  selected: Row[];
  onOpenModal: () => void;
  onSelectedChange?: (selected: Row[]) => void;
}

type AllProps = ReduxProps & Props;

const Table: FC<AllProps> = (props) => {
  const {
    products,
    selected,
    setSelectedProduct,
    onOpenModal,
    onSelectedChange,
  } = props;
  const columns: HeadCell[] = [
    {
      id: "name",
      label: "Назва",
      width: "35%",
      cell: (cell, row) => (
        <Link
          onClick={() => {
            analytics().sendEvent(AnalyticsEvents.edit_product_btn);
            setSelectedProduct(row as ProductResponse);
            onOpenModal();
          }}
          component="button"
          sx={{ textAlign: "left", textDecoration: "none" }}
        >
          {row.name}
        </Link>
      ),
    },
    {
      id: "price",
      label: "Ціна, ₴",
      width: "10%",
      style: {
        whiteSpace: "nowrap",
      },
    },
    {
      id: "unit",
      label: "Одиниці",
      width: "5%",
      cell: ({ symbol }) => symbol,
    },
    {
      id: "code",
      label: "Код",
      width: "10%",
      cell: (value) => (
        <Typography variant="inherit" sx={{ overflowWrap: "anywhere" }}>
          {value}
        </Typography>
      ),
    },
    {
      id: "barcode",
      label: "Штрихкод",
      width: "10%",
      cell: (value) => (
        <Typography variant="inherit" sx={{ overflowWrap: "anywhere" }}>
          {value}
        </Typography>
      ),
    },
    {
      id: "category",
      label: "Категорія",
      width: "10%",
      cell: (cell, row) => (
        <Typography variant="inherit">{row.category.name}</Typography>
      ),
    },
    {
      id: "properties",
      label: "Властивості",
      width: "20%",
      cell: (properties: string[]) =>
        properties.map((property) => (
          <Chip
            key={property}
            label={property}
            size="small"
            sx={{ mr: 0.5, mt: 0.25, mb: 0.25 }}
          />
        )),
    },
  ];

  return (
    <ReportTable
      idName="productUid"
      rows={products}
      columns={columns}
      checkboxSelection
      selected={selected}
      onSelectedChange={onSelectedChange}
    />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setSelectedProduct: actionCreators.setSelectedProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
