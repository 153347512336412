import React, { useState, useRef, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Box from '@mui/material/Box';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pdf from './instruction.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const documentOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const InstructionForRegistration: React.FC = () => {
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [pageWidth, setPageWidth] = useState<number>(0);

  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    addEventListener('resize', setPageSize);

    return () => {
      removeEventListener('resize', setPageSize)
    }
  }, []);


  const setPageSize = () => {
    if (pageRef.current) {
      const newWidth = pageRef.current.clientWidth;
      if (pageWidth != newWidth) {
        setPageWidth(newWidth);
      }
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages);
    setPageSize();
  }

  return (
    <Box mt={2} mb={2}>
      <Document
        options={documentOptions}
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numberOfPages), (element, index) => (
          <Box key={`page_${index + 1}`} mt={2}>
            <Page
              inputRef={pageRef}
              width={pageWidth}
              pageNumber={index + 1}
            />
          </Box>
        ))}
      </Document>
    </Box>
  )
}

export default InstructionForRegistration;
