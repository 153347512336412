import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DeleteButton from "components/DeleteButton";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

interface Props {
  isOpenModal: boolean;
  isDeleteInProgress: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
}

const ConfirmDeleteDialog: FC<Props> = (props) => {
  const { isOpenModal, isDeleteInProgress, onCloseModal, onConfirm } = props;

  return (
    <Dialog open={isOpenModal} fullWidth>
      <DialogTitle>
        Видалення товарів
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={styles.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography fontWeight={300} paragraph>
          Ви впевнені, що хочете видалити вибрані товари?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseModal}
          size="large"
          variant="contained"
          color="inherit"
          disabled={isDeleteInProgress}
        >
          Відмінити
        </Button>
        <DeleteButton
          sx={styles.deleteBtn}
          size="large"
          disabled={isDeleteInProgress}
          loading={isDeleteInProgress}
          onClick={onConfirm}
        >
          Видалити
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 12,
  },
  deleteBtn: { marginRight: 0 },
};

export default ConfirmDeleteDialog;
