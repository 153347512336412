import * as React from 'react';
import './index.scss';

interface Fact {
    text: string;
    href: string;
}

const factsList: Array<Fact> = [
    {
        text: 'Реєстрація ПРРО через Електронний кабінет платника податків',
        href: 'blog/article/1'
    },
    {
        text: 'Чи потрібне РРО за наявності банківської «ключ-картки» у ФОПа?',
        href: 'blog/article/3'
    },    
    {
        text: 'Переваги використання хмарної каси перед стаціонарними касовими апаратами',
        href: 'blog/article/8'
    }
];

const Facts: React.FC = () => (
    <section className="facts">
        <h2 className="header-normal facts__header">Корисно знати</h2>
        <p className="text-large facts__text">
        Незалежно від того, який варіант буде використовувати підприємець, 
        робота програми все одно відбувається через єдиний фіскальний сервер податкової служби, 
        який забезпечує фіскалізацію чеків з програмними РРО в онлайн режимі. 
        </p>
        <h3 className="text-normal facts__list-header">Більш детально читайте у блозі:</h3>
        <ul className="facts__list">
            {factsList.map(item =>
                <li className="facts__item" key={item.text}>
                    <a
                        className="text-normal facts__list-text"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={item.href}
                    >
                        {item.text}
                    </a>
                </li>
            )}
        </ul>
    </section>
);

export default Facts;
