import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { QRCodeSVG } from "qrcode.react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import BorderLinearProgress from "components/BorderLinearProgress";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { ApplicationState } from "store";
import { actionCreators } from "store/Receipt";
import { actionCreators as orderActions } from "store/Order";
import { MonoApiQrInvoiceStatusEnum, MonoQrInvoiceDto } from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface ReduxProps {
  monoQrInvoice:
    | (MonoQrInvoiceDto & {
        monoInvoiceIntervalId?: NodeJS.Timeout | undefined;
        receiptUid?: string | undefined;
      })
    | null;
  cancelMonoQrInvoice: (
    invoiceId: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  cleanOrder: () => void;
}

interface Props {
  onCloseModal: () => void;
}

const statusMapper: Record<string, string> = {
  [MonoApiQrInvoiceStatusEnum.Created]: "Очікується оплата...",
  [MonoApiQrInvoiceStatusEnum.Processing]: "Оплата опарцьовується банком...",
  [MonoApiQrInvoiceStatusEnum.Failure]:
    "Помилка оплати. Згенеруйте новий QR-код та спробуйте ще раз або використайте інший спосіб оплати.",
  [MonoApiQrInvoiceStatusEnum.Expired]:
    "Термін очікування оплати закінчився. Згенеруйте новий QR-код та спробуйте ще раз або використайте інший спосіб оплати.",
};

const MonoInvoice: FC<ReduxProps & Props> = (props) => {
  const { monoQrInvoice, cancelMonoQrInvoice, cleanOrder, onCloseModal } =
    props;
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const onCancelPayment = () => {
    analytics().sendEvent(AnalyticsEvents.sales_cancel_mono_qr);
    if (monoQrInvoice?.id) {
      setIsCancelLoading(true);
      cancelMonoQrInvoice(
        monoQrInvoice.id,
        () => {
          monoQrInvoice?.monoInvoiceIntervalId &&
            clearInterval(monoQrInvoice.monoInvoiceIntervalId);
          cleanOrder();
          setIsCancelLoading(false);
          onCloseModal();
        },
        () => {
          setIsCancelLoading(false);
        }
      );
    }
  };

  return (
    <>
      <DialogTitle>
        QR код для оплати
        <IconButton
          aria-label="close"
          disabled={isCancelLoading}
          onClick={onCancelPayment}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {monoQrInvoice?.paymentUrl && (
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <QRCodeSVG value={monoQrInvoice.paymentUrl} size={250} level="Q" />
          </Box>
        )}
        <Box p={2}>
          <Typography pb={2}>
            Для оплати клієнту потрібно відсканувати даний QR код або
            скористатися QR кодом наданим Monobank.
          </Typography>
          <Typography
            variant="body1"
            fontWeight="100"
            textAlign="center"
            pt={1}
            pb={1}
          >
            {
              statusMapper[
                monoQrInvoice?.status || MonoApiQrInvoiceStatusEnum.Created
              ]
            }
          </Typography>
          {monoQrInvoice?.status &&
            [
              MonoApiQrInvoiceStatusEnum.Created,
              MonoApiQrInvoiceStatusEnum.Processing,
            ].includes(monoQrInvoice.status) && <BorderLinearProgress />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          disabled={isCancelLoading}
          onClick={onCancelPayment}
          fullWidth
        >
          Cкасувати замовлення
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ receipt }: ApplicationState) => ({
  monoQrInvoice: receipt.monoQrInvoice,
});

const mapDispatchToProps = {
  cancelMonoQrInvoice: actionCreators.cancelMonoQrInvoice,
  cleanOrder: orderActions.cleanOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonoInvoice);
