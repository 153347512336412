import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import Info from "./Info";
import Create from "./Create";
import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { AccountantContext, Steps } from "contexts/AccountantContext";
import { actionCreators } from "store/Accountants";

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
}

const Accountant: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isOpenModal, onCloseModal } = props;
  const [step, setStep] = useState(Steps.create);
  const isInfo = !!useSelector(
    ({ accountants }) => accountants.accountant.userUid
  );

  useEffect(() => {
    isInfo ? setStep(Steps.info) : setStep(Steps.create);
    if (!isOpenModal) {
      dispatch(actionCreators.removeAccountant());
    }
  }, [isOpenModal]);

  const renderStep = () => {
    switch (step) {
      case Steps.create:
        return <Create />;
      case Steps.info:
        return <Info />;
    }
  };

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { minHeight: 420 } }}
    >
      <DialogTitle>
        {step === Steps.create
          ? "Додати бухгалтера"
          : " Інформація про бухгалтера"}
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <AccountantContext.Provider value={{ setStep, onCloseModal }}>
        {renderStep()}
      </AccountantContext.Provider>
    </Dialog>
  );
};

export default Accountant;
