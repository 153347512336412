import React, { FC, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DeleteButton from "components/DeleteButton";
import { actionCreators } from "store/Accountants";
import { AccountantContext } from "contexts/AccountantContext";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

const Info: FC = () => {
  const dispatch = useDispatch();

  const emptyValue = "Немає інформації";
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const { userUid, fullName, phone } = useSelector(
    (state) => state.accountants.accountant
  );
  const { onCloseModal } = useContext(AccountantContext);

  const onDeleteAccountant = () => {
    setIsDeleteInProgress(true);
    userUid &&
      dispatch(
        actionCreators.deleteAccountant({ userUid }, () => {
          setIsDeleteInProgress(false);
          onCloseModal();
        })
      );
    analytics().sendEvent(
      AnalyticsEvents.accountants_accountant_info_modal_remove
    );
  };

  const onCancel = () => {
    onCloseModal();
    analytics().sendEvent(
      AnalyticsEvents.accountants_accountant_info_modal_cancel
    );
  };

  return (
    <>
      <DialogContent dividers>
        <Typography
          mb={1.7}
          color={({ colors }) => colors.secondaryGrey}
          fontSize="0.8571428571428571rem"
        >
          ІМ&apos;Я БУХГАЛТЕРА:
        </Typography>
        <Typography fontWeight={300}>{fullName || emptyValue}</Typography>
        <Typography
          mt={2}
          mb={1.7}
          color={({ colors }) => colors.secondaryGrey}
          fontSize="0.8571428571428571rem"
        >
          ТЕЛЕФОН БУХГАЛТЕРА:
        </Typography>
        <Typography fontWeight={300}>
          {(phone && "+" + phone) || emptyValue}
        </Typography>
      </DialogContent>
      <DialogActions>
        <DeleteButton loading={isDeleteInProgress} onClick={onDeleteAccountant}>
          Видалити бухгалтера
        </DeleteButton>
        <Box marginLeft="auto">
          <Button
            onClick={onCancel}
            size="large"
            variant="contained"
            color="inherit"
          >
            Закрити
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default Info;
