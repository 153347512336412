export const changeFavicon = (faviconPath: string) => {
  const publicUrl = process.env.PUBLIC_URL || "";

  const link: HTMLLinkElement | null = document.querySelector(
    "link[rel='shortcut icon']"
  ) as HTMLLinkElement;
  if (link) {
    link.href = `${publicUrl}/${faviconPath}`;
  }
};
