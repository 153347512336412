/* tslint:disable */
/* eslint-disable */
/**
 * FOPkasa.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MonoApiQrInvoiceStatusEnum {
    Created = 'Created',
    Processing = 'Processing',
    Hold = 'Hold',
    Success = 'Success',
    Failure = 'Failure',
    Reversed = 'Reversed',
    Expired = 'Expired',
    Cancelled = 'Cancelled'
}

