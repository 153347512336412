import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import Header from "components/Header";
import Footer from "components/Footer";

import authService from 'services/auth';
import * as User from 'store/User';

import "assets/styles/index.scss";

interface LayoutProps {
  children?: React.ReactNode;
}

const WebsiteLayout: React.FC<LayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (authService().getAccessToken()) {
      dispatch(User.actionCreators.getUser());
    }
  }, []);

  return (
    <>
      <Header website />
      <Container sx={{ flex: 1 }}>{children}</Container>
      <Footer />
    </>
  );
};

export default WebsiteLayout;
