import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import TariffFeatureItem from './TariffFeatureItem';
import CurrentTariff from './CurrentTariff';
import { ReactComponent as MarkIcon } from 'assets/images/icon_mark.svg';
import { SubscriptionPaymentPlan } from 'containers/Subscription/Subscription';
import { useGetSubscriptionPlanButtonText } from 'hooks';
import { Routes } from 'shared/constants/routes';

import {
  TariffContainer,
  TariffTitleContainer,
  TariffFeatureContainer,
  TariffOpportunitiesContainer,
  TariffButton
} from './styles';

import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';


const tariffFeatures: string[] = [];

interface IProps {
  createPayment: () => void
  isLanding: boolean
}

const SuccessfulYear: FC<IProps> = props => {
  const paymentPlanId = useSelector(state => state.subscription.subscription.paymentPlan.paymentPlanId);
  const isCancelled = useSelector(state => state.subscription.subscription.isCancelled);
  const authorized = useSelector(state => state.user.authorized);
  const history = useHistory();
  const buttonText = useGetSubscriptionPlanButtonText(props.isLanding, SubscriptionPaymentPlan.Success);
  const isCurrentPlanSelected = paymentPlanId === SubscriptionPaymentPlan.SuccessfulYear;


  return (
    <TariffContainer 
        sx={{ minWidth: props.isLanding ? 'none' : '390px',
              marginRight: { md: '24px' } }}>
      <TariffTitleContainer sx={({ colors }) => ({ background: colors.tariffPremiumGradient })}>
        <Typography fontSize={32}>
        Успішний рік
        </Typography>
        <Typography display='inline' fontSize={32}> 130</Typography>
        <Typography display='inline' fontSize={18} fontWeight={600}> грн./місяць</Typography>
        { props.isLanding && <Typography display='inline' fontSize={12}>, безкоштовно перші 2 місяці</Typography> }
      </TariffTitleContainer>
      <TariffFeatureContainer>
        <ListItemAvatar sx={{ minWidth: '15px' }}>
          <MarkIcon/>
        </ListItemAvatar>
        <Box ml={1}>
          <Typography display='inline' fontSize={18} fontWeight={600}>
            Весь функціонал тарифу “Успішний” при оплаті 1560 грн. одразу за весь рік.
          </Typography>
        </Box>
      </TariffFeatureContainer>
      <TariffOpportunitiesContainer>
        <Box>
          {tariffFeatures.map((tariffFeature) => (
            <TariffFeatureItem
              tariffFeature={tariffFeature}
              key={tariffFeature}
              isPremium={false}
            />
          ))}
        </Box>
        {isCurrentPlanSelected && !isCancelled ?
          <CurrentTariff/> :
          <TariffButton
            onClick={() => {
              if (props.isLanding && !authorized) history.push(`${Routes.registrationPhoneNumber}?tariff=successfulYear`);
              else props.createPayment();
              analytics().sendEvent(AnalyticsEvents.subscription_payment_plan_successful_year);
            }}
            fullWidth
            size='large'
            variant='contained'
            color='secondary'
          >
            {buttonText}
          </TariffButton>
        }
      </TariffOpportunitiesContainer>
    </TariffContainer>
  );
};

export default SuccessfulYear;
