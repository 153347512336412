import React, { FC } from 'react';
import { useParams } from 'react-router';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import articles, { Article } from './blogMockData'

type Params = {
  id: string;
}

const BlogSingle: FC<Article> = () => {
  const { id } = useParams<Params>();
  const { image, title, chips = [], date, description } = articles.find(({ articleId }) => Number(id) == articleId) || {};
  
  return (
    <Box
      mt={3}
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
    >
      {
        title ? (
          <Box width='80%'>
            <Box>
              {chips.map((label) => <Chip key={label} label={label} size='small' sx={{ marginRight: 1 }} />)}
            </Box>
            <Typography mt={1} fontSize={32} lineHeight={1.1} variant='h1'>
              {title}
            </Typography>
            <Typography mt={1} mb={1} fontWeight='300' color={({ colors }) => colors.secondaryGrey}>
              {date}
            </Typography>
            <img
              style={{ width: '100%', alignSelf: 'center' }}
              src={image}
              alt={title}
              loading="lazy"
            />
            <Box>
              <Typography fontWeight='300' whiteSpace='pre-line' mb={1}>
                {description}
              </Typography>
            </Box>
          </Box>
        ) : <Typography mt={1} fontSize={48} lineHeight={1.1} variant='h1'>Статтю не знайдено</Typography>
      }
    </Box>
  );
};

export default BlogSingle;
