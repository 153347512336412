import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { QRCodeSVG } from "qrcode.react";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Loader from "components/Loader";
import InfoItem from "components/InfoItem";
import Banks from "./Banks";

import Divider from "assets/images/divider.jpg";
import Logo from "assets/images/logo-black.png";
import HalfDivider from "assets/images/half-divider.jpg";
import BankDivider from "assets/images/bank-divider.jpg";
import { ReactComponent as LogoFilled } from "assets/images/logo-filled.svg";

import { IbanQrInvoiceApi } from "api";
import { IbanQrInvoiceResponseDto } from "api/models";
import { getPriceFormat } from "shared/functions";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import {
  grey000,
  grey700,
  grey800,
  mainStyles,
} from "containers/IbanOplata/styles";
import { Routes } from "shared/constants/routes";
import { Bank, BankKeys } from "shared/constants/banks";
import { changeFavicon } from "shared/functions/changeFavicon";

import { styles } from "./styles";

interface Params {
  uid: string;
}

const ibanQrInvoiceApi = new IbanQrInvoiceApi();

const IbanQr: React.FC = () => {
  const { uid } = useParams<Params>();
  const [iban, setIban] = useState<IbanQrInvoiceResponseDto | null>(null);
  const [isIbanLoading, setIsIbanLoading] = useState<boolean>(false);

  const [preferedBank, setPreferedBank] = useState(
    localStorage.getItem(BankKeys.PREFERED_BANK) || ""
  );

  useEffect(() => {
    document.title = "IBAN oplata";
    changeFavicon("favicon-iban.ico");

    return () => {
      document.title = "ФОПкаса";
      changeFavicon("favicon.ico");
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsIbanLoading(true);
        const { data } = await ibanQrInvoiceApi.apiIbanQrIbanInvoiceUidGet(uid);
        setIban(data);
      } finally {
        setIsIbanLoading(false);
      }
    })();
  }, [uid]);

  const ibanFormat = (iban: string) => {
    const countryCode = iban.substring(0, 2);
    const controlNumbers = iban.substring(2, 4);
    const bankMFO = iban.substring(4, 10);
    const zeros = iban.substring(10, 15);
    const accountNumber = iban.substring(15);

    return `${countryCode} ${controlNumbers} ${bankMFO} ${zeros} ${accountNumber}`;
  };

  const constructBankUrl = (bank: Bank) => {
    const bankPackage = isIOS ? bank.packageIOS : bank.packageAndroid;

    const bankUrlAndroid =
      (iban?.ibanPaymentUrl || "").replace("https", "intent") +
      `#Intent;scheme=https;package=${bankPackage};end`;

    const bankUrlIOS = (iban?.ibanPaymentUrl || "").replace(
      "https",
      bankPackage
    );

    return isIOS ? bankUrlIOS : bankUrlAndroid;
  };

  const constructPaymentUrl = () => {
    return iban?.ibanPaymentUrl || "";
  };

  const onPay = () => {
    analytics().sendEvent(AnalyticsEvents.iban_qr_pay_default);
  };

  if (isIbanLoading) {
    return <Loader isLoadingPage />;
  }

  return (
    <Box sx={styles.page}>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1.25}
          >
            <LogoFilled
              style={{ cursor: "pointer" }}
              onClick={() => window.open(Routes.home, "_blank")}
            />
            <Link sx={mainStyles.ui2} href={Routes.ibanOplata} target="_blank">
              Що таке IBAN oplata?
            </Link>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <Paper elevation={0} sx={styles.ibanReceipt}>
              <Box textAlign="center">
                <Typography sx={styles.heading}>
                  Оплата рахунку по IBAN
                </Typography>
                <Box sx={styles.amountContainer}>
                  <Typography sx={styles.text} color={grey800} mb={0.5}>
                    Сума до сплати:
                  </Typography>
                  <Typography sx={styles.amount}>
                    {getPriceFormat(iban?.amount || 0)} ₴
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
                mb={2}
              >
                <img src={HalfDivider} />
                <Typography sx={styles.text} color={grey800} ml={1.5} mr={1.5}>
                  Реквізити
                </Typography>
                <img src={HalfDivider} />
              </Box>
              <Box>
                <InfoItem
                  title="Юридична назва компанії або ФОП"
                  value={iban?.organizationName || ""}
                  copyEnabled
                />
                <InfoItem
                  title="ЄДРПОУ / РНОКПП"
                  value={iban?.identificationCode || ""}
                  copyEnabled
                />
                <InfoItem
                  title="IBAN"
                  value={iban?.iban ? ibanFormat(iban.iban) : ""}
                  copyEnabled
                  copyWithoutSpaces
                />
                <InfoItem
                  title="Призначення платежу"
                  value={iban?.paymentPurpose || ""}
                  copyEnabled
                />
              </Box>
              {(isIOS || isAndroid || isMobile || isTablet) && (
                <>
                  <img src={Divider} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={2}
                    mb={2}
                  >
                    <Button
                      sx={mainStyles.primaryButton}
                      href={constructPaymentUrl()}
                      onClick={onPay}
                      fullWidth
                    >
                      Оплатити {getPriceFormat(iban?.amount || 0)} ₴
                    </Button>
                    <Typography
                      sx={[mainStyles.ui2, { color: grey700 }]}
                      mt={1.5}
                    >
                      Відкриється ваш улюблений додаток.
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                    mb={2}
                  >
                    <img src={BankDivider} />
                    <Typography
                      sx={[mainStyles.ui2, { color: grey800 }]}
                      ml={1.5}
                      mr={1.5}
                    >
                      або оберіть зі списку
                    </Typography>
                    <img src={BankDivider} />
                  </Box>
                  <Banks
                    preferedBankName={preferedBank}
                    setPreferedBank={setPreferedBank}
                    constructBankUrl={constructBankUrl}
                  />
                </>
              )}
            </Paper>
            {!(isAndroid || isIOS || isTablet || isTablet) && (
              <>
                {iban?.ibanPaymentUrl && (
                  <Box sx={styles.qrContainer}>
                    <Box sx={styles.qrWrapper}>
                      <QRCodeSVG
                        value={iban?.ibanPaymentUrl}
                        size={160}
                        level="Q"
                        imageSettings={{
                          src: Logo,
                          width: 50,
                          height: 50,
                          excavate: true,
                        }}
                      />
                    </Box>
                    <Typography
                      sx={[mainStyles.body2, { color: grey000 }]}
                      mt={1}
                    >
                      Скануйте QR-код, щоб оплатити через ваш улюблений додаток.
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IbanQr;
