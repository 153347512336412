import React, { useState } from "react";

import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Paper } from "./styles";
import { ReactComponent as IconClose } from "assets/images/icon_close.svg";
import { ReactComponent as IconReportsBlue } from "assets/images/icon_reports_blue.svg";
import WorkShiftReportDialog, {
  ReportType,
} from "../RegisterActionsModals/WorkShiftReportDialog";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";
import dayjs from "dayjs";

const dayOfWeek: Map<string, string> = new Map([
  ["Monday", "Понеділок"],
  ["Tuesday", "Вівторок"],
  ["Wednesday", "Середа"],
  ["Thursday", "Четвер"],
  ["Friday", "Пʼятниця"],
  ["Saturday", "Субота"],
  ["Sunday", "Неділя"],
]);

const ShiftBlock: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(ReportType.xReport);

  const workShift = useSelector((state) => state.workShift.workShift);

  const formXReport = () => {
    analytics().sendEvent(AnalyticsEvents.sales_x_report_btn);
    setType(ReportType.xReport);
    setModalOpen(true);
  };

  const formZReport = () => {
    analytics().sendEvent(AnalyticsEvents.sales_close_shift_btn);
    setType(ReportType.zReport);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    analytics().sendEvent(
      type === ReportType.xReport
        ? AnalyticsEvents.sales_x_report_close
        : AnalyticsEvents.sales_close_shift_modal_cancel
    );
    setModalOpen(false);
  };

  return (
    <>
      <Box width={{ xs: "90%", sm: "48%", md: "30%" }}>
        <Paper variant="outlined">
          <Typography variant="body2">Зміна відкрита:</Typography>
          <Typography variant="body1">
            {workShift &&
              dayOfWeek.get(
                dayjs.utc(workShift.openDateTimeUtc).local().format("dddd")
              )}{" "}
            {workShift &&
              dayjs
                .utc(workShift.openDateTimeUtc)
                .local()
                .format("DD/MM/YYYY HH:mm")}
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            alignItems={{ xs: "flex-start", lg: "center" }}
            justifyContent="space-between"
            pt={1}
          >
            <Button
              size="medium"
              variant="contained"
              color="error"
              startIcon={<IconClose />}
              onClick={formZReport}
            >
              Закрити зміну
            </Button>
            <Button
              size="medium"
              variant="text"
              color="secondary"
              startIcon={<IconReportsBlue />}
              onClick={formXReport}
            >
              Х-звіт
            </Button>
          </Box>
        </Paper>
      </Box>
      <WorkShiftReportDialog
        type={type}
        isOpenModal={modalOpen}
        onCloseModal={onCloseModal}
      />
    </>
  );
};

export default ShiftBlock;
