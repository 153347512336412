import React, {FC, useEffect} from 'react';
import { connect } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import ReactPixel from "react-facebook-pixel";

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Link from "@mui/material/Link";

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';
import { FormInput, InputEvent } from 'components/FormInput';

import getNumbersFormString from 'shared/functions/getNumbersFormString';

import { ApplicationState } from 'store';
import { actionCreators } from 'store/Registration';
import { CheckCodeRequest, TokenResponse, UserRoleEnum } from 'api/models';
import { Routes } from 'shared/constants/routes';

import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';
import { FbPixelAnalyticsEvents } from 'shared/constants/fb-pixel-analytics';

const styles = {
    input: {
        '& .MuiOutlinedInput-input': {
            fontSize: '16px',
        },
    },
  };

interface VWOSettings {
    dataStore: {
        campaigns: {
            [key: string]: {
                combination_chosen: string
            }
        }
    }
}

declare global {
    interface Window {
        VWO: VWOSettings;
    }
}

type FormValues = {
    code: string;
};

interface ReduxProps {
    phoneNumber: string,
    codeError: string,
    signUp: (data: CheckCodeRequest, callBack: (data: TokenResponse | undefined) => void) => void,
    removeErrors: () => void
}

const ConfiguredLink = ({ href, children }: { href: string, children: React.ReactNode }) => (
    <Link color={({ colors }) => colors.secondaryGrey} fontWeight={400} underline='none' href={href} target='_blank' overflow-wrap='break-word'>{children}</Link>
);

const PhoneVerification: FC<ReduxProps> = props => {
    const { phoneNumber, codeError } = props;
    const history = useHistory();
    const location = useLocation();

    const methods = useForm<FormValues>({
        mode: 'onChange'
    });

    useEffect(() => {
        if (codeError) {
            methods.setError('code', { message: codeError })
        }
        props.removeErrors();
    }, [codeError]);

    const onSubmit = (data: FormValues) => {
        props.signUp(
            { code: data.code, phone: phoneNumber },
            (data: TokenResponse | undefined) => {
                const userRole = data?.userRole;
                const route = UserRoleEnum.Accountant === userRole ? '/reports' : '/business-structure';
                history.push(location.state?.from || route, { isWelcomeDialogOpen: true });
                ReactPixel.track(FbPixelAnalyticsEvents.registration_code_provided);
                analytics().sendEvent(AnalyticsEvents.registration_code_provided);
            });
        analytics().sendEvent(AnalyticsEvents.registration_code_verification_send_code);
    };

    const onChange = ({ target }: InputEvent) => {
        methods.setValue('code', getNumbersFormString(target.value).substring(0, 4));
    };

    return (
        <Card sx={{ width: 648, margin: '0 20px' }}>
            <CardHeader title='Створення профілю ФОПкаса' />
            <CardContent>
                <Typography
                    onClick={() => history.push(Routes.registrationPhoneNumber)}
                    fontWeight={300}
                    mb={3}
                    sx={{ cursor: 'pointer' }}
                    color={({ colors }) => colors.body}
                >
                    Код був надісланий: {phoneNumber}.
                    <Link ml={1} fontWeight={500} underline='none' mb={3}>Змінити номер телефону</Link>
                </Typography>
                <FormProvider {...methods}>
                    <Typography
                        ml={2}
                        mb={.5}
                        color={({ colors }) => colors.secondaryGrey}
                        fontSize='0.8571428571428571rem'>
                        КОД ПІДТВЕРДЖЕННЯ:
                    </Typography>
                    <FormInput
                        placeholder='4 цифри із СМС'
                        sx={styles.input}
                        fullWidth
                        name='code'
                        rules={{
                            minLength: {
                                value: 4,
                                message: ''
                            },
                            onChange,
                        }}
                        autoFocus={true}
                    />
                </FormProvider>
                    <Typography mt={1} color={({ colors }) => colors.secondaryGrey} fontWeight='lighter'>
                        <br />Натискаючи &quot;Зареєструватися&quot;, ви погоджуєтесь з умовами <ConfiguredLink href='/public-offer'>Публічної оферти</ConfiguredLink> та <ConfiguredLink href='/terms-of-service'>Політики конфіденційності</ConfiguredLink>
                    </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={() => {
                    history.goBack();
                    analytics().sendEvent(AnalyticsEvents.registration_code_verification_go_back);
                }} size='large' variant='contained' color='inherit'>Назад</Button>
                <Box marginLeft='auto'>
                    <Button
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={
                            !methods.getValues('code') ||
                            !!Object.keys(methods.formState.errors).length
                        }
                        size='large'
                        variant='contained'
                    >
                        Зареєструватися
                    </Button>
                </Box>
            </CardActions>
        </Card>
    );
};

const mapStateToProps = ({ registration }: ApplicationState) => ({
    phoneNumber: registration.phoneNumber,
    codeError: registration.errors.code,
});

const mapDispatchToProps = {
    signUp: actionCreators.signUp,
    removeErrors: actionCreators.removeErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification);