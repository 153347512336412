import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import Select from "@mui/material/Select";
import { SelectInputProps } from "./types";

const FormSelect: FC<SelectInputProps> = (props) => {
  const { name, rules, defaultValue = "", ...selectProps } = props;

  const formContext = useFormContext();
  const { control } = formContext;

  const { field } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Select
      sx={{ padding: 0, height: "40px" }}
      {...selectProps}
      onChange={field.onChange}
      value={field.value}
    />
  );
};

export default FormSelect;
