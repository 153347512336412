import React, { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { FormInput, FormPhoneInput } from "components/FormInput";
import { actionCreators } from "store/Accountants";
import { AccountantContext } from "contexts/AccountantContext";
import { ApplicationState } from "store";
import { removeSpacesFormString } from "shared/functions";
import LoadingButton from "@mui/lab/LoadingButton";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

type FormValues = {
  phone: string;
  fullName: string;
};

interface ReduxProps {
  addAccountant: (
    data: FormValues,
    callBack?: (hasError: boolean) => void
  ) => void;
  removeErrors: () => void;
  phoneNumberError: string;
  fullNameError: string;
}

const Create: FC<ReduxProps> = (props) => {
  const { onCloseModal } = useContext(AccountantContext);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<FormValues>({
    mode: "onChange",
  });
  const fullName = methods.watch("fullName");
  const phone = methods.watch("phone");

  useEffect(() => {
    if (props.phoneNumberError) {
      methods.setError("phone", { message: props.phoneNumberError });
    }
    if (props.fullNameError) {
      methods.setError("fullName", { message: props.fullNameError });
    }
    props.removeErrors();
  }, [props.phoneNumberError, props.fullNameError]);

  const onSubmit = (data: FormValues) => {
    setIsLoading(true);
    props.addAccountant(
      { ...data, phone: removeSpacesFormString(data.phone) },
      (hasError) => {
        if (hasError) {
          return setIsLoading(false);
        }
        onCloseModal();
        setIsLoading(false);
        methods.reset({ fullName: "", phone: "" });
      }
    );
    analytics().sendEvent(AnalyticsEvents.accountants_add_accountant_save);
  };

  const onCancel = () => {
    analytics().sendEvent(AnalyticsEvents.accountants_add_accountant_cancel);
    onCloseModal();
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={0.5} variant="body2">
              ПРІЗВИЩЕ ІМ&apos;Я ПО-БАТЬКОВІ БУХГАЛТЕРА:
            </Typography>
            <FormInput fullWidth name="fullName" autoFocus={true} />
          </Box>
          <Box mt={2}>
            <Typography mb={0.5} variant="body2">
              МОБІЛЬНИЙ НОМЕР ТЕЛЕФОНУ:
            </Typography>
            <FormPhoneInput fullWidth name="phone" />
          </Box>
        </FormProvider>

        <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
          <Typography
            fontWeight={300}
            color={(theme) => theme.colors.secondaryBlue}
          >
            Бухгалтер матиме доступ лише до Звітів і не матиме можливості
            здійснювати інші операції, такі як робота з касами чи іншими
            налаштуваннями.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          size="large"
          variant="contained"
          color="inherit"
          disabled={isLoading}
        >
          Відмінити
        </Button>
        <LoadingButton
          size="large"
          onClick={methods.handleSubmit(onSubmit)}
          loading={isLoading}
          disabled={
            !phone ||
            !fullName ||
            phone === "+380" ||
            !!Object.keys(methods.formState.errors).length
          }
        >
          Зберегти
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  phoneNumberError: state.accountants.errors.phoneNumber,
  fullNameError: state.accountants.errors.fullName,
});

const mapDispatchToProps = {
  addAccountant: actionCreators.addAccountant,
  removeErrors: actionCreators.removeErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
