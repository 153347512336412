import React, { useEffect } from "react";

import Box from "@mui/material/Box";

import IbanHeader from "components/Header/IbanHeader";
import Footer from "components/Footer";

import Main from "./Main";
import HowItWorks from "./HowItWorks";
import SocialProof from "./SocialProof";
import InstallApp from "./InstallApp";
import FAQ from "./FAQ";
import ContactUs from "./ContactUs";

import { changeFavicon } from "shared/functions/changeFavicon";

import { grey000 } from "./styles";

const IbanOplata: React.FC = () => {
  useEffect(() => {
    document.title = "IBAN oplata";
    changeFavicon("favicon-iban.ico");

    return () => {
      document.title = "ФОПкаса";
      changeFavicon("favicon.ico");
    };
  }, []);

  return (
    <>
      <IbanHeader />
      <Box sx={styles.main}>
        <Main />
        <HowItWorks />
        <SocialProof />
        <InstallApp />
        <FAQ />
        <ContactUs />
      </Box>
      <Footer />
    </>
  );
};

const styles = {
  main: {
    backgroundColor: grey000,
  },
};

export default IbanOplata;
