import React, { FC } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Loader from "components/Loader";
import ReceiptReturnComponent from "containers/ReceiptReturn/ReceiptReturnComponent";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { ApplicationState } from "store";
import { ReceiptReturnResponse } from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface ReduxProps {
  receiptReturn: ReceiptReturnResponse | null;
}

interface Props {
  isOpenModal: boolean;
  receiptIsLoading: boolean;
  onCloseModal: () => void;
  onSendReceipt: () => void;
}

const ReceiptReturnDialog: FC<Props & ReduxProps> = (props) => {
  const {
    receiptReturn,
    isOpenModal,
    receiptIsLoading,
    onCloseModal,
    onSendReceipt,
  } = props;

  const onSend = () => {
    onSendReceipt();
    analytics().sendEvent(AnalyticsEvents.sales_send_receipt_modal_btn);
  };

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { minHeight: 420, maxWidth: 424 } }}
    >
      {!receiptIsLoading && receiptReturn ? (
        <>
          <DialogTitle>
            Чек №{receiptReturn?.fiscalNumber || receiptReturn?.localNumber}
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <ReceiptReturnComponent receiptReturn={receiptReturn} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onSend}
              size="large"
              variant="contained"
              color="inherit"
              fullWidth
            >
              Надіслати
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={onCloseModal}
              fullWidth
            >
              Закрити
            </Button>
          </DialogActions>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flex="1"
        >
          <Loader />
        </Box>
      )}
    </Dialog>
  );
};

const mapStateToProps = ({ receiptReturn }: ApplicationState) => ({
  receiptReturn: receiptReturn.receiptReturn,
});

export default connect(mapStateToProps, {})(ReceiptReturnDialog);
