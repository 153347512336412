import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReportTable, HeadCell, Row } from "components/Table";

import { actionCreators } from "store/WorkShift";
import { CashRegisterOverviewResponse } from "api/models";
import { Routes } from "shared/constants/routes";

import dayjs from "dayjs";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface IProps {
  registers: CashRegisterOverviewResponse[];
}

const styles = {
  registerNameWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  registerChip: {
    marginLeft: "8px",
  },
  disabledLink: {
    cursor: "default",
    textDecoration: "none",
    opacity: 0.5,
  },
};

const Table: React.FC<IProps> = ({ registers }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const openRegister = (row: Row) => {
    analytics().sendEvent(AnalyticsEvents.sales_open_register_btn);

    if (row.openDateTimeUtc && !row.closeDateTimeUtc) {
      history.push(`${Routes.workShift}/${row.workShiftId}`);
    } else {
      dispatch(
        actionCreators.openWorkShift(
          row.cashRegisterId,
          (workShiftId?: number) => {
            history.push(`${Routes.workShift}/${workShiftId}`);
          }
        )
      );
    }
  };

  const columns: HeadCell[] = [
    {
      id: "name",
      label: "Назва каси",
      width: "74%",
      cell: (cell, row) => (
        <Box sx={styles.registerNameWrapper}>
          <Typography variant="inherit" sx={{ overflowWrap: "anywhere" }}>
            {row.name}
          </Typography>
          {!row.fiscalNumber && (
            <Chip label="Нефіскальна" size="small" sx={styles.registerChip} />
          )}
          {row.hasUnsentOfflineSession && (
            <Chip label="Офлайн" size="small" sx={styles.registerChip} />
          )}
        </Box>
      ),
    },
    {
      id: "status",
      label: "Статус",
      align: "left",
      width: "10%",
      cell: (cell, row) => {
        if (row.closeDateTimeUtc) {
          return (
            <Chip
              label={`Закрита ${dayjs
                .utc(row.closeDateTimeUtc)
                .local()
                .format("DD/MM/YYYY HH:mm")}`}
              size="small"
            />
          );
        }
        if (row.openDateTimeUtc) {
          return (
            <Chip
              label={`Відкрита ${dayjs
                .utc(row.openDateTimeUtc)
                .local()
                .format("DD/MM/YYYY HH:mm")}`}
              color="success"
              size="small"
            />
          );
        }
      },
    },
    {
      id: "openRegister",
      label: "",
      align: "right",
      width: "16%",
      cell: (cell, row) => (
        <Link
          underline="none"
          component="button"
          disabled={!row.isAvailableForUser}
          onClick={() => openRegister(row)}
          sx={!row.isAvailableForUser ? styles.disabledLink : {}}
        >
          {row.openDateTimeUtc && !row.closeDateTimeUtc
            ? "Перейти до зміни"
            : "Відкрити зміну"}
        </Link>
      ),
    },
  ];

  return (
    <ReportTable
      idName="order"
      enablePagination={false}
      rows={registers}
      columns={columns}
    />
  );
};

export default Table;
