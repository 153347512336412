import { ProductResponse } from "api";

export const filterProducts = (
  products: ProductResponse[],
  searchText: string
) => {
  if (products) {
    return products.filter((product) => {
      const productName = product.name?.toLowerCase();
      const productCode = product.code?.toLowerCase();
      searchText = searchText.trim().toLowerCase();
      return (
        productName?.includes(searchText) || productCode?.includes(searchText)
      );
    });
  }

  return [];
};
