import React from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { CategoryResponse } from "api";

import theme from "theme";

interface Props {
  categories: CategoryResponse[];
  selectedTab: string;
  handleTabChange: (e: React.SyntheticEvent, newValue: string) => void;
}

const CategoriesTabs: React.FC<Props> = (props) => {
  const { categories, selectedTab, handleTabChange } = props;

  return (
    <Tabs
      value={selectedTab}
      sx={styles.tabContainer}
      onChange={handleTabChange}
      variant="scrollable"
    >
      <Tab
        key="all"
        value="all"
        label="Всі категорії"
        sx={styles.tab}
        disableRipple
      />
      {categories.map((category) => {
        return (
          <Tab
            key={category.categoryId}
            value={category.name}
            label={category.name}
            sx={styles.tab}
            disableRipple
          />
        );
      })}
    </Tabs>
  );
};

const styles = {
  tab: {
    "&.Mui-selected": {
      bgcolor: "inherit",
      border: "none",
      borderRadius: 0,
      borderBottom: `2px ${theme.colors.secondaryBlue} solid`,
    },
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `solid 1px ${theme.colors.primaryGrey}`,
    marginBottom: "24px",
  },
};

export default CategoriesTabs;
