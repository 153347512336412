import React, { useState } from "react";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import TabPanel from "components/TabPanel";
import Typography from "@mui/material/Typography";

import Cashiers from "containers/Cashiers";
import Accountants from "containers/Accountants";
import theme from "theme";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

const styles = {
  tab: {
    "&.Mui-selected": {
      bgcolor: "inherit",
      border: "none",
      borderRadius: 0,
      borderBottom: `2px ${theme.colors.secondaryBlue} solid`,
    },
  },
};

const Users: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("cashiers");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onCloseModal = () => setIsOpenModal(false);
  const onOpenModal = () => setIsOpenModal(true);

  const onAddAccountant = () => {
    analytics().sendEvent(AnalyticsEvents.accountants_add_accountant);
    onOpenModal();
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" mt={3} mb={2} component="h1">
          Користувачі
        </Typography>
      </Box>
      <Box mb={3} display="flex" justifyContent="space-between">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
        >
          <Tab disableRipple value="cashiers" label="Касири" sx={styles.tab} />
          <Tab
            disableRipple
            value="accountants"
            label="Бухгалтери"
            sx={styles.tab}
          />
        </Tabs>

        {/* TODO: Temporarily annotated code */}
        {/* {selectedTab === "cashiers" && (
          <Button onClick={onOpenModal} size="large">
            Додати касира
          </Button>
        )} */}

        {selectedTab === "accountants" && (
          <Button onClick={onAddAccountant} size="large">
            Додати бухгалтера
          </Button>
        )}
      </Box>
      <TabPanel value={selectedTab} index="cashiers">
        <Cashiers {...{ isOpenModal, onCloseModal, onOpenModal }} />
      </TabPanel>
      <TabPanel value={selectedTab} index="accountants">
        <Accountants {...{ isOpenModal, onCloseModal, onOpenModal }} />
      </TabPanel>
    </>
  );
};

export default Users;
