import { Action, Reducer } from 'redux';
import { CashiersApi } from 'api'
import { CashierResponse, SendCodeRequest, CheckCodeRequest, ChangeUserPhoneRequest } from 'api/models'
import { AppThunkAction } from './';
import { getBase64 } from 'services/file';
import { FormValues } from './DigitalSign';
import { ApiError } from '../types';

const cashiersApi = new CashiersApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

enum ErrorFieldName {
  phoneNumber = 'phoneNumber',
  code = 'code',
  uploadKey = 'uploadKey',
}

interface ErrorPayload {
  field: ErrorFieldName,
  message: string
}

export interface CashiersState {
  cashier: CashierResponse,
  cashiers: CashierResponse[],
  errors: {
    phoneNumber: string,
    code: string,
    uploadKey: string
  }
}

enum CashiersActionTypes {
  CASHIERS_SET = 'CASHIERS_SET',
  CASHIER_SET_PHONE_NUMBER = 'CASHIER_SET_PHONE_NUMBER',
  CASHIER_GET_CODE = 'CASHIER_GET_CODE',
  CASHIER_SEND_CODE = 'CASHIER_SEND_CODE',
  CASHIER_UPLOAD_KEY = 'CASHIER_UPLOAD_KEY',
  CASHIER_SET = 'CASHIER_SET',
  CASHIER_REMOVE = 'CASHIER_REMOVE',
  CASHIER_SET_PHONE_NUMBER_ERROR = 'CASHIER_SET_PHONE_NUMBER_ERROR',
  CASHIER_SET_CODE_ERROR = 'CASHIER_SET_CODE_ERROR',
  CASHIER_SET_UPLOAD_KEY_ERROR = 'CASHIER_SET_UPLOAD_KEY_ERROR',
  CASHIER_REMOVE_ERRORS = 'CASHIER_REMOVE_ERRORS',
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface CashiersSetAction { type: CashiersActionTypes.CASHIERS_SET, payload: CashierResponse[] }
interface CashierCreateGetCodeAction { type: CashiersActionTypes.CASHIER_GET_CODE, payload: SendCodeRequest, callBack: () => void }
interface CashierCreateSetPhoneNumberAction { type: CashiersActionTypes.CASHIER_SET_PHONE_NUMBER, payload: SendCodeRequest }
interface CashierCreateSendCodeAction { type: CashiersActionTypes.CASHIER_SEND_CODE, payload: CheckCodeRequest, callBack: () => void }
interface CashierUploadKeyAction { type: CashiersActionTypes.CASHIER_UPLOAD_KEY, payload: CheckCodeRequest, callBack: () => void }
interface CashierSetAction { type: CashiersActionTypes.CASHIER_SET, payload: CashierResponse }
interface CashierRemoveAction { type: CashiersActionTypes.CASHIER_REMOVE }
interface CashierSetPhoneNumberErrorAction { type: CashiersActionTypes.CASHIER_SET_PHONE_NUMBER_ERROR, payload: ErrorPayload }
interface CashierSetCodeErrorAction { type: CashiersActionTypes.CASHIER_SET_CODE_ERROR, payload: ErrorPayload }
interface CashierSetUploadKeyErrorAction { type: CashiersActionTypes.CASHIER_SET_UPLOAD_KEY_ERROR, payload: ErrorPayload }
interface CashierRemoveErrors { type: CashiersActionTypes.CASHIER_REMOVE_ERRORS }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  CashiersSetAction |
  CashierCreateGetCodeAction |
  CashierCreateSetPhoneNumberAction |
  CashierCreateSendCodeAction |
  CashierUploadKeyAction |
  CashierSetAction |
  CashierRemoveAction |
  CashierRemoveErrors |
  CashierSetCodeErrorAction |
  CashierSetUploadKeyErrorAction |
  CashierSetPhoneNumberErrorAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setCashiers: (payload: CashierResponse[]): CashiersSetAction => ({ type: CashiersActionTypes.CASHIERS_SET, payload }),
  setCashierPhoneNumber: (payload: SendCodeRequest): CashierCreateSetPhoneNumberAction => ({ type: CashiersActionTypes.CASHIER_SET_PHONE_NUMBER, payload }),
  setCashier: (payload: CashierResponse): CashierSetAction => ({ type: CashiersActionTypes.CASHIER_SET, payload }),
  removeCashier: (): CashierRemoveAction => ({ type: CashiersActionTypes.CASHIER_REMOVE }),
  setPhoneNumberError: (payload: ErrorPayload): CashierSetPhoneNumberErrorAction => ({ type: CashiersActionTypes.CASHIER_SET_PHONE_NUMBER_ERROR, payload }),
  setCodeError: (payload: ErrorPayload): CashierSetCodeErrorAction => ({ type: CashiersActionTypes.CASHIER_SET_CODE_ERROR, payload }),
  setUploadKeyError: (payload: ErrorPayload): CashierSetUploadKeyErrorAction => ({ type: CashiersActionTypes.CASHIER_SET_UPLOAD_KEY_ERROR, payload }),
  removeErrors: (): CashierRemoveErrors => ({ type: CashiersActionTypes.CASHIER_REMOVE_ERRORS }),
  editDigitalKey: ({ files, password }: FormValues, callBack?: () => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    try {
      const { cashier: { keyId, userUid = '' } } = getState().cashiers;
      await cashiersApi.apiCashiersUpdateKeyPut({
        files: await Promise.all(Array.from(files).map(async (file) => ({
          fileName: file.name,
          contentBase64: await getBase64(file),
        }))),
        password,
        keyId,
        digitalSignOwnerUserUid: userUid
      });

      const { data } = await cashiersApi.apiCashiersGet();

      dispatch(actionCreators.setCashiers(data));
      if (callBack) {
        callBack();
      }
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionCreators.setUploadKeyError({ message: data.title, field: ErrorFieldName.uploadKey }));
    }
  },
  uploadDigitalKey: ({ files, password }: FormValues, callBack?: () => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    try {
      const { cashier: { keyId, userUid = '', phone, tin, isChiefCashier } } = getState().cashiers;
      await cashiersApi.apiCashiersUploadKeyPost({
        files: await Promise.all(Array.from(files).map(async (file) => ({
          fileName: file.name,
          contentBase64: await getBase64(file),
        }))),
        password,
        keyId,
        digitalSignOwnerUserUid: userUid
      });

      await cashiersApi.apiCashiersCreatePost({ phone, userUid, tin, keyId, isChiefCashier });
      const { data } = await cashiersApi.apiCashiersGet();

      dispatch(actionCreators.setCashiers(data));
      if (callBack) {
        callBack();
      }
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionCreators.setUploadKeyError({ message: data.title, field: ErrorFieldName.uploadKey }));
    }
  },
  changeCashierPhoneNumber: (payload: ChangeUserPhoneRequest, callBack: () => void): AppThunkAction<KnownAction> => async dispatch => {
    try {
      await cashiersApi.apiCashiersChangePhonePost(payload);
      const { data } = await cashiersApi.apiCashiersGet();

      dispatch(actionCreators.setCashiers(data));
      callBack();
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionCreators.setCodeError({ message: data.title, field: ErrorFieldName.code }));
    }
  },
  sendCashierCode: (payload: CheckCodeRequest, callBack: () => void): AppThunkAction<KnownAction> => async dispatch => {
    try {
      await cashiersApi.apiCashiersCheckCodePost(payload);

      callBack();
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionCreators.setCodeError({ message: data.title, field: ErrorFieldName.code }));
    }
  },
  getCashierCode: (payload: SendCodeRequest, callBack: () => void): AppThunkAction<KnownAction> => async dispatch => {
     try {
       await cashiersApi.apiCashiersSendCodePost(payload);

       dispatch(actionCreators.setCashierPhoneNumber(payload));
       callBack();
     } catch ({ response }) {
       const { data } = response as ApiError;
       dispatch(actionCreators.setPhoneNumberError({ message: data.title, field: ErrorFieldName.phoneNumber }));
     }
  },
  getCashiers: (): AppThunkAction<KnownAction> => async dispatch => {
    const { data } = await cashiersApi.apiCashiersGet();

    dispatch(actionCreators.setCashiers(data));
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: CashiersState = {
  cashiers: [],
  cashier: {},
  errors: {
    phoneNumber: '',
    code: '',
    uploadKey: ''
  }
};

export const reducer: Reducer<CashiersState> = (state: CashiersState = defaultState, incomingAction: Action): CashiersState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case CashiersActionTypes.CASHIERS_SET:
      return { ...state, cashiers: action.payload };
    case CashiersActionTypes.CASHIER_REMOVE:
      return { ...state, cashier: defaultState.cashier };
    case CashiersActionTypes.CASHIER_SET:
      return { ...state, cashier: action.payload}
    case CashiersActionTypes.CASHIER_SET_PHONE_NUMBER:
      return { ...state, cashier: { ...state.cashier, phone: action.payload.phone } };
    case CashiersActionTypes.CASHIER_SET_CODE_ERROR:
    case CashiersActionTypes.CASHIER_SET_PHONE_NUMBER_ERROR:
    case CashiersActionTypes.CASHIER_SET_UPLOAD_KEY_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message
        }
      };
    case CashiersActionTypes.CASHIER_REMOVE_ERRORS:
      return { ...state, errors: defaultState.errors };
    default:
      return state;
  }
};
