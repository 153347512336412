import { createContext, Dispatch, SetStateAction } from "react";

enum Steps {
  edit,
  create,
}

interface CategoryContextInterface {
  setStep: Dispatch<SetStateAction<Steps>>;
  onCloseModal: () => void;
}

const CategoryContext = createContext<CategoryContextInterface>({
  setStep: () => null,
  onCloseModal: () => null,
});

export { Steps, CategoryContext };
