import React, { useEffect } from 'react';
import { History } from 'history';

import { Routes } from 'shared/constants/routes';
import { ApplicationsUrl } from '../../shared/constants/applications';

import Logo from '../../assets/images/logo-outlined.svg';
import DownloadAndroid  from '../../assets/images/download-android-app.svg';
import DownloadIOS  from '../../assets/images/download-ios-app.svg';

import './index.scss';
import { storePages } from 'shared/constants/storePage';

interface Props {
    history: History
}

const Applications: React.FC<Props> = (props) => {
  const { location } = window;

  const queryParams = new URLSearchParams(location.search);
  const storePageName = queryParams.get("storePage") || "";
  const storePage = storePages[storePageName as keyof typeof storePages];

  useEffect(() => {
    const { userAgent } = navigator;

    const isIPhone = /iPhone/i.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);

    if (isIPhone)
      location.href =
        ApplicationsUrl.ios + (storePage ? `?ppid=${storePage.ios}` : "");
    if (isAndroid)
      location.href =
        ApplicationsUrl.android +
        (storePage ? `&listing=${storePage.android}` : "");
  }, []);

  const redirectToAppIOS = () => {
    location.href =
      ApplicationsUrl.ios + (storePage ? `?ppid=${storePage.ios}` : "");
  };

  const redirectToAppAndroid = () => {
    location.href =
      ApplicationsUrl.android +
      (storePage ? `&listing=${storePage.android}` : "");
  };

    return (
        <div className='applications'>
            <div className='applications__content'>
                <img
                    onClick={() => props.history.push(Routes.home)}
                    className='applications__content-logo pointer'
                    alt="Logo"
                    src={Logo}
                />
                <span className='applications__content-title'>
                    Завантажуйте безкоштовний додаток
                </span>
                <div className='applications__content-download'>
                    <img
                        className='pointer'
                        alt="DownloadAndroid"
                        src={DownloadAndroid}
                        onClick={redirectToAppAndroid}
                    />
                    <img
                        className='applications__content-download-ios pointer'
                        alt="DownloadIOS"
                        src={DownloadIOS}
                        onClick={redirectToAppIOS}
                    />
                </div>
            </div>
        </div>
    );
};

export default Applications;
