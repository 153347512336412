import React, { useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Row } from "components/Table";
import { SearchInput } from "components/SearchInput";

import Table from "./Table";
import ExportImportBtns from "./ExportImportBtns";
import ProductDialog from "./ProductModal/ProductDialog";
import ProductsBulkOperations from "./ProductsBulkOperations";

import { ReactComponent as IconPlus } from "assets/images/icon_plus.svg";

import { ProductResponse } from "api";
import { ApplicationState } from "store";
import { filterProducts } from "./filterProducts";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface ReduxProps {
  products: ProductResponse[];
}

const ProductTab: React.FC<ReduxProps> = (props) => {
  const { products } = props;
  const [searchText, setSearchText] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [selected, setSelected] = useState<Row[]>([]);

  const onCloseModal = () => setIsOpenModal(false);
  const onOpenModal = () => setIsOpenModal(true);

  const productsFiltered = filterProducts(products, searchText);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onSelectedChange = (selected: Row[]) => {
    setSelected(selected);
  };

  const clearValue = () => {
    setSearchText("");
  };

  const onAddProduct = () => {
    analytics().sendEvent(AnalyticsEvents.add_product_btn);
    onOpenModal();
  }

  return (
    <>
      <ProductDialog {...{ isOpenModal, onCloseModal }} />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "left", md: "center" }}
        justifyContent="space-between"
        mt={3}
        mb={2}
      >
        <Typography variant="h4" component="h1">
          Товари
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "start", sm: "center" }}
        >
          <SearchInput
            sx={styles.searchInput}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Пошук товару за назвою та кодом"
            clearValue={clearValue}
          />
          <Box display="flex" pt={1}>
            <ExportImportBtns />
            <Button onClick={onAddProduct} size="large" startIcon={<IconPlus />}>
              Додати товар
            </Button>
          </Box>
        </Box>
      </Box>
      {selected.length ? (
        <ProductsBulkOperations
          selected={selected}
          onSelectedChange={onSelectedChange}
          clearValue={clearValue}
        />
      ) : null}
      <Table
        products={productsFiltered}
        onOpenModal={onOpenModal}
        selected={selected}
        onSelectedChange={onSelectedChange}
      />
    </>
  );
};

const styles = {
  searchInput: {
    minWidth: "300px",
    maxHeight: "40px",
    marginTop: "8px",
    marginRight: "16px",
  },
};

const mapStateToProps = ({ product }: ApplicationState) => ({
  products: product.products,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTab);
