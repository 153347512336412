import { Action, Reducer } from "redux";
import { ApiError } from "types";
import { CategoryApi } from "../api";
import {
  CategoryResponse,
  CreateCategoryRequest,
  DeleteCategoryRequest,
  UpdateCategoryRequest,
} from "../api/models";
import { AppThunkAction } from "./";
import {
  actionCreators as notificationActions,
  SetNotificationAction,
} from "./Notification";

const categoryApi = new CategoryApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CategoryState {
  isLoading: boolean;
  categories: CategoryResponse[];
  selectedCategory: CategoryResponse | null;
}

enum CategoryActionTypes {
  SET_IS_LOADING = "SET_IS_LOADING",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCategoriesAction {
  type: CategoryActionTypes.SET_CATEGORIES;
  payload: CategoryResponse[];
}
export interface SetSelectedCategoryAction {
  type: CategoryActionTypes.SET_SELECTED_CATEGORY;
  payload: CategoryResponse | null;
}
export interface SetIsLoadingAction {
  type: CategoryActionTypes.SET_IS_LOADING;
  payload: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | SetCategoriesAction
  | SetSelectedCategoryAction
  | SetNotificationAction
  | SetIsLoadingAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setIsLoading: (payload: boolean): SetIsLoadingAction => ({
    type: CategoryActionTypes.SET_IS_LOADING,
    payload,
  }),
  setCategories: (payload: CategoryResponse[]): SetCategoriesAction => ({
    type: CategoryActionTypes.SET_CATEGORIES,
    payload,
  }),
  setSelectedCategory: (
    payload: CategoryResponse | null
  ): SetSelectedCategoryAction => ({
    type: CategoryActionTypes.SET_SELECTED_CATEGORY,
    payload,
  }),
  getCategories: (): AppThunkAction<KnownAction> => async (dispatch) => {
    try {
      dispatch(actionCreators.setIsLoading(true));

      const { data } = await categoryApi.apiCategoryGet();

      dispatch(actionCreators.setCategories(data));
    } catch ({ response }) {
      const { data } = response as ApiError;

      dispatch(
        notificationActions.setNotification({
          message: data.title,
          severity: "error",
        })
      );
    } finally {
      dispatch(actionCreators.setIsLoading(false));
    }
  },
  deleteCategory:
    (
      body: DeleteCategoryRequest,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        dispatch(actionCreators.setIsLoading(true));

        await categoryApi.apiCategoryDelete(body);

        if (onSuccess) {
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError();
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      } finally {
        dispatch(actionCreators.setIsLoading(false));
      }
    },
  createCategory:
    (
      body: CreateCategoryRequest,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        dispatch(actionCreators.setIsLoading(true));

        await categoryApi.apiCategoryPost(body);

        if (onSuccess) {
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError();
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      } finally {
        dispatch(actionCreators.setIsLoading(false));
      }
    },
  updateCategory:
    (
      body: UpdateCategoryRequest,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        dispatch(actionCreators.setIsLoading(true));

        await categoryApi.apiCategoryPut(body);

        if (onSuccess) {
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError();
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      } finally {
        dispatch(actionCreators.setIsLoading(false));
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: CategoryState = {
  isLoading: false,
  categories: [],
  selectedCategory: null,
};

export const reducer: Reducer<CategoryState> = (
  state: CategoryState = defaultState,
  incomingAction: Action
): CategoryState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case CategoryActionTypes.SET_CATEGORIES:
      return { ...state, categories: action.payload };
    case CategoryActionTypes.SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    case CategoryActionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
