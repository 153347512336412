import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { ReceiptReturnApi } from "api";
import { ReceiptReturnResponse } from "api/models";

import Loader from "components/Loader";
import ReceiptReturnComponent from "./ReceiptReturnComponent";

import "./index.scss";

interface Params {
  uid: string;
}

const receiptReturnApi = new ReceiptReturnApi();

const ReceiptReturn: React.FC = () => {
  const { uid } = useParams<Params>();
  const [receipt, setReceipt] = useState<ReceiptReturnResponse | null>(null);
  const [isReceiptLoading, setIsReturnReceiptLoading] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsReturnReceiptLoading(true);
        const { data } = await receiptReturnApi.apiReceiptReturnUidGet(uid);
        setReceipt(data);
      } finally {
        setIsReturnReceiptLoading(false);
      }
    })();
  }, [uid]);

  if (isReceiptLoading) {
    return <Loader isLoadingPage />;
  }

  return <ReceiptReturnComponent receiptReturn={receipt} />;
};

export default ReceiptReturn;
