import { Action, Reducer } from "redux";

import { PaymentTypeEnum, ProductResponse } from "../api/models";
import { SetNotificationAction } from "./Notification";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OrderProduct extends Omit<ProductResponse, "price"> {
  quantity: number | null;
  price: number | null;
}

export enum DiscountTypeEnum {
  Percentage = "Percentage",
  Amount = "Amount",
}

export interface OrderState {
  products: OrderProduct[];
  totalAmount: number;
  providedCashAmount: number | null;
  notes: string;
  isPublicNotes: boolean;
  paymentType: PaymentTypeEnum;
  discount: {
    value: number | null;
    type: DiscountTypeEnum;
  };
}

enum OrderActionTypes {
  UPDATE_PRODUCTS = "UPDATE_PRODUCTS",
  ADD_PRODUCT = "ADD_PRODUCT",
  REMOVE_PRODUCT = "REMOVE_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  SET_TOTAL_AMOUNT = "SET_TOTAL_AMOUNT",
  SET_PROVIDED_CASH_AMOUNT = "SET_PROVIDED_CASH_AMOUNT",
  SET_NOTES = "SET_NOTES",
  SET_IS_PUBLIC_NOTES = "SET_IS_PUBLIC_NOTES",
  SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE",
  SET_DISCOUNT_VALUE = "SET_DISCOUNT_VALUE",
  SET_DISCOUNT_TYPE = "SET_DISCOUNT_TYPE",
  CLEAN_ORDER = "CLEAN_ORDER",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface UpdateProductsAction {
  type: OrderActionTypes.UPDATE_PRODUCTS;
  payload: OrderProduct[];
}

export interface AddProductAction {
  type: OrderActionTypes.ADD_PRODUCT;
  payload: ProductResponse;
}

export interface RemoveProductAction {
  type: OrderActionTypes.REMOVE_PRODUCT;
  payload: OrderProduct;
}

export interface UpdateProductAction {
  type: OrderActionTypes.UPDATE_PRODUCT;
  payload: {
    productUid: string;
    quantity: number | null;
    price: number | null;
  };
}

export interface SetTotalAmountAction {
  type: OrderActionTypes.SET_TOTAL_AMOUNT;
}

export interface SetProvidedCashAmountAction {
  type: OrderActionTypes.SET_PROVIDED_CASH_AMOUNT;
  payload: number | null;
}

export interface SetNotesAction {
  type: OrderActionTypes.SET_NOTES;
  payload: string;
}

export interface SetIsPublicNotesAction {
  type: OrderActionTypes.SET_IS_PUBLIC_NOTES;
  payload: boolean;
}

export interface SetPaymentTypeAction {
  type: OrderActionTypes.SET_PAYMENT_TYPE;
  payload: PaymentTypeEnum;
}

export interface SetDiscountValueAction {
  type: OrderActionTypes.SET_DISCOUNT_VALUE;
  payload: number | null;
}

export interface SetDiscountTypeAction {
  type: OrderActionTypes.SET_DISCOUNT_TYPE;
  payload: DiscountTypeEnum;
}

export interface CleanOrderAction {
  type: OrderActionTypes.CLEAN_ORDER;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | UpdateProductsAction
  | AddProductAction
  | RemoveProductAction
  | UpdateProductAction
  | SetTotalAmountAction
  | SetProvidedCashAmountAction
  | SetNotesAction
  | SetIsPublicNotesAction
  | SetPaymentTypeAction
  | SetDiscountValueAction
  | SetDiscountTypeAction
  | CleanOrderAction
  | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  updateProducts: (payload: OrderProduct[]): UpdateProductsAction => ({
    type: OrderActionTypes.UPDATE_PRODUCTS,
    payload,
  }),
  addProductToOrder: (payload: ProductResponse): AddProductAction => ({
    type: OrderActionTypes.ADD_PRODUCT,
    payload,
  }),
  removeProductFromOrder: (payload: OrderProduct): RemoveProductAction => ({
    type: OrderActionTypes.REMOVE_PRODUCT,
    payload,
  }),
  updateProductInOrder: (payload: {
    productUid: string;
    quantity: number | null;
    price: number | null;
  }): UpdateProductAction => ({
    type: OrderActionTypes.UPDATE_PRODUCT,
    payload,
  }),
  setTotalAmount: (): SetTotalAmountAction => ({
    type: OrderActionTypes.SET_TOTAL_AMOUNT,
  }),
  setProvidedCashAmount: (
    payload: number | null
  ): SetProvidedCashAmountAction => ({
    type: OrderActionTypes.SET_PROVIDED_CASH_AMOUNT,
    payload,
  }),
  setNotes: (payload: string): SetNotesAction => ({
    type: OrderActionTypes.SET_NOTES,
    payload,
  }),
  setIsPublicNotes: (payload: boolean): SetIsPublicNotesAction => ({
    type: OrderActionTypes.SET_IS_PUBLIC_NOTES,
    payload,
  }),
  setPaymentType: (payload: PaymentTypeEnum): SetPaymentTypeAction => ({
    type: OrderActionTypes.SET_PAYMENT_TYPE,
    payload,
  }),
  setDiscountValue: (payload: number | null): SetDiscountValueAction => ({
    type: OrderActionTypes.SET_DISCOUNT_VALUE,
    payload,
  }),
  setDiscountType: (payload: DiscountTypeEnum): SetDiscountTypeAction => ({
    type: OrderActionTypes.SET_DISCOUNT_TYPE,
    payload,
  }),
  cleanOrder: (): CleanOrderAction => ({
    type: OrderActionTypes.CLEAN_ORDER,
  }),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: OrderState = {
  products: [],
  totalAmount: 0,
  providedCashAmount: 0,
  notes: "",
  isPublicNotes: false,
  paymentType: PaymentTypeEnum.Cash,
  discount: {
    value: 0,
    type: DiscountTypeEnum.Percentage,
  },
};

export const reducer: Reducer<OrderState> = (
  state: OrderState = defaultState,
  incomingAction: Action
): OrderState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case OrderActionTypes.UPDATE_PRODUCTS:
      return { ...state, products: action.payload };
    case OrderActionTypes.ADD_PRODUCT: {
      const product = state.products.find(
        (product) => product.productUid === action.payload.productUid
      );

      if (product) {
        return {
          ...state,
          products: state.products.map((item) => {
            if (item.productUid === action.payload.productUid) {
              return {
                ...item,
                quantity: (item.quantity || 0) + 1,
              };
            }
            return item;
          }),
        };
      }

      return {
        ...state,
        products: [
          ...state.products,
          { ...action.payload, price: action.payload.price || 0, quantity: 1 },
        ],
      };
    }
    case OrderActionTypes.REMOVE_PRODUCT: {
      return {
        ...state,
        products: state.products.filter(
          (product) => product.productUid !== action.payload.productUid
        ),
      };
    }
    case OrderActionTypes.UPDATE_PRODUCT: {
      const product = state.products.find(
        (product) => product.productUid === action.payload.productUid
      );

      return {
        ...state,
        products: state.products.map((item) => {
          if (item.productUid === product?.productUid) {
            return {
              ...product!,
              quantity: action.payload.quantity,
              price: action.payload.price,
            };
          }
          return item;
        }),
      };
    }
    case OrderActionTypes.SET_TOTAL_AMOUNT: {
      const priceAmount = state.products.reduce(
        (acc, item) => (item.quantity || 0) * (item.price || 0) + acc,
        0
      );
      return {
        ...state,
        totalAmount: priceAmount,
      };
    }
    case OrderActionTypes.SET_PROVIDED_CASH_AMOUNT:
      return { ...state, providedCashAmount: action.payload };
    case OrderActionTypes.SET_NOTES:
      return { ...state, notes: action.payload };
    case OrderActionTypes.SET_IS_PUBLIC_NOTES:
      return { ...state, isPublicNotes: action.payload };
    case OrderActionTypes.SET_PAYMENT_TYPE:
      return { ...state, paymentType: action.payload };
    case OrderActionTypes.SET_DISCOUNT_VALUE:
      return {
        ...state,
        discount: { value: action.payload, type: state.discount.type },
      };
    case OrderActionTypes.SET_DISCOUNT_TYPE:
      return {
        ...state,
        discount: { type: action.payload, value: state.discount.value },
      };
    case OrderActionTypes.CLEAN_ORDER:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
