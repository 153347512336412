import React, { forwardRef } from "react";
import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
}

const CustomNumericFormat = forwardRef<NumericFormatProps, CustomProps>(
  function CustomNumericFormat(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator=" "
        valueIsNumericString
        decimalScale={2}
        allowNegative={false}
        isAllowed={(values) => {
          const { value } = values;
          const withoutZeros = value.replace(/^0+(?=[^.])/g, "");
          const isMatching = value === withoutZeros;

          if (props.isAllowed) {
            return isMatching && props.isAllowed(values);
          }

          return isMatching;
        }}
        {...other}
      />
    );
  }
);

export default CustomNumericFormat;
