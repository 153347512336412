import React, { FC } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { actionCreators } from "store/Drawer";
import { HeaderLink, sideMenuLinks } from "shared/constants/header-links";
import { ReactComponent as IconDrawerArrow } from "assets/images/icon_drawer_arrow.svg";
import {
  Drawer,
  DrawerHeader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "./styles";

interface ReduxProps {
  setDrawer: (drawerOpen: boolean) => void;
}

const SideMenu: FC<ReduxProps & RouteComponentProps> = (props) => {
  const drawerOpen = useSelector(({ drawer }) => drawer.drawerOpen);
  const userRole = useSelector(({ user }) => user.data.userRole);
  const authorized = useSelector(state => state.user.authorized);
  const isExcluded = props.location.pathname == '/delete-account';

  const linksFiltered: HeaderLink[][] = sideMenuLinks
    .map((row) =>
      row.filter((link) => userRole && link.allowedRoles.includes(userRole))
    )
    .filter((row) => row.length > 0);

  const handleDrawerOpen = () => {
    props.setDrawer(true);
  };

  const handleDrawerClose = () => {
    props.setDrawer(false);
  };

  const renderLinks = (headerLinks: HeaderLink[], index: number) => {
    const isLast = linksFiltered.length - 1 === index;

    return (
      <>
        {headerLinks.map((link) => (
          <ListItem
            key={link.route}
            disablePadding
            selected={props.location.pathname == link.route}
          >
            <ListItemButton
              open={drawerOpen}
              onClick={() => props.history.push(link.route)}
            >
              <ListItemIcon open={drawerOpen}>
                <Box>{link.icon}</Box>
              </ListItemIcon>

              <ListItemText open={drawerOpen} primary={link.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {!isLast && <Divider sx={{ marginTop: "-4px", marginBottom: "8px" }} />}
      </>
    );
  };

  if (!authorized || isExcluded) {
    return <></>
  } 

  return (
    <Drawer
      variant="permanent"
      open={drawerOpen}
      sx={{ display: { xs: "none", md: "block" } }}
    >
      <DrawerHeader open={drawerOpen}>
        {drawerOpen ? (
          <>
            <Typography color={({ colors }) => colors.secondaryGrey}>
              МЕНЮ:
            </Typography>
            <IconButton
              disableRipple
              disableTouchRipple
              onClick={handleDrawerClose}
              sx={{ padding: 0 }}
            >
              <IconDrawerArrow />
            </IconButton>
          </>
        ) : (
          <IconButton
            sx={{ transform: "scaleX(-1)" }}
            disableRipple
            disableTouchRipple
            onClick={handleDrawerOpen}
          >
            <IconDrawerArrow />
          </IconButton>
        )}
      </DrawerHeader>
      <Divider />
      <List>
        {linksFiltered.map((links, index) => renderLinks(links, index))}
      </List>
    </Drawer>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setDrawer: actionCreators.setDrawer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideMenu));
