import React, { FC, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { ProductContext } from "contexts/ProductContext";
import FormSelect from "components/FormInput/FormSelect";
import { FormCashInput, FormInput } from "components/FormInput";

import {
  CategoryResponse,
  CreateProductRequest,
  ProductUnitResponse,
} from "api";
import { ApplicationState } from "store";
import { actionCreators } from "store/Product";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

type FormValues = {
  name: string;
  price: string;
  code: string;
  barcode: string;
  productUnitId: number;
  productCategoryId: number;
};

interface Props {
  defaultCategoryId?: number;
}

interface ReduxProps {
  createProduct: (
    data: CreateProductRequest,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  getProducts: () => void;
  productUnits: ProductUnitResponse[];
  categories: CategoryResponse[];
}

type AllProps = Props & ReduxProps;

const Create: FC<AllProps> = (props) => {
  const { productUnits, categories, defaultCategoryId } = props;

  const { onCloseModal } = useContext(ProductContext);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      productCategoryId:
        defaultCategoryId || (categories[0] && categories[0].categoryId),
      productUnitId: productUnits[0] && productUnits[0].productUnitId,
    },
  });

  const name = methods.watch("name") || "";
  const price = methods.watch("price") || "";

  const onSubmit = (closeModal: boolean) => (data: FormValues) => {
    setIsLoading(true);

    const { createProduct, getProducts } = props;
    const price = parseFloat(data.price);

    createProduct(
      { ...data, price },
      () => {
        analytics().sendEvent(AnalyticsEvents.add_product_btn_modal);
        getProducts();
        setIsLoading(false);
        methods.reset({});
        closeModal && onCloseModal();
      },
      () => {
        setIsLoading(false);
        closeModal && onCloseModal();
      }
    );
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={0.5} variant="body2">
              НАЗВА ТОВАРУ
              <Typography sx={{ display: "inline", color: "red" }}>
                *
              </Typography>
              :
            </Typography>
            <FormInput fullWidth name="name" autoFocus={true} />
          </Box>
          <Box display="flex">
            <Box mt={2} mr={2} flex="1">
              <Typography mb={0.5} variant="body2">
                ЦІНА ТОВАРУ
                <Typography sx={{ display: "inline", color: "red" }}>
                  *
                </Typography>
                :
              </Typography>
              <FormCashInput fullWidth name="price" />
            </Box>
            <Box mt={2} flex="1">
              <Typography mb={0.5} variant="body2">
                ШТРИХКОД:
              </Typography>
              <FormInput fullWidth name="barcode" />
            </Box>
          </Box>
          <Box display="flex">
            <Box mt={2} mr={2} flex="1">
              <Typography mb={0.5} variant="body2">
                КОД:
              </Typography>
              <FormInput fullWidth name="code" />
            </Box>
            <Box mt={2} flex="1">
              <Typography mb={0.5} variant="body2">
                ОДИНИЦЯ ВИМІРУ:
              </Typography>
              <FormSelect fullWidth name="productUnitId">
                {productUnits.map((unit) => (
                  <MenuItem key={unit.productUnitId} value={unit.productUnitId}>
                    {unit.symbol}
                  </MenuItem>
                ))}
              </FormSelect>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography mb={0.5} variant="body2">
              КАТЕГОРІЯ:
            </Typography>
            <FormSelect fullWidth name="productCategoryId">
              {categories.map((category) => (
                <MenuItem key={category.categoryId} value={category.categoryId}>
                  {category.name}
                </MenuItem>
              ))}
            </FormSelect>
          </Box>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={methods.handleSubmit(onSubmit(false))}
          size="large"
          variant="contained"
          color="inherit"
          disabled={!name.trim() || !price.trim() || price === "0" || isLoading}
        >
          Зберегти і додати ще
        </Button>
        <LoadingButton
          size="large"
          onClick={methods.handleSubmit(onSubmit(true))}
          disabled={!name.trim() || !price.trim() || price === "0" || isLoading}
          loading={isLoading}
        >
          Додати товар у базу
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ category, product }: ApplicationState) => ({
  productUnits: product.productUnits,
  categories: category.categories,
});

const mapDispatchToProps = {
  createProduct: actionCreators.createProduct,
  getProducts: actionCreators.getProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
