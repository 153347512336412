import { Action, Reducer } from 'redux';
import {
  MonoAcquiringApi,
  GetCashRegisterQrTerminalsResponseDto,
  GetMonoQrTerminalsResponseDto,
  GetQrTerminalsRequestDto,
  CashRegisterMonoQrTerminalDto,
  CreateCashRegisterQrTerminalRequestDto,
  UpdateCashRegisterQrTerminalRequestDto
} from 'api'
import { AppThunkAction } from './';
import { ApiError } from '../types';
import { actionCreators as actionNotification, SetNotificationAction } from 'store/Notification';

const acquiringApi = new MonoAcquiringApi();

enum ErrorFieldName {
  monoAccessToken = 'monoAccessToken',
}

interface ErrorPayload {
  field: ErrorFieldName,
  message: string
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// ----------------

export interface AcquiringState {
  cashRegisterQrTerminal: CashRegisterMonoQrTerminalDto;
  cashRegisterQrTerminals: GetCashRegisterQrTerminalsResponseDto;
  terminals: GetMonoQrTerminalsResponseDto;
  errors: {
    monoAccessToken?: string,
  }
}

enum AcquiringActionTypes {
  GET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS = 'GET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS',
  SET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS = 'SET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS',
  SET_ACQUIRING_MONO_QR_TERMINALS = 'SET_ACQUIRING_MONO_QR_TERMINALS',
  SET_ACQUIRING_MONO_QR_TERMINAL = 'SET_ACQUIRING_MONO_QR_TERMINAL',
  CREATE_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINAL = 'CREATE_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINAL',
  DELETE_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS_CASH_REGISTER_TERMINAL = 'DELETE_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS_CASH_REGISTER_TERMINAL',
  SET_ACQUIRING_MONO_TOKEN_ERROR = 'SET_ACQUIRING_MONO_TOKEN_ERROR',
  REMOVE_ACQUIRING_ERRORS = 'REMOVE_ACQUIRING_ERRORS',
  REMOVE_ACQUIRING_MONO_QR_TERMINAL = 'REMOVE_ACQUIRING_MONO_QR_TERMINAL',
}


// -----------------
// ACTIONS
// ----------------

interface SetAcquiringMonoCashRegisterQrTerminals { type: AcquiringActionTypes.SET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS, payload: GetCashRegisterQrTerminalsResponseDto }
interface DeleteAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminal { type: AcquiringActionTypes.DELETE_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS_CASH_REGISTER_TERMINAL }
interface SetAcquiringMonoQrTerminals { type: AcquiringActionTypes.SET_ACQUIRING_MONO_QR_TERMINALS, payload: GetMonoQrTerminalsResponseDto }
interface SetAcquiringMonoQrTerminal { type: AcquiringActionTypes.SET_ACQUIRING_MONO_QR_TERMINAL, payload: CashRegisterMonoQrTerminalDto }
interface SetAcquiringMonoTokenError { type: AcquiringActionTypes.SET_ACQUIRING_MONO_TOKEN_ERROR, payload: ErrorPayload }
interface SetAcquiringMonoTokenError { type: AcquiringActionTypes.SET_ACQUIRING_MONO_TOKEN_ERROR, payload: ErrorPayload }
interface RemoveAcquiringErrors { type: AcquiringActionTypes.REMOVE_ACQUIRING_ERRORS }
interface GetAcquiringMonoCashRegisterQrTerminals { type: AcquiringActionTypes.GET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS }
interface RemoveAcquiringMonoQrTerminal { type: AcquiringActionTypes.REMOVE_ACQUIRING_MONO_QR_TERMINAL }


export type KnownAction = SetAcquiringMonoCashRegisterQrTerminals |
  DeleteAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminal |
  SetNotificationAction |
  SetAcquiringMonoQrTerminals |
  SetAcquiringMonoQrTerminal |
  SetAcquiringMonoTokenError |
  RemoveAcquiringErrors |
  RemoveAcquiringMonoQrTerminal |
  GetAcquiringMonoCashRegisterQrTerminals

// ----------------
// ACTION CREATORS
// ----------------

export const actionCreators = {
  setAcquiringMonoTokenError: (payload: ErrorPayload): SetAcquiringMonoTokenError => ({ type: AcquiringActionTypes.SET_ACQUIRING_MONO_TOKEN_ERROR, payload }),
  setAcquiringMonoCashRegisterQrTerminals: (payload: GetCashRegisterQrTerminalsResponseDto): SetAcquiringMonoCashRegisterQrTerminals => ({ type: AcquiringActionTypes.SET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS, payload }),
  getAcquiringMonoCashRegisterQrTerminals: (): AppThunkAction<KnownAction> => async (dispatch) => {
    try {
      dispatch({ type: AcquiringActionTypes.GET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS });
      const { data } = await acquiringApi.apiAcquiringMonoCashRegisterQrTerminalsGet();

      dispatch(actionCreators.setAcquiringMonoCashRegisterQrTerminals(data));
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionNotification.setNotification({
        message: data.title,
        severity: 'error'
      }));
    }
  },
  setAcquiringMonoQrTerminal: (payload: CashRegisterMonoQrTerminalDto): SetAcquiringMonoQrTerminal => ({ type: AcquiringActionTypes.SET_ACQUIRING_MONO_QR_TERMINAL, payload }),
  setAcquiringMonoQrTerminals: (payload: GetMonoQrTerminalsResponseDto): SetAcquiringMonoQrTerminals => ({ type: AcquiringActionTypes.SET_ACQUIRING_MONO_QR_TERMINALS, payload }),
  getAcquiringMonoQrTerminals: (payload: GetQrTerminalsRequestDto, callBack?: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
    try {
      const { data } = await acquiringApi.apiAcquiringMonoQrTerminalsPost(payload);

      dispatch(actionCreators.setAcquiringMonoQrTerminals(data));
      dispatch(actionCreators.setAcquiringMonoQrTerminal(payload));
      if (callBack) callBack();
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionCreators.setAcquiringMonoTokenError({
        message: data.title,
        field: ErrorFieldName.monoAccessToken
      }));
    }
  },
  createAcquiringMonoCashRegisterQrTerminals: (payload: CreateCashRegisterQrTerminalRequestDto, callBack: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
    try {
      await acquiringApi.apiAcquiringMonoCashRegisterQrTerminalsPost(payload);
      const { data } = await acquiringApi.apiAcquiringMonoCashRegisterQrTerminalsGet();
      dispatch(actionCreators.setAcquiringMonoCashRegisterQrTerminals(data));

      callBack();
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionNotification.setNotification({
        message: data.title,
        severity: 'error'
      }));
    }
  },
  deleteAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUid: (callBack: () => void ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    try {
      dispatch({ type: AcquiringActionTypes.DELETE_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS_CASH_REGISTER_TERMINAL });
      const { acquiring: { cashRegisterQrTerminal: { uid = '' } } } = getState();
      await acquiringApi.apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidDelete(uid);

      const { data } = await acquiringApi.apiAcquiringMonoCashRegisterQrTerminalsGet();
      dispatch(actionCreators.setAcquiringMonoCashRegisterQrTerminals(data));
      callBack();
    } catch ({ response }) {
      const { data } = response as ApiError
      dispatch(actionNotification.setNotification({
        message: data.title,
        severity: 'error'
      }));
    }
  },
  updateAcquiringMonoCashRegisterQrTerminals: (payload: UpdateCashRegisterQrTerminalRequestDto, callBack: () => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    try {
      const { acquiring: { cashRegisterQrTerminal: { uid = '' } } } = getState();

      await acquiringApi.apiAcquiringMonoCashRegisterQrTerminalsCashRegisterTerminalUidPut(uid, payload);
      const { data } = await acquiringApi.apiAcquiringMonoCashRegisterQrTerminalsGet();
      dispatch(actionCreators.setAcquiringMonoCashRegisterQrTerminals(data));
      
      callBack();
    } catch ({ response }) {
      const { data } = response as ApiError;
      dispatch(actionNotification.setNotification({
        message: data.title,
        severity: 'error'
      }));
    }
  },
  removeAcquiringErrors: (): RemoveAcquiringErrors => ({ type: AcquiringActionTypes.REMOVE_ACQUIRING_ERRORS }),
  removeAcquiringMonoQrTerminal: (): RemoveAcquiringMonoQrTerminal => ({ type: AcquiringActionTypes.REMOVE_ACQUIRING_MONO_QR_TERMINAL })
};

// ----------------
// REDUCER
// ----------------

const defaultState: AcquiringState = { cashRegisterQrTerminals: {}, terminals: {}, cashRegisterQrTerminal: {}, errors: {} };

export const reducer: Reducer<AcquiringState> = (state: AcquiringState = defaultState, incomingAction: Action): AcquiringState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case AcquiringActionTypes.SET_ACQUIRING_MONO_CASH_REGISTER_QR_TERMINALS:
      return {
        ...state,
        cashRegisterQrTerminals: action.payload
      };
    case AcquiringActionTypes.SET_ACQUIRING_MONO_QR_TERMINAL:
      return {
        ...state,
        cashRegisterQrTerminal: {
          ...state.cashRegisterQrTerminal,
          ...action.payload,
        }
      };
    case AcquiringActionTypes.SET_ACQUIRING_MONO_QR_TERMINALS:
      return {
        ...state,
        terminals: action.payload
      };
    case AcquiringActionTypes.SET_ACQUIRING_MONO_TOKEN_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message
        }
      };
    case AcquiringActionTypes.REMOVE_ACQUIRING_MONO_QR_TERMINAL:
      return {
        ...state,
        cashRegisterQrTerminal: defaultState.cashRegisterQrTerminal,
        terminals: defaultState.terminals
      }
    case AcquiringActionTypes.REMOVE_ACQUIRING_ERRORS:
      return { ...state, errors: defaultState.errors };
    default:
      return state;
  }
};
