import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import InstallBg from "assets/images/install-bg.jpg";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { Routes } from "shared/constants/routes";
import { storePages } from "shared/constants/storePage";

import { blue600, grey000, mainStyles } from "../styles";

const InstallApp: React.FC = () => {
  const onAppButtonClick = () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_download_app);
  };

  return (
    <Box sx={styles.installBlock}>
      <Container>
        <Typography sx={[mainStyles.h2, { color: grey000 }]} mb={2.5}>
          Завантажуйте застосунок зараз
        </Typography>
        <Typography sx={[mainStyles.body1, { color: grey000 }]}>
          Отримуйте оплати online безкоштовно та швидко.
        </Typography>
        <Box mt={4}>
          <Button
            sx={styles.installBtn}
            onClick={onAppButtonClick}
            href={`${Routes.app}?storePage=${storePages.iban.queryName}`}
            target="_blank"
            size="large"
            variant="contained"
          >
            <Typography fontSize={14}>Завантажити додаток</Typography>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

const styles = {
  installBlock: {
    backgroundImage: `url(${InstallBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: { xs: "56px", sm: "96px" },
    paddingBottom: { xs: "56px", sm: "96px" },
    textAlign: "left",
  },
  image: {
    height: "56px",
    cursor: "pointer",
  },
  installBtn: {
    backgroundColor: grey000,
    color: blue600,
    padding: "16px 24px",
    borderRadius: "1000px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "2px",
  },
};

export default InstallApp;
