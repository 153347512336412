import * as React from "react";
import "./index.scss";

const Suitability: React.FC = () => (
  <div className="suitability">
    <div className="suitability__text-wrapper">
      <h2 className="header-normal suitability__header">
        Кому підійде програмне РРО
      </h2>
      <span className="text-normal suitability__item">
        Програмні РРО будуть цікаві малому та середньому бізнесу, 
        адже це найоптимальніший спосіб відповідати законодавчим вимогам, 
        збалансувати кошти та необхідні функції. Базові функції будь-якого ПРРО: 
        фіскалізація розрахункових операцій та робота з електронними чеками. 
        Додаткові можливості і функції у різних ПРРО різняться. 
        Деякі ПРРО пропонують опцію тестового/навчального режиму перед встановленням фіскального режиму. 
      </span>
      <br />
      <br />
      <span className="text-normal suitability__item">
        Наприклад, ПРРО ФОПкаса працює у двох режимах: фіскальному та в тестовому. 
        Тестовий режим ідеально підходить для тих, хто хоче опанувати роботу з фіскалізацією 
        та впевнитися в правильності виконання операцій перед початком відправки результатів у ДПС, 
        що є обов&apos;язковим для ведення бізнесу.        
      </span>
    </div>
  </div>
);

export default Suitability;
