import React, { FC } from 'react';
import { visuallyHidden } from '@mui/utils';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';

import { Order, HeadCell, Row } from './types'

interface IProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: readonly HeadCell[];
  rows?: Row[];
  selected?: Row[];
  checkboxSelection?: boolean;
  handleSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EnhancedTableHead: FC<IProps> = props => {
  const { order, orderBy, onRequestSort, headCells, checkboxSelection = false, handleSelectAllClick, rows, selected } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
      {checkboxSelection && selected && rows && 
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={selected.length > 0 && selected.length < rows.length}
            checked={rows.length > 0 && selected.length === rows.length}
            onChange={handleSelectAllClick}
            inputProps={{
              'aria-label': 'select_all',
            }}
          />
        </TableCell>}
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              padding: 1.5,
              color: (theme) => theme.colors.secondaryGrey,
              textTransform: 'uppercase',
              ...headCell.style,
            }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
