import React, { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DeleteButton from "components/DeleteButton";
import { FormInput } from "components/FormInput";
import { CategoryContext } from "contexts/CategoryContext";

import { actionCreators } from "store/Category";
import { ApplicationState } from "store";
import {
  CategoryResponse,
  DeleteCategoryRequest,
  UpdateCategoryRequest,
} from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

type FormValues = {
  name: string;
};

interface ReduxProps {
  updateCategory: (
    data: UpdateCategoryRequest,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  deleteCategory: (
    data: DeleteCategoryRequest,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  getCategories: () => void;
  setSelectedCategory: (category: CategoryResponse | null) => void;
  selectedCategory: CategoryResponse | null;
  categories: CategoryResponse[];
}

const Edit: FC<ReduxProps> = (props) => {
  const { onCloseModal } = useContext(CategoryContext);

  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const methods = useForm<FormValues>({
    mode: "onChange",
  });

  const name = methods.watch("name") || "";

  useEffect(() => {
    const { setSelectedCategory, selectedCategory } = props;

    methods.reset({
      name: selectedCategory?.name || "",
    });
    return () => {
      methods.reset({});
      setSelectedCategory(null);
    };
  }, []);

  const onDeleteCategory = () => {
    const { deleteCategory, getCategories, selectedCategory } = props;
    setIsDeleteInProgress(true);

    selectedCategory?.categoryId &&
      deleteCategory(
        { categoryId: selectedCategory.categoryId },
        () => {
          analytics().sendEvent(AnalyticsEvents.delete_category_btn_modal);
          getCategories();
          setIsDeleteInProgress(false);
          onCloseModal();
        },
        () => {
          setIsDeleteInProgress(false);
          onCloseModal();
        }
      );
  };

  const onSubmit = (data: FormValues) => {
    setIsUpdateInProgress(true);

    const {
      updateCategory,
      getCategories,
      setSelectedCategory,
      selectedCategory,
    } = props;

    selectedCategory?.categoryId &&
      updateCategory(
        { ...data, categoryId: selectedCategory.categoryId },
        () => {
          analytics().sendEvent(AnalyticsEvents.edit_category_btn_modal);
          getCategories();
          setIsUpdateInProgress(false);
          onCloseModal();
          methods.reset({});
          setSelectedCategory(null);
        },
        () => {
          setIsUpdateInProgress(false);
          onCloseModal();
        }
      );
  };

  const onCancel = () => {
    onCloseModal();
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={0.5} variant="body2">
              НАЗВА КАТЕГОРІЇ:
            </Typography>
            <FormInput fullWidth name="name" autoFocus={true} />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <DeleteButton
          disabled={isUpdateInProgress}
          loading={isDeleteInProgress}
          onClick={onDeleteCategory}
        >
          Видалити категорію
        </DeleteButton>
        <Box marginLeft="auto">
          <Button
            onClick={onCancel}
            disabled={isDeleteInProgress || isUpdateInProgress}
            size="large"
            variant="contained"
            color="inherit"
            sx={{ marginRight: "8px" }}
          >
            Відмінити
          </Button>
          <LoadingButton
            size="large"
            disabled={!name.trim() || isDeleteInProgress}
            onClick={methods.handleSubmit(onSubmit)}
            loading={isUpdateInProgress}
          >
            Зберегти зміни
          </LoadingButton>
        </Box>
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ category }: ApplicationState) => ({
  selectedCategory: category.selectedCategory,
  categories: category.categories,
});

const mapDispatchToProps = {
  updateCategory: actionCreators.updateCategory,
  deleteCategory: actionCreators.deleteCategory,
  getCategories: actionCreators.getCategories,
  setSelectedCategory: actionCreators.setSelectedCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
