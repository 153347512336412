import React, {FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import dayjs from 'dayjs';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Button from "@mui/material/Button";

import { FormTimeField, FormSwitch } from 'components/FormInput';

import { actionCreators } from 'store/Settings';
import { Routes } from 'shared/constants/routes';

import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

type FormValues = {
  autoCloseLocalHour: number;
  autoCloseLocalMinute: number;
  isAutoCloseEnabled: boolean;
};

const WorkShift: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const methods = useForm<FormValues>({
    mode: 'onChange'
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { setValue, handleSubmit, formState: { isDirty, isValid } } = methods;
  const workShiftSettings = useSelector(({ settings: { workShiftSettings } }) => workShiftSettings);
  const { isAutoCloseEnabled, autoCloseLocalMinute, autoCloseLocalHour, isAutoCloseAllowed } = workShiftSettings;
  const isAutoCloseEnabledValue = methods.watch('isAutoCloseEnabled');
  const autoCloseLocalHourValue = methods.watch('autoCloseLocalHour');
  const autoCloseLocalMinuteValue = methods.watch('autoCloseLocalMinute');

  useEffect(() => {
    setValue('isAutoCloseEnabled', !!isAutoCloseEnabled);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue('autoCloseLocalMinute', dayjs().minute(autoCloseLocalMinute || 0));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue('autoCloseLocalHour', dayjs().hour(autoCloseLocalHour || 0));
  },[workShiftSettings]);

  useEffect(() => {
    dispatch(actionCreators.getWorkShiftSettings());
  }, []);

  useEffect(() => {
    if (isSubmitted) setIsSubmitted(false);
  }, [isAutoCloseEnabledValue, autoCloseLocalHourValue, autoCloseLocalMinuteValue]);

  const onSubmit = ({ autoCloseLocalMinute, autoCloseLocalHour, isAutoCloseEnabled }: FormValues) => {
    dispatch(actionCreators.createOrUpdateWorkShiftSettings({
      isAutoCloseEnabled,
      autoCloseLocalMinute: dayjs(autoCloseLocalMinute).minute(),
      autoCloseLocalHour: dayjs(autoCloseLocalHour).hour(),
    }));
    setIsSubmitted(true);
    analytics().sendEvent(AnalyticsEvents.settings_work_shift_save_btn);
  };

  const autoCloseEnabledLabel = () => {
    if (typeof isAutoCloseAllowed == 'undefined' || isAutoCloseAllowed) return null;
    if (!isAutoCloseAllowed) return (
      <Chip
        sx={{ justifyContent: { xs: 'start' }, overflow: 'auto' }}
        label={
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Typography fontWeight='300' variant='subtitle2'>Доступно у тарифах </Typography>
            <Link
              sx={{ cursor: 'pointer' }}
              fontWeight='300'
              variant='subtitle2'
              ml={1}
              onClick={() => history.push(Routes.subscription)}
              underline='hover'>“Успішний” та “Успішний Рік”</Link>
          </Box>
        }
        color='primary'
        size='small'
      />
    );
  };

  return (
    <Card sx={{ overflowX: 'auto' }} variant='outlined' >
      <CardContent>
        <FormProvider {...methods}>
          <Typography variant='subtitle1'>Автоматичне закриття зміни</Typography>
          <Box mt={2} display='flex' flexDirection={{ md: 'row', xs: 'column-reverse' }} alignItems={{ md: 'center' }}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <FormSwitch disabled={!isAutoCloseAllowed} name='isAutoCloseEnabled' />
              <Typography fontWeight='300' mr={1}>Автоматично закривати зміну</Typography>
            </Box>
            {autoCloseEnabledLabel()}
          </Box>
          <Typography mt={2} mb={1} variant='body2'>
            ЧАС ЗАКРИТТЯ:
          </Typography>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <FormTimeField
              disabled={!isAutoCloseAllowed || !isAutoCloseEnabledValue}
              name='autoCloseLocalHour'
              selectedSections={'hours'}
              format='HH'
              sx={{ width: 60 }}
              rules={{ required: true }}
            />
            <Typography marginLeft={1} marginRight={1}>:</Typography>
            <FormTimeField
              disabled={!isAutoCloseAllowed || !isAutoCloseEnabledValue}
              name='autoCloseLocalMinute'
              selectedSections={'minutes'}
              format='mm'
              sx={{ width: 60, marginRight: 2 }}
              rules={{ required: true }}
            />
            <Button
              disabled={!isDirty || !isValid || isSubmitted }
              onClick={handleSubmit(onSubmit)}
              size='large'
            >
              Зберегти
            </Button>
          </Box>
          <Typography fontWeight='150'marginTop={2} variant='subtitle2'>
            Увага! Автоматично закриються лише зміни тривалістю до 24 годин.<br/>
            Рекомендуємо налаштувати відкриття та закриття змін в межах однієї календарної доби, <br/>щоб зміна завершувалася до 23:55 того ж дня.
          </Typography>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default WorkShift;
