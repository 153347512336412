import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const drawerWidth = 372;

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  width: drawerWidth,
  zIndex: 0,
  flexShrink: 0,
  height: "100%",
  boxSizing: "border-box",
  position: "relative",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  ".MuiDrawer-paper": {
    flex: 1,
    height: "100%",
    zIndex: 0,
    width: drawerWidth,
    position: "relative",
    overflowX: "hidden",
  },
}));
