import React, { FC } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { ReactComponent as IconBin } from "assets/images/icon_bin.svg";
import { ReactComponent as IconMinus } from "assets/images/icon_minus.svg";
import { ReactComponent as IconPlus } from "assets/images/icon_plus.svg";

import { CustomNumericFormat } from "components/FormInput";

import { actionCreators, OrderProduct } from "store/Order";
import theme from "theme";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface Props {
  product: OrderProduct;
}

interface ReduxProps {
  removeProductFromOrder: (data: OrderProduct) => void;
  updateProductInOrder: (data: {
    productUid: string;
    quantity: number | null;
    price: number | null;
  }) => void;
  setTotalAmount: () => void;
}

const OrderProductItem: FC<Props & ReduxProps> = (props) => {
  const { product } = props;
  const { updateProductInOrder, removeProductFromOrder, setTotalAmount } =
    props;

  const onIncrement = () => {
    updateProductInOrder({
      productUid: product.productUid!,
      price: product.price!,
      quantity: (product.quantity || 0) + 1,
    });
    setTotalAmount();
  };

  const onDecrement = () => {
    updateProductInOrder({
      productUid: product.productUid!,
      price: product.price!,
      quantity: (product.quantity || 0) - 1,
    });
    setTotalAmount();
  };

  const onChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = parseFloat(event.target.value);

    updateProductInOrder({
      productUid: product.productUid!,
      price: product.price!,
      quantity: isNaN(quantity) ? null : quantity,
    });
    setTotalAmount();
  };

  const onBlurQuantity = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const quantity = parseFloat(event.target.value);

    if (isNaN(quantity)) {
      updateProductInOrder({
        productUid: product.productUid!,
        price: product.price!,
        quantity: 1,
      });
      setTotalAmount();
    }
  };

  const onChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const price = parseFloat(event.target.value);

    updateProductInOrder({
      productUid: product.productUid!,
      quantity: product.quantity,
      price: isNaN(price) ? null : price,
    });
    setTotalAmount();
  };

  const onBlurPrice = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    const price = parseFloat(event.target.value);
    if (isNaN(price)) {
      updateProductInOrder({
        productUid: product.productUid!,
        quantity: product.quantity,
        price: 0,
      });
      setTotalAmount();
    }
  };

  const onRemoveProduct = (product: OrderProduct) => {
    removeProductFromOrder(product);
    setTotalAmount();
    analytics().sendEvent(AnalyticsEvents.sales_remove_product_from_order);
  };

  return (
    <Box sx={styles.contentContainer}>
      <Box sx={styles.headerContainer}>
        <Typography sx={styles.productName}>{product.name}</Typography>
        <IconButton
          disableRipple
          disableTouchRipple
          onClick={() => onRemoveProduct(product)}
        >
          <IconBin />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton
            disableRipple
            disableTouchRipple
            onClick={onDecrement}
            disabled={(product.quantity || 0) <= 1}
          >
            <IconMinus
              color={
                (product.quantity || 0) <= 1
                  ? theme.colors.primaryGrey
                  : theme.colors.body
              }
            />
          </IconButton>
          <TextField
            sx={styles.quantityInput}
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: CustomNumericFormat as any,
              inputProps: {
                decimalScale: 3,
                onBlur: onBlurQuantity,
                value: product.quantity,
              },
            }}
            onChange={onChangeQuantity}
            onBlur={onBlurQuantity}
            value={product.quantity}
          />
          <IconButton disableRipple disableTouchRipple onClick={onIncrement}>
            <IconPlus />
          </IconButton>
          <Box>
            <Typography
              variant="body1"
              fontWeight="100"
              color={({ colors }) => colors.secondaryGrey}
            >
              {product.unit.symbol}.
            </Typography>
          </Box>
        </Box>
        <Box>
          <TextField
            sx={{ width: "120px" }}
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: CustomNumericFormat as any,
              inputProps: {
                fixedDecimalScale: true,
              },
            }}
            onChange={onChangePrice}
            onBlur={onBlurPrice}
            value={product.price}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  contentContainer: {
    borderBottom: `solid 1px ${theme.colors.strokeLight}`,
    paddingBottom: "16px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: "16px",
  },
  productName: {
    overflowWrap: "anywhere",
    padding: "8px",
  },
  quantityInput: {
    width: "64px",
    ".MuiOutlinedInput-input": { textAlign: "center" },
  },
};

const mapDispatchToProps = {
  removeProductFromOrder: actionCreators.removeProductFromOrder,
  updateProductInOrder: actionCreators.updateProductInOrder,
  setTotalAmount: actionCreators.setTotalAmount,
};

export default connect(() => ({}), mapDispatchToProps)(OrderProductItem);
