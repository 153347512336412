import React from 'react';
import ReactPixel from "react-facebook-pixel";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';

import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { FbPixelAnalyticsEvents } from 'shared/constants/fb-pixel-analytics';


interface IProps {
  open: boolean,
  onClose: () => void
}

const WelcomeModal: React.FC<IProps> = ({ open, onClose }) => {

  const onAppLinkClick = () => {
    analytics().sendEvent(AnalyticsEvents.business_structure_welcome_modal_app_link_click);
    ReactPixel.track(FbPixelAnalyticsEvents.bs_modal_welcome_app_link_click);
  };

  const onAppButtonClick = () => {
    analytics().sendEvent(AnalyticsEvents.business_structure_welcome_modal_app_btn_click);
    ReactPixel.track(FbPixelAnalyticsEvents.bs_modal_welcome_app_btn_click);
  };
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Вітаємо в ФОПкаса!
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight={300} paragraph>
          Щоб розпочати видавати чеки, <Link onClick={onAppLinkClick} underline='none' target='_blank' href='/app'>завантажте</Link> мобільний додаток. Додаток може працювати в тестовому та фіскальному режимах. Ви можете спробувати усі функції ФОПкаса без надсилання даних в ДПС.
        </Typography>
        <Typography fontWeight={300} paragraph>
         Спробуйте видати декілька тестових чеків в додатку ФОПкаса та підключіть фіскальну касу, використовуючи інструкції у додатку.
        </Typography>
        <Typography fontWeight={300} paragraph>
        У разі потреби, Служба підтримки завжди готова надати вам допомогу.
        </Typography>

        <Box display='flex' alignItems='center' justifyContent='center'>
          <Button onClick={onAppButtonClick} href='/app' target='_blank' size='large' variant='contained'>         
              <Typography fontSize={14}>Завантажити додаток</Typography>
          </Button>
        </Box>

      </DialogContent>
    </Dialog>
  )
};

export default WelcomeModal;
