import React, { FC } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import DownloadIOS from "assets/images/download-ios-app.svg";
import DownloadAndroid from "assets/images/download-android-app.svg";
import { ReactComponent as Logo } from "assets/images/logo-filled.svg";

import { Routes } from "shared/constants/routes";
import { ApplicationsUrl } from "shared/constants/applications";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";
import { storePages } from "shared/constants/storePage";

const AppBarMui = styled(AppBar)(({ theme }) => ({
  position: "sticky",
  height: 56,
  background: theme.palette.common.white,
  display: "flex",
  boxShadow: "0 4px 8px rgba(95, 98, 115, 0.16)",
  flexDirection: "row",
}));

interface HeaderProps {
  website?: boolean;
}

type AllProps = HeaderProps & RouteComponentProps;

const IbanHeader: FC<AllProps> = (props) => {
  const phoneNumber = "+380980965020";
  const email = "info@fopkasa.com";

  const links = {
    viber: `viber://chat?number=${phoneNumber}`,
    telegram: `tg://resolve?phone=${phoneNumber}`,
    email: `mailto:${email}`,
  };

  const redirectToAppIOS = () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_download_app_ios);
    location.href = ApplicationsUrl.ios + storePages.iban.ios;
  };

  const redirectToAppAndroid = () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_download_app_andr);
    location.href = ApplicationsUrl.android + storePages.iban.android;
  };

  const renderContactLinks = () => {
    return (
      <Box sx={styles.linksContainer}>
        <Box sx={styles.socialContainer}>
          <Link
            href={links.viber}
            sx={{ marginRight: "20px" }}
            underline="none"
            color={(theme) => theme.colors.body}
          >
            Viber
          </Link>
          <Link
            href={links.telegram}
            underline="none"
            color={(theme) => theme.colors.body}
          >
            Telegram
          </Link>
        </Box>
        <Box sx={styles.emailContainer}>
          <Link
            href={links.email}
            underline="none"
            color={(theme) => theme.colors.body}
          >
            info@fopkasa.com
          </Link>
        </Box>
      </Box>
    );
  };

  const renderButtons = () => {
    return (
      <Box sx={styles.buttonsContainer}>
        <img
          style={{ ...styles.image, ...styles.androidApp }}
          alt="DownloadAndroid"
          src={DownloadAndroid}
          onClick={redirectToAppAndroid}
        />
        <img
          style={styles.image}
          alt="DownloadIOS"
          src={DownloadIOS}
          onClick={redirectToAppIOS}
        />
      </Box>
    );
  };

  return (
    <AppBarMui>
      <Container sx={styles.headerContainer}>
        <Logo
          style={{ cursor: "pointer", height: "40px" }}
          onClick={() => props.history.push(Routes.home)}
        />
        {renderContactLinks()}
        {renderButtons()}
      </Container>
    </AppBarMui>
  );
};

const styles = {
  headerContainer: {
    alignItems: "center",
    display: "flex",
  },
  linksContainer: {
    marginLeft: "auto",
    display: "block",
  },
  socialContainer: {
    marginRight: "20px",
    display: "inline",
  },
  emailContainer: {
    display: { xs: "none", md: "inline" },
    paddingLeft: "20px",
    paddingRight: "20px",
    borderLeft: "solid 1px #CDCDCD",
  },
  buttonsContainer: {
    marginLeft: "20px",
    display: { xs: "none", md: "block" },
  },
  image: {
    height: "40px",
    cursor: "pointer",
  },
  androidApp: {
    marginRight: "12px",
  },
};

export default withRouter(IbanHeader);
