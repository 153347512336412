import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { FormInput, InputEvent } from 'components/FormInput';
import Card from 'containers/Authorization/components/Card';

import { CheckCodeRequest, TokenResponse, UserRoleEnum } from 'api/models';
import { actionCreators } from 'store/Authorization';
import { Routes } from 'shared/constants/routes';

import { ReactComponent as LogoAuth } from 'assets/images/logo-auth.svg';
import { ReactComponent as DeleteInput } from 'assets/images/icon_delete_input.svg';

import getNumbersFormString from 'shared/functions/getNumbersFormString';
import { ApplicationState } from 'store';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const styles = {
    button: {
        height: '40px',
        width: '100%',
        marginTop: '24px'
    },
    input: {
        width: '100%',
        marginTop: '16px',
        '& .MuiOutlinedInput-input': {
            fontSize: '16px',
        },
    },
    changeNumber: {
        color: '#0570E3',
        textDecoration: 'none',
        cursor: 'pointer'
    },
    deleteIcon: {
        cursor: 'pointer',
    }
}

interface ReduxProps {
    phoneNumber: string,
    codeError: string,
    sendAuthCode: (data: CheckCodeRequest, callBack: (data: TokenResponse | undefined) => void) => void,
    removeErrors: () => void,
}

type FormValues = {
    code: string;
};

const Verification: FC<ReduxProps> = props => {
    const history = useHistory();
    const location = useLocation();
    const { codeError } = props;

    useEffect(() => {
        if (!props.phoneNumber) {
            history.push(Routes.login)
        }
    }, []);

    useEffect(() => {
        if (codeError) {
            methods.setError('code', { message: codeError })
        }
        props.removeErrors();
    }, [codeError]);

    const methods = useForm<FormValues>({
        mode: 'onChange'
    });

    const onSubmit = (data: FormValues) => {
        props.sendAuthCode({
            code: data.code,
            phone: props.phoneNumber,
        },
            (data: TokenResponse | undefined) => {
                const userRole = data?.userRole;
                const route = UserRoleEnum.Accountant === userRole ? '/reports' : '/business-structure';
                history.push(location.state?.from || route);
            });
        analytics().sendEvent(AnalyticsEvents.login_code_verification_send_code);
    };

    const clearValue = () => {
        methods.setValue('code', '');
        methods.clearErrors();
    };

    const onChange = ({ target }: InputEvent) => {
        const filteredValue = getNumbersFormString(target.value).substring(0, 4);
        methods.setValue('code', filteredValue);
    };

    return (
        <Card>
            <Box display='flex' justifyContent='center'>
                <LogoAuth />
            </Box>
            <Typography variant='h4' mt={5}>Введіть код</Typography>
            <Typography fontWeight={300} mb={0.5} mt={1}>Код був надісланий на: {props.phoneNumber}</Typography>
            <Link onClick={() => history.goBack()} style={styles.changeNumber}>Змінити номер телефону</Link>
            <FormProvider {...methods}>
                <FormInput
                    name='code'
                    placeholder='1234'
                    sx={styles.input}
                    rules={{
                        minLength: {
                            value: 4,
                            message: ''
                        },
                        onChange,
                    }}
                    InputProps={{
                        endAdornment: <DeleteInput style={styles.deleteIcon} onClick={clearValue} />
                    }}
                    autoFocus={true}
                />
                <Button
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={
                        !methods.getValues('code') ||
                        !!Object.keys(methods.formState.errors).length
                    }
                    sx={styles.button}
                >
                    <Typography fontSize={14}>Продовжити</Typography>
                </Button>
            </FormProvider>
            <Button onClick={() => {
                history.push(Routes.registrationPhoneNumber);
                analytics().sendEvent(AnalyticsEvents.login_phone_verification_redirect_to_registration);
            }} sx={styles.button} variant="contained" color='inherit'>
                <Typography fontSize={14}>Зареєструватися</Typography>
            </Button>
        </Card>
    );
};

const mapStateToProps = ({ authorization: { errors, phoneNumber, } }: ApplicationState) => ({
    phoneNumber,
    codeError: errors.code
});

const mapDispatchToProps = {
    sendAuthCode: actionCreators.sendAuthCode,
    removeErrors:  actionCreators.removeErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
