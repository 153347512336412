import * as React from "react";
import Comparison from "./components/Comparison";
import Differentiation from "./components/Differentiation";
import Suitability from "./components/Suitability";
import Options from "./components/Options";
import Advantages from "./components/Advantages";
import UsageAdvices from "./components/UsageAdvices";
import "./index.scss";

const Works: React.FC = () => (
  <section className="works">
    <div className="works__wrapper">
      <Differentiation />
      <Suitability />
    </div>
    <div className="works__wrapper">
      <Options />
      <Advantages />
    </div>
    <Comparison />
    <UsageAdvices />
  </section>
);

export default Works;
