import * as React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

import StepOneIcon from "assets/images/step1-icon.jpg";
import StepTwoIcon from "assets/images/step2-icon.jpg";
import StepThreeIcon from "assets/images/step3-icon.jpg";
import StepFourIcon from "assets/images/step4-icon.jpg";
import StepFiveIcon from "assets/images/step5-icon.jpg";

import StepOne from "assets/images/step1.jpg";
import StepTwo from "assets/images/step2.jpg";
import StepThree from "assets/images/step3.jpg";
import StepFour from "assets/images/step4.jpg";
import StepFive from "assets/images/step5.jpg";

import { ReactComponent as ListArrowIcon } from "assets/images/icon_list_arrow.svg";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { Routes } from "shared/constants/routes";
import { storePages } from "shared/constants/storePage";

import { blue600, mainStyles } from "../styles";

const onAppButtonClick = () => {
  analytics().sendEvent(AnalyticsEvents.iban_oplata_download_app);
};

const howItWorks = [
  {
    image: StepOne,
    icon: StepOneIcon,
    imageAlign: "left",
    title: "Крок перший",
    content: (
      <>
        Потрібно{" "}
        <Link
          href={`${Routes.app}?storePage=${storePages.iban.queryName}`}
          target="_blank"
          underline="none"
          color={blue600}
          onClick={onAppButtonClick}
        >
          завантажити застосунок ФОПкаса
        </Link>{" "}
        та ввести мобільний номер, щоб увійти в систему.
      </>
    ),
  },
  {
    image: StepTwo,
    icon: StepTwoIcon,
    imageAlign: "right",
    title: "Крок другий",

    content: (
      <>
        Обрати IBAN oplata та ввести свої реквізити рахунку:
        <Box display="flex" alignItems="center" mt={1.5}>
          <ListArrowIcon />
          <Typography sx={mainStyles.h5} ml={1.5}>
            Юридичну назву компанії або ФОП;
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1.5}>
          <ListArrowIcon />
          <Typography sx={mainStyles.h5} ml={1.5}>
            ЄДРПОУ або РНОКПП;
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1.5} mb={1.5}>
          <ListArrowIcon />
          <Typography sx={mainStyles.h5} ml={1.5}>
            IBAN.
          </Typography>
        </Box>
        <Typography sx={mainStyles.body1}>
          Цю інформацію буде збережено для повторних операцій.
        </Typography>
      </>
    ),
  },
  {
    image: StepThree,
    icon: StepThreeIcon,
    imageAlign: "left",
    title: "Крок третій",
    content: "Далі потрібно вказати необхідну суму та призначення платежу.",
  },
  {
    image: StepFour,
    icon: StepFourIcon,
    imageAlign: "right",
    title: "Крок четвертий",
    content:
      "На екрані з’явиться QR код для оплати та посилання на оплату, яке можна легко скопіювати і надати покупцю.",
  },
  {
    image: StepFive,
    icon: StepFiveIcon,
    imageAlign: "left",
    title: <>Крок п&apos;ятий</>,
    content: "Покупець переходить за лінком та здійснює оплату.",
  },
];

const HowItWorks: React.FC = () => {
  const isXs = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={styles.howItWorksBlock} id='how-it-works'>
      <Container>
        <Box>
          <Typography sx={mainStyles.h2} mb={2.5}>
            Як це працює?
          </Typography>
          <Typography sx={mainStyles.h5}>
            Для того, щоб користуватись IBAN oplata не потрібні електронні
            ключі, складна реєстрація чи підписка.
          </Typography>
        </Box>
        <Box mt={4}>
          {howItWorks.map((item, index) => (
            <Box key={index} sx={styles.stepWrapper}>
              {(item.imageAlign === "left" || isXs) && (
                <Box sx={styles.imageWrapper}>
                  <img
                    style={{ ...styles.image, ...(isXs && styles.imageSm) }}
                    src={item.image}
                  />
                </Box>
              )}
              <Box
                sx={styles.stepContent}
                pl={!isXs && item.imageAlign === "left" ? { xs: 3, md: 10 } : 0}
                pr={
                  !isXs && item.imageAlign === "right" ? { xs: 3, md: 10 } : 0
                }
              >
                <Box sx={styles.stepHeading}>
                  <img style={styles.icon} src={item.icon} />
                  <Typography sx={[mainStyles.h5, { color: "#1653F0" }]}>
                    {item.title}
                  </Typography>
                </Box>
                <Typography sx={mainStyles.h5}>{item.content}</Typography>
              </Box>
              {item.imageAlign === "right" && !isXs && (
                <Box sx={styles.imageWrapper}>
                  <img style={styles.image} src={item.image} />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

const styles = {
  howItWorksBlock: {
    backgroundColor: "#F5F8FF",
    paddingTop: { xs: "56px", sm: "120px" },
    paddingBottom: { xs: "56px", sm: "120px" },
    textAlign: "center",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    width: { xs: "100%", md: "50%" },
    marginBottom: { xs: "20px", md: "0" },
    borderRadius: "24px",
  },
  image: {
    borderRadius: "24px",
    width: "100%",
  },
  imageSm: {
    width: "120%",
  },
  icon: {
    width: "40px",
    borderRadius: "100px",
    marginRight: "12px",
  },
  stepWrapper: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: "40px",
  },
  stepContent: {
    width: { xs: "100%", md: "50%" },
  },
  stepHeading: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginBottom: "20px",
  },
};

export default HowItWorks;
