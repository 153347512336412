import * as React from "react";
import "./index.scss";

const advantagesList: Array<string> = [
  "Безкоштовний тестовий режим.",
  "Два перші місяці безкоштовного користування для всіх тарифів.",
  "Відсутні обмеження щодо кількості касирів у всіх тарифах.",
  "Необмежена кількість торгових точок, кас та товарів.",
  "Безкоштовна інтеграція з віртуальним терміналом Monobank.",
  "Налаштування автоматичного закриття зміни.",
  "Можливість роботи за відсутності зв'язку з серверами ДПС.",
  "Аналітичні звіти для бізнесу.",
  "Зручна база товарів за категоріями та фільтрами.",
  "Збереження коментарів, які залишає касир до чеку у базу.",
  "Автоматичне надсилання чеків (замовлень) іншим працівникам через Telegram.",
  "Служба підтримки користувачів у чаті та за телефоном.",
];

const Advantages: React.FC = () => (
  <div className="advantages">
    <div className="advantages__text-wrapper">
      <h2 className="header-normal advantages__header">
        Переваги ПРРО ФОПкаса
      </h2>
      <ul className="advantages__list">
        {advantagesList.map((item) => (
          <li key={item} className="text-normal advantages__item">
            {item}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Advantages;
