import * as React from "react";
import Logo from "assets/images/logo.png";
import "./index.scss";

const About: React.FC = () => (
  <section className="sip-about" id="sip-about">
    <div className="sip-about__wrapper">
      <h2 className="header-normal sip-about__header">Що таке фіскалізація?</h2>
      <div className="sip-about__list-wrapper">
        <span className="text-normal sip-about__item">
            Фіскалізація - це законодавча вимога до підприємців реєструвати кожен продаж товару або послуги.
        </span>
        <br />
        <span className="text-normal sip-about__item">
            Реєструвати - це фіксувати і надсилати відомості в ДПС про кожну грошову операцію за допомогою реєстратора 
            розрахункових операцій (РРО) або програмного реєстратора (ПРРО).{" "}
        </span>
      </div>
    </div>
    <div className="sip-about__logo-wrapper">
      <img className="sip-about__logo" alt="Logo" src={Logo} />
    </div>
  </section>
);

export default About;
