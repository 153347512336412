import React, { useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ReactComponent as IconPlus } from "assets/images/icon_plus.svg";

import Table from "./Table";
import CategoryProducts from "../CategoryProducts";
import CategoryDialog from "./CategoryModal/CategoryDialog";

import { CategoryResponse } from "api";
import { ApplicationState } from "store";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface ReduxProps {
  categories: CategoryResponse[];
}

const CategoryTab: React.FC<ReduxProps> = (props) => {
  const { categories } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [categoryDetails, setCategoryDetails] =
    useState<CategoryResponse | null>(null);

  const onCloseModal = () => setIsOpenModal(false);
  const onOpenModal = () => setIsOpenModal(true);

  const onCategoryClick = (category: CategoryResponse | null) => {
    setCategoryDetails(category);
  };

  const onAddCategory = () => {
    analytics().sendEvent(AnalyticsEvents.add_category_btn);
    onOpenModal();
  }

  return categoryDetails ? (
    <CategoryProducts
      category={categoryDetails}
      setCategoryDetails={setCategoryDetails}
    />
  ) : (
    <>
      <CategoryDialog {...{ isOpenModal, onCloseModal }} />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        mb={2}
      >
        <Typography variant="h4" component="h1">
          Категорії товарів
        </Typography>
        <Box display="flex">
          <Button
            sx={{ marginLeft: "16px" }}
            onClick={onAddCategory}
            size="large"
            startIcon={<IconPlus />}
          >
            Нова категорія
          </Button>
        </Box>
      </Box>
      <Table
        categories={categories}
        onOpenModal={onOpenModal}
        onCategoryClick={onCategoryClick}
      />
    </>
  );
};

const mapStateToProps = ({ category }: ApplicationState) => ({
  categories: category.categories,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTab);
