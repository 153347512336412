import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "containers/Cashiers/Table";
import CashierModal from "./Cashier";
import { actionCreators } from "store/Cashiers";

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onOpenModal: () => void;
}

const Cashiers: React.FC<Props> = (props) => {
  const { isOpenModal, onCloseModal, onOpenModal } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionCreators.getCashiers());
  }, []);

  return (
    <>
      <CashierModal {...{ onCloseModal, isOpenModal }} />
      <Table {...{ onOpenModal }} />
    </>
  );
};

export default Cashiers;
