import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Header from 'components/Header';
import SideMenu from "components/Header/SideMenu";
import Footer from 'components/Footer';
import 'assets/styles/index.scss';

import * as User from 'store/User';
import * as PointOfSale from 'store/PointOfSale';
import * as Company from 'store/Company';
import * as Subscription from 'store/Subscription';
import authService from 'services/auth';
import { UserRoleEnum } from 'api';

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const dispatch = useDispatch();
    const userRole = useSelector(({ user }) => user.data.userRole);
    
    useEffect(() => {
        if (authService().getAccessToken()) {
            dispatch(Company.actionCreators.getCompany());
            dispatch(PointOfSale.actionCreators.getPointsOfSale());
            dispatch(User.actionCreators.getUser());
        }
    }, []);

    useEffect(() => {
        if (authService().getAccessToken() && userRole === UserRoleEnum.Owner) {
            dispatch(Subscription.actionCreators.getSubscription());   
        }
    }, [userRole]);

    return (
        <>
          <Header />
          <Container sx={{ flex: 1, display: "flex" }}>
            <SideMenu />
            <Container sx={{ minWidth: "80%" }}>{children}</Container>
          </Container>

          <Footer />
        </>
    );
};

export default Layout;
