import React, { useState } from "react";
import { useSelector } from "react-redux";
import { History } from "history";

import { Box, Button } from "@mui/material";

import DeleteButton from "components/DeleteButton";
import DeleteModal from "./DeleteModal";

import { Routes } from "shared/constants/routes";

interface Props {
  history: History;
}

const DeleteAccount: React.FC<Props> = (props) => {
  const authorized = useSelector((state) => state.user.authorized);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onLoginClick = () => {
    props.history.push({
      pathname: Routes.login,
      state: { from: Routes.deleteAccount },
    });
  };

  return (
    <Box
      flexWrap="wrap"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight="100%"
    >
      <DeleteModal open={deleteModalOpen} onClose={onDeleteModalClose} />
      {authorized ? (
        <>
          <h3 className="text-normal about__list-header">
            Ми гарантуємо надійний захист вашої інформації і зобов&apos;язуємося
            не передавати її третім сторонам. Ваш обліковий запис може
            залишатися активним, що дозволить вам швидко відновити доступ у разі
            потреби. Ви маєте право запросити видалення ваших даних з нашої бази
            ФОПкаса. Однак, якщо ваші дії включали фіскальні операції,
            інформація про них вже була передана до Державної податкової служби
            (ДПС). Видалення вашого профілю на сайті ФОПкаса не впливатиме на
            зберігання ваших даних у ДПС.
          </h3>
          <DeleteButton
            style={{ marginRight: 0, marginTop: 16 }}
            onClick={() => setDeleteModalOpen(true)}
          >
            Видалити профіль
          </DeleteButton>
        </>
      ) : (
        <>
          <h3 className="text-large about__list-header">
            Для видалення профілю, будь ласка, залогуйтесь у Кабінет
          </h3>

          <Button onClick={onLoginClick} variant="contained" color="secondary">
            Увійти в кабінет
          </Button>
        </>
      )}
    </Box>
  );
};

export default DeleteAccount;
