import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import Loader from "components/Loader";
import { ReportType } from "containers/Sales/RegisterActionsModals/WorkShiftReportDialog";

import { Routes } from "shared/constants/routes";

import { WorkShiftReportResponse } from "api/models";
import { actionCreators } from "store/WorkShift";
import WorkShiftReport from "./WorkShiftReport";
import "./index.scss";

interface Params {
  uid: string;
}

const WorkSiftReportDoc: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { uid } = useParams<Params>();

  const [report, setReceipt] = useState<WorkShiftReportResponse | null>(null);
  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsReceiptLoading(true);
    dispatch(
      actionCreators.getZReport(
        uid,
        (data) => {
          data && setReceipt(data);
          setIsReceiptLoading(false);
        },
        () => {
          history.replace(Routes.login);
          setIsReceiptLoading(false);
        }
      )
    );
  }, [uid]);

  if (isReceiptLoading) {
    return <Loader isLoadingPage />;
  }

  return (
    <div>
      <section className="receipt">
        <WorkShiftReport report={report} type={ReportType.zReport} />
      </section>
    </div>
  );
};

export default WorkSiftReportDoc;
