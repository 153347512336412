import React, { FC } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import CashDialogContent from "./CashDialogContent";
import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

export enum CashDialogType {
  addCash,
  removeCash,
}

interface Props {
  type: CashDialogType;
  isOpenModal: boolean;
  onCloseModal: () => void;
}

const CashDialog: FC<Props> = (props) => {
  const { type, isOpenModal, onCloseModal } = props;

  const renderTitle = () => {
    switch (type) {
      case CashDialogType.addCash:
        return "Внести готівку";
      case CashDialogType.removeCash:
        return "Вилучити готівку";
    }
  };

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { minHeight: 420 } }}
    >
      <DialogTitle>
        {renderTitle()}
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <CashDialogContent onCloseModal={onCloseModal} type={type} />
    </Dialog>
  );
};

export default CashDialog;
