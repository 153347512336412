import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SubscriptionPaymentPlan } from 'containers/Subscription/Subscription';

import { Start, Successful, SuccessfulYear } from './Tariffs';

import { actionCreators } from "store/Subscription";
import CancelSubscription from "containers/Subscription/CancelSubscription";
import { SubscriptionContext } from 'contexts/SubscriptionContext';

const Prices: FC = () => {
  const dispatch = useDispatch();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const onCloseModal = () => setCancelModalOpen(false);
  const onOpenModal = () => setCancelModalOpen(true);

  const createPayment = useCallback((paymentPlanId: SubscriptionPaymentPlan) => () => {
    dispatch(actionCreators.createSubscriptionPayment({ paymentPlanId }, url => location.replace(url)));
  }, []);

  return (
    <Box>
      <Typography
        textAlign='center'
        variant='h2'
        fontWeight={700}
        maxWidth={1000}
        margin='80px auto 24px auto'
        fontSize={{ xs: '32px', md: '48px' }}
       >
        Оберіть тариф ПРРО, що найкраще підходить вашому бізнесу
      </Typography>
      <SubscriptionContext.Provider value={{ onOpenModal }}>
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
          <Start isLanding createPayment={createPayment(SubscriptionPaymentPlan.Start)} />
          <Successful isLanding createPayment={createPayment(SubscriptionPaymentPlan.Success)} />
          <SuccessfulYear isLanding createPayment={createPayment(SubscriptionPaymentPlan.SuccessfulYear) }/>
        </Box>
        <CancelSubscription
          isOpenModal={cancelModalOpen}
          onCloseModal={onCloseModal}
        />
      </SubscriptionContext.Provider>
    </Box>
  );
};

export default Prices;
