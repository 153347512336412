// colors
// GREY
export const grey000 = "#FFFFFF";
export const grey100 = "#FAFAFA";
export const grey200 = "#F5F5F5";
export const grey300 = "#EDEDED";
export const grey400 = "#E5E5E5";
export const grey500 = "#CDCDCD";
export const grey600 = "#A8A8A8";
export const grey700 = "#747474";
export const grey800 = "#393939";
export const grey900 = "#000000";

// BLUE
export const blue050 = "#F4FBFF";
export const blue100 = "#E5F1FF";
export const blue200 = "#CBDCFF";
export const blue300 = "#AFC1FF";
export const blue400 = "#798AFF";
export const blue500 = "#3C46FF";
export const blue600 = "#0000FF";
export const blue700 = "#0404AC";
export const blue800 = "#020267";
export const blue900 = "#00002E";

// GREEN
export const green050 = "#F4FFF4";
export const green100 = "#E9FFE8";
export const green200 = "#D8FFD8";
export const green300 = "#C9FFC7";
export const green400 = "#A6FFA3";
export const green500 = "#7DF178";
export const green600 = "#51DA4B";
export const green700 = "#3FA93A";
export const green800 = "#2D7129";
export const green900 = "#183718";

// RED
export const red050 = "#FFF7F6";
export const red100 = "#FFEDEC";
export const red200 = "#FFDEDB";
export const red300 = "#FFD3C9";
export const red400 = "#FFB7A4";
export const red500 = "#FF9574";
export const red600 = "#FF6E3D";
export const red700 = "#FF4500";
export const red800 = "#AA2E00";
export const red900 = "#541700";

export const mainStyles = {
  h1: {
    fontFamily: "Inter",
    color: grey900,
    fontSize: { xs: "48px", sm: "60px" },
    lineHeight: { xs: "56px", sm: "68px" },
    letterSpacing: "-0.8px",
    fontWeight: "600",
  },
  h2: {
    fontFamily: "Inter",
    color: grey900,
    fontSize: { xs: "36px", sm: "48px" },
    lineHeight: { xs: "42px", sm: "56px" },
    letterSpacing: { xs: "0", sm: "-0.8px" },
    fontWeight: "400",
  },
  h3: {
    fontFamily: "Inter",
    color: grey900,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0",
    fontWeight: "400",
  },
  h4: {
    fontFamily: "Inter",
    color: grey900,
    fontSize: "22px",
    lineHeight: "32px",
    letterSpacing: "0",
    fontWeight: "500",
  },
  h5: {
    fontFamily: "Inter",
    color: grey900,
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "28px",
    letterSpacing: "0",
  },
  body1: {
    fontFamily: "Inter",
    color: "#5B616E",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "28px",
    letterSpacing: "0",
  },
  body2: {
    fontFamily: "Inter",
    color: "#5B616E",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0",
  },
  ui1: {
    fontFamily: "Inter",
    textTransform: "uppercase",
    color: red800,
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "2px",
  },
  ui2: {
    fontFamily: "Inter",
    color: grey900,
    textDecorationColor: grey900,
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 400,
    letterSpacing: "0px",
  },
  primaryButton: {
    fontFamily: "Inter",
    color: grey000,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "2px",
    padding: "16px",
    borderRadius: "1000px",
    backgroundColor: blue600,
  },
};
