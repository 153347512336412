import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import MonobankToken from './MonobankToken';
import TerminalConfig from './TerminalConfig';

import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';

import { AcquiringContext, Steps } from 'contexts/AcquiringContext';
import { actionCreators } from 'store/Acquiring';
import { actionCreators as actionCreatorsCashRegister } from 'store/CashRegister';
import { actionCreators as actionCreatorsPointOfSale } from 'store/PointOfSale';

interface Props {
  onCloseModal: () => void
  isOpenModal: boolean
}

const QRTerminalDialog: FC<Props> = props => {
  const { isOpenModal, onCloseModal } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(Steps.monobankToken);
  const isEdit = !!useSelector(({ acquiring }) => acquiring.cashRegisterQrTerminal.uid);

  useEffect(() => {
    isEdit ? setStep(Steps.terminalConfig) : setStep(Steps.monobankToken);
    if (!isOpenModal) dispatch(actionCreators.removeAcquiringMonoQrTerminal());
  }, [isOpenModal]);

  useEffect(() => {
    dispatch(actionCreatorsCashRegister.getCashRegisters());
    dispatch(actionCreatorsPointOfSale.getPointsOfSale());
  }, []);
  const renderStep = () => {
    switch (step) {
      case Steps.monobankToken:
        return <MonobankToken/>
      case Steps.terminalConfig:
        return <TerminalConfig/>
    }
  };

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { minHeight: 420 } }}
    >
      <DialogTitle>
        {isEdit ? 'Редагувати QR термінал' : 'Додати новий QR термінал'}
        <IconButton
          aria-label='close'
          onClick={() => {
            onCloseModal();
            setStep(Steps.monobankToken);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <AcquiringContext.Provider value={{ setStep, onCloseModal }}>
        {renderStep()}
      </AcquiringContext.Provider>
    </Dialog>
  );
};

export default QRTerminalDialog;
