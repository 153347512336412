import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";

import Edit from "./Edit";
import Create from "./Create";

import { CategoryContext, Steps } from "contexts/CategoryContext";
import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { CategoryResponse } from "api";
import { ApplicationState } from "store";
import { actionCreators } from "store/Category";

interface ReduxProps {
  setSelectedCategory: (category: CategoryResponse | null) => void;
  selectedCategory: CategoryResponse | null;
}

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
}

type AllProps = ReduxProps & Props;

const CategoryDialog: FC<AllProps> = (props) => {
  const { selectedCategory, isOpenModal, onCloseModal } = props;
  const [step, setStep] = useState(Steps.create);
  const isEdit = !!selectedCategory;

  useEffect(() => {
    isEdit ? setStep(Steps.edit) : setStep(Steps.create);
  }, [isOpenModal]);

  const renderStep = () => {
    switch (step) {
      case Steps.create:
        return <Create />;
      case Steps.edit:
        return <Edit />;
    }
  };

  return (
    <Dialog open={isOpenModal} fullWidth PaperProps={{ sx: styles.dialog }}>
      <DialogTitle>
        Додати нову категорію
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={styles.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <CategoryContext.Provider value={{ setStep, onCloseModal }}>
        {renderStep()}
      </CategoryContext.Provider>
    </Dialog>
  );
};

const styles = {
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 12,
  },
  dialog: { minHeight: 420 },
};

const mapStateToProps = ({ category }: ApplicationState) => ({
  selectedCategory: category.selectedCategory,
});

const mapDispatchToProps = {
  setSelectedCategory: actionCreators.setSelectedCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDialog);
