/* tslint:disable */
/* eslint-disable */
/**
 * FOPkasa.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * ReportExportApi - axios parameter creator
 * @export
 */
export const ReportExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Date} startDateUtc 
         * @param {Date} endDateUtc 
         * @param {string} reportType 
         * @param {number} [pointOfSaleId] 
         * @param {number} [cashRegisterId] 
         * @param {string} [partnerUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportExportGet: async (startDateUtc: Date, endDateUtc: Date, reportType: string, pointOfSaleId?: number, cashRegisterId?: number, partnerUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDateUtc' is not null or undefined
            if (startDateUtc === null || startDateUtc === undefined) {
                throw new RequiredError('startDateUtc','Required parameter startDateUtc was null or undefined when calling apiReportExportGet.');
            }
            // verify required parameter 'endDateUtc' is not null or undefined
            if (endDateUtc === null || endDateUtc === undefined) {
                throw new RequiredError('endDateUtc','Required parameter endDateUtc was null or undefined when calling apiReportExportGet.');
            }
            // verify required parameter 'reportType' is not null or undefined
            if (reportType === null || reportType === undefined) {
                throw new RequiredError('reportType','Required parameter reportType was null or undefined when calling apiReportExportGet.');
            }
            const localVarPath = `/api/report-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDateUtc !== undefined) {
                localVarQueryParameter['startDateUtc'] = (startDateUtc as any instanceof Date) ?
                    (startDateUtc as any).toISOString() :
                    startDateUtc;
            }

            if (endDateUtc !== undefined) {
                localVarQueryParameter['endDateUtc'] = (endDateUtc as any instanceof Date) ?
                    (endDateUtc as any).toISOString() :
                    endDateUtc;
            }

            if (pointOfSaleId !== undefined) {
                localVarQueryParameter['pointOfSaleId'] = pointOfSaleId;
            }

            if (cashRegisterId !== undefined) {
                localVarQueryParameter['cashRegisterId'] = cashRegisterId;
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }

            if (partnerUid !== undefined) {
                localVarQueryParameter['partnerUid'] = partnerUid;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportExportApi - functional programming interface
 * @export
 */
export const ReportExportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Date} startDateUtc 
         * @param {Date} endDateUtc 
         * @param {string} reportType 
         * @param {number} [pointOfSaleId] 
         * @param {number} [cashRegisterId] 
         * @param {string} [partnerUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportExportGet(startDateUtc: Date, endDateUtc: Date, reportType: string, pointOfSaleId?: number, cashRegisterId?: number, partnerUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Blob>>> {
            const localVarAxiosArgs = await ReportExportApiAxiosParamCreator(configuration).apiReportExportGet(startDateUtc, endDateUtc, reportType, pointOfSaleId, cashRegisterId, partnerUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReportExportApi - factory interface
 * @export
 */
export const ReportExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Date} startDateUtc 
         * @param {Date} endDateUtc 
         * @param {string} reportType 
         * @param {number} [pointOfSaleId] 
         * @param {number} [cashRegisterId] 
         * @param {string} [partnerUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportExportGet(startDateUtc: Date, endDateUtc: Date, reportType: string, pointOfSaleId?: number, cashRegisterId?: number, partnerUid?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
            return ReportExportApiFp(configuration).apiReportExportGet(startDateUtc, endDateUtc, reportType, pointOfSaleId, cashRegisterId, partnerUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportExportApi - object-oriented interface
 * @export
 * @class ReportExportApi
 * @extends {BaseAPI}
 */
export class ReportExportApi extends BaseAPI {
    /**
     * 
     * @param {Date} startDateUtc 
     * @param {Date} endDateUtc 
     * @param {string} reportType 
     * @param {number} [pointOfSaleId] 
     * @param {number} [cashRegisterId] 
     * @param {string} [partnerUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApi
     */
    public async apiReportExportGet(startDateUtc: Date, endDateUtc: Date, reportType: string, pointOfSaleId?: number, cashRegisterId?: number, partnerUid?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Blob>> {
        return ReportExportApiFp(this.configuration).apiReportExportGet(startDateUtc, endDateUtc, reportType, pointOfSaleId, cashRegisterId, partnerUid, options).then((request) => request(this.axios, this.basePath));
    }
}
