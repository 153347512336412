import React from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import theme from "theme";

interface Props {
  selectedTab: string;
  handleTabChange: (e: React.SyntheticEvent, newValue: string) => void;
}

const PageTabs: React.FC<Props> = (props) => {
  const { selectedTab, handleTabChange } = props;

  return (
    <Tabs
      value={selectedTab}
      sx={styles.tabContainer}
      onChange={handleTabChange}
    >
      <Tab
        key="all_products"
        value="all_products"
        label="Всі товари"
        sx={styles.tab}
        disableRipple
      />
      <Tab
        key="all_categories"
        value="all_categories"
        label="Категорії"
        sx={styles.tab}
        disableRipple
      />
    </Tabs>
  );
};

const styles = {
  tab: {
    textTransform: "uppercase",
    "&.Mui-selected": {
      bgcolor: "inherit",
      border: "none",
      borderRadius: 0,
      borderBottom: `2px ${theme.colors.secondaryBlue} solid`,
    },
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `solid 1px ${theme.colors.primaryGrey}`,
    margin: "24px 0",
  },
};

export default PageTabs;
