import MuiListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import MuiListItemIcon, { ListItemIconProps } from "@mui/material/ListItemIcon";
import MuiListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
import MuiListItem, { ListItemProps } from "@mui/material/ListItem";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";

const drawerWidth = 180;

type CustomProps = {
  open: boolean;
};

type CustomListItemProps = ListItemProps & {
  selected: boolean;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

export const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps & CustomProps>(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "space-between" : "center",
  ...theme.mixins.toolbar,
})) as React.ComponentType<DrawerProps & CustomProps>;

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 0,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ".MuiDrawer-paper": {
    backgroundColor: "inherit",
    position: "relative",
    borderRight: "none",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "selected",
})<CustomListItemProps>(({ theme, selected }) => ({
  display: "block",
  cursor: "pointer",
  borderRadius: "4px",
  marginBottom: "12px",
  ...(selected && {
    textTransform: "none",
    border: `1px solid ${theme.colors.primaryBlue}`,
    backgroundColor: "white",
    cursor: "default",
  }),
})) as React.ComponentType<CustomListItemProps>;

export const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== "open",
})<ListItemButtonProps & CustomProps>(({ open }) => ({
  minHeight: "48px",
  justifyContent: open ? "initial" : "center",
  padding: "10px 10px 10px 14px",
})) as React.ComponentType<ListItemButtonProps & CustomProps>;

export const ListItemIcon = styled(MuiListItemIcon, {
  shouldForwardProp: (prop) => prop !== "open",
})<ListItemIconProps & CustomProps>(({ open }) => ({
  minWidth: 0,
  marginRight: open ? "8px" : "auto",
  justifyContent: "center",
})) as React.ComponentType<ListItemIconProps & CustomProps>;

export const ListItemText = styled(MuiListItemText, {
  shouldForwardProp: (prop) => prop !== "open",
})<ListItemTextProps & CustomProps>(({ theme, open }) => ({
  opacity: open ? 1 : 0,
  fontWeight: "500",
  letterSpacing: "0.2px",
  fontSize: "14px",
  lineHeight: "18px",
  color: theme.colors.body,
})) as React.ComponentType<ListItemTextProps & CustomProps>;
