import React, { FC, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as MenuIcon } from 'assets/images/navigation-menu.svg'

import { actionCreators } from 'store/User';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';
import { UserRoleEnum } from 'api';
import { headerLinks, HeaderLink } from 'shared/constants/header-links';
import { Routes } from 'shared/constants/routes';

interface ResponsiveMenuProps {
  website?: boolean,
}

type AllProps = ResponsiveMenuProps & RouteComponentProps;

const ResponsiveMenu: FC<AllProps> = props => {
  const dispatch = useDispatch();
  const { website } = props;

  const [isOpenedMenu, setIsOpenedMenu] = useState(false);
  const anchorEl = useRef(null);

  const authorized = useSelector(state => state.user.authorized);
  const userRole = useSelector(state => state.user.data.userRole);
  const route = UserRoleEnum.Accountant === userRole ? '/reports' : '/business-structure';

  const websitePages = props.location.pathname == '/' || website;

  const renderAuthorizedMenuItems = (link: HeaderLink) => {
    if (userRole && link.allowedRoles.includes(userRole)) {
      return (
        <MenuItem key={link.route} onClick={() => props.history.push(link.route)}>
          {link.name}
        </MenuItem>
      );
    }

    return;
  }

  const renderMenuItems = () => {
    if (!websitePages && authorized) {
      return (
        <Box>
          {headerLinks.map(link => renderAuthorizedMenuItems(link))}
          <MenuItem
            sx={{ color: (theme) => theme.colors.secondaryRed }}
            onClick={() => dispatch(actionCreators.logout())}>
            Вийти
          </MenuItem>
        </Box>
      )
    }

    return (
      <Box>
        <MenuItem onClick={() => location.href = '#about'}>
          Що таке ФОПкаса
        </MenuItem>
        <MenuItem onClick={() => location.href = '#organization'}>
          Переваги
        </MenuItem>
        <MenuItem onClick={() => props.history.push(Routes.blog)}>
          Блог
        </MenuItem>
        <MenuItem onClick={() => props.history.push(Routes.ibanOplata)}>
          Iban оплата
        </MenuItem>
        { authorized ?
          <MenuItem
            onClick={() => {props.history.push(route);                    
                     analytics().sendEvent(AnalyticsEvents.menu_login_to_myaccount);}}
          >
            Увійти в кабінет
          </MenuItem> :
          <>
            <MenuItem onClick={() => {props.history.push(Routes.login);                    
                                      analytics().sendEvent(AnalyticsEvents.menu_login);}}>
              Увійти в кабінет
            </MenuItem>
            <MenuItem onClick={() => {props.history.push(Routes.registrationPhoneNumber);                    
                     analytics().sendEvent(AnalyticsEvents.menu_registration);}}>
              Зареєструватися
            </MenuItem>
          </>
        }
      </Box>
    );
  };

  return (
    <>
      <MenuIcon
        ref={anchorEl}
        onClick={() => setIsOpenedMenu(true)}
      >
        Відкрити меню
      </MenuIcon>
      <Menu
        anchorEl={anchorEl.current}
        open={isOpenedMenu}
        onClick={() => setIsOpenedMenu(false)}
        onClose={() => setIsOpenedMenu(false)}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};

export default withRouter(ResponsiveMenu);
