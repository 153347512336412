import React from "react";

import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";

import theme from "theme";

interface RadioButton {
  value: string;
  text: string;
}

interface RadioButtonsProps {
  activeValue: string;
  radioButtons: Array<RadioButton>;
  onChange: (value: string) => void;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  radioButtons,
  activeValue,
  onChange,
}) => {
  const onButton = (value: string) => {
    onChange(value);
  };

  return (
    <>
      <Box sx={styles.radioButtons}>
        {radioButtons.map((item, index) => (
          <ButtonBase
            key={index}
            sx={{
              ...styles.button,
              ...(activeValue === item.value && styles.activeButton),
            }}
            onClick={() => onButton(item.value)}
          >
            {item.text}
          </ButtonBase>
        ))}
      </Box>
    </>
  );
};

const styles = {
  radioButtons: {
    display: "flex",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "32px",
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    color: theme.colors.body,
    padding: "8px",
  },
  activeButton: {
    backgroundColor: theme.colors.primaryBlue,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.16)",
    borderRadius: "4px",
    color: "white",
    padding: "8px",
  },
};

export default RadioButtons;
