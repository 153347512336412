import React, { FC } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { CustomNumericFormat } from "components/FormInput";

import { PaymentTypeEnum } from "api";
import { ApplicationState } from "store";
import { DiscountTypeEnum, actionCreators } from "store/Order";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { getPriceFormat } from "shared/functions";

import theme from "theme";

interface ReduxProps {
  setNotes: (notes: string) => void;
  setIsPublicNotes: (displayNote: boolean) => void;
  setPaymentType: (paymentType: PaymentTypeEnum) => void;
  setProvidedCashAmount: (providedAmount: number | null) => void;
  notes: string;
  isPublicNotes: boolean;
  totalAmount: number;
  providedCashAmount: number | null;
  paymentType: PaymentTypeEnum;
  discountValue: number;
  discountType: DiscountTypeEnum;
}

const PaymentDialogContent: FC<ReduxProps> = (props) => {
  const {
    totalAmount,
    providedCashAmount,
    notes,
    isPublicNotes,
    paymentType,
    discountType,
    discountValue,
  } = props;
  let rest = 0;

  const discountAmount =
    discountType === DiscountTypeEnum.Amount
      ? discountValue
      : Number(((totalAmount / 100) * discountValue).toFixed(2));

  const amountToPay = totalAmount - discountAmount;

  const fieldsDisabled = paymentType !== PaymentTypeEnum.Cash;

  if (providedCashAmount && providedCashAmount > amountToPay) {
    rest = providedCashAmount - amountToPay;
  }

  const onChangeProvidedAmount = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { setProvidedCashAmount } = props;
    const providedAmount = parseFloat(event.target.value);
    isNaN(providedAmount)
      ? setProvidedCashAmount(null)
      : setProvidedCashAmount(providedAmount);
  };

  const onBlurProvidedAmount = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const { setProvidedCashAmount } = props;
    const providedAmount = parseFloat(event.target.value);
    isNaN(providedAmount) && setProvidedCashAmount(0);
  };

  const onChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { setNotes } = props;
    setNotes(event.target.value);
  };

  const onChangeDisplayNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { setIsPublicNotes } = props;
    setIsPublicNotes(e.target.checked);
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    const { setPaymentType, setProvidedCashAmount } = props;
    setPaymentType(newValue as PaymentTypeEnum);

    switch (newValue) {
      case PaymentTypeEnum.Cash:
        analytics().sendEvent(AnalyticsEvents.sales_payment_type_cash);
        return;
      case PaymentTypeEnum.CreditCard:
        setProvidedCashAmount(0);
        analytics().sendEvent(AnalyticsEvents.sales_payment_type_card);
        return;
      case PaymentTypeEnum.MonoQr:
        setProvidedCashAmount(0);
        analytics().sendEvent(AnalyticsEvents.sales_payment_type_mono_qr);
        return;
    }
  };

  return (
    <>
      <Tabs
        value={paymentType}
        onChange={handleTabChange}
        sx={styles.tabContainer}
        variant="fullWidth"
      >
        <Tab
          key={PaymentTypeEnum.Cash}
          value={PaymentTypeEnum.Cash}
          label="ГОТІВКА"
          disableRipple
          sx={styles.tab}
        />
        <Tab
          key={PaymentTypeEnum.CreditCard}
          value={PaymentTypeEnum.CreditCard}
          label="КАРТКА"
          disableRipple
          sx={styles.tab}
        />
        <Tab
          key={PaymentTypeEnum.MonoQr}
          value={PaymentTypeEnum.MonoQr}
          label="QR КАСА"
          disableRipple
          sx={styles.tab}
        />
      </Tabs>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={2}
        >
          <Typography fontWeight={100} pr={6}>
            Отримано:
          </Typography>
          <TextField
            fullWidth
            disabled={fieldsDisabled}
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: CustomNumericFormat as any,
              inputProps: {
                fixedDecimalScale: true,
                suffix: " ₴",
                style: { textAlign: "right" },
              },
            }}
            onChange={onChangeProvidedAmount}
            onBlur={onBlurProvidedAmount}
            value={providedCashAmount}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={2}
        >
          <Typography fontWeight={100}>Решта:</Typography>
          <Typography
            fontWeight={100}
            sx={{ opacity: fieldsDisabled ? 0.5 : 1 }}
          >
            {getPriceFormat(rest)} ₴
          </Typography>
        </Box>
        <Divider />
        <Box pt={2}>
          <TextField
            fullWidth
            multiline
            placeholder="Коментар"
            onChange={onChangeNote}
            value={notes}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
          pl={1}
          borderRadius={"6px"}
          bgcolor={theme.colors.blueWhite}
        >
          <Typography fontWeight={100}>Показувати коментар в чеку</Typography>
          <Switch checked={isPublicNotes} onChange={onChangeDisplayNote} />
        </Box>
      </Box>
    </>
  );
};

const styles = {
  tab: {
    color: theme.colors.secondaryBlue,
    "&.Mui-selected": {
      bgcolor: theme.colors.secondaryBlue,
      color: "white",
    },
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
    border: `solid 1px ${theme.colors.primaryGrey}`,
    borderRadius: "8px",
    marginBottom: "16px",
    height: "32px",
  },
};

const mapStateToProps = ({ order }: ApplicationState) => ({
  notes: order.notes,
  isPublicNotes: order.isPublicNotes,
  totalAmount: order.totalAmount,
  providedCashAmount: order.providedCashAmount,
  paymentType: order.paymentType,
  discountValue: order.discount.value || 0,
  discountType: order.discount.type,
});

const mapDispatchToProps = {
  setNotes: actionCreators.setNotes,
  setIsPublicNotes: actionCreators.setIsPublicNotes,
  setPaymentType: actionCreators.setPaymentType,
  setProvidedCashAmount: actionCreators.setProvidedCashAmount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDialogContent);
