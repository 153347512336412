import { Action, Reducer } from 'redux';
import { ApiError } from 'types';
import { ProductApi, ProductUnitApi } from '../api'
import { CreateProductRequest, ProductResponse, ProductUnitResponse, UpdateProductRequest } from '../api/models'
import { AppThunkAction } from './';
import { actionCreators as notificationActions, SetNotificationAction } from './Notification';

const productApi = new ProductApi();
const productUnitApi = new ProductUnitApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProductsByCategories {
  [categoryName: string]: ProductResponse[];
}

export interface ProductState {
  selectedProduct: ProductResponse | null;
  products: ProductResponse[];
  productUnits: ProductUnitResponse[];
  productsByCategories: ProductsByCategories | null;
}

enum ProductActionTypes {
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCT_UNITS = 'SET_PRODUCT_UNITS',
  SET_PRODUCTS_BY_CATEGORIES = 'SET_PRODUCTS_BY_CATEGORIES'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface SetProductAction { type: ProductActionTypes.SET_PRODUCTS, payload: ProductResponse[] }

export interface SetSelectedProductAction { type: ProductActionTypes.SET_SELECTED_PRODUCT, payload: ProductResponse | null }

export interface SetProductUnitsAction { type: ProductActionTypes.SET_PRODUCT_UNITS, payload: ProductUnitResponse[] }

export interface SetProductByCategoriesAction { type: ProductActionTypes.SET_PRODUCTS_BY_CATEGORIES, payload: ProductsByCategories }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetProductAction | SetSelectedProductAction | SetProductUnitsAction | SetProductByCategoriesAction | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setProducts: (payload: ProductResponse[]): SetProductAction => ({ type: ProductActionTypes.SET_PRODUCTS, payload }),
    setSelectedProduct: (payload: ProductResponse | null): SetSelectedProductAction => ({ type: ProductActionTypes.SET_SELECTED_PRODUCT, payload }),
    setProductUnits: (payload: ProductUnitResponse[]): SetProductUnitsAction => ({ type: ProductActionTypes.SET_PRODUCT_UNITS, payload }),
    setProductsByCategories: (payload: ProductsByCategories): SetProductByCategoriesAction => ({ type: ProductActionTypes.SET_PRODUCTS_BY_CATEGORIES, payload }),
    getProducts: (): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        const { data } = await productApi.productGet();

        const productsByCategories = data.reduce(
          (result: ProductsByCategories, currentValue: ProductResponse) => {
            currentValue?.category?.name &&
              (result[currentValue.category.name] =
                result[currentValue.category.name] || []).push(currentValue);
            return result;
          },
          {}
        );
        productsByCategories["all"] = data;

        dispatch(actionCreators.setProducts(data));
        dispatch(actionCreators.setProductsByCategories(productsByCategories));
      } catch ({ response }) {
        const { data } = response as ApiError

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
    getProductUnit: (): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        const { data } = await productUnitApi.productUnitGet();

        dispatch(actionCreators.setProductUnits(data));
      } catch ({ response }) {
        const { data } = response as ApiError

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
    createProduct: (body: CreateProductRequest, onSuccess?: () => void, onError?: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        await productApi.productPost(body);

        if(onSuccess){
          onSuccess();
        }

      } catch ({ response }) {
        const { data } = response as ApiError;

        if(onError){
          onError();
        }

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
    updateProduct: (body: UpdateProductRequest, onSuccess?: () => void, onError?: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        await productApi.productPut(body);

        if(onSuccess){
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if(onError){
          onError();
        }

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
    deleteProducts: (productIds: number[], onSuccess?: () => void, onError?: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        await productApi.productDelete({productIds});

        if(onSuccess){
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if(onError){
          onError();
        }

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: ProductState = { products: [], productUnits: [], productsByCategories: null, selectedProduct: null };

export const reducer: Reducer<ProductState> = (state: ProductState = defaultState, incomingAction: Action): ProductState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ProductActionTypes.SET_PRODUCTS:
            return { ...state, products: action.payload };
        case ProductActionTypes.SET_SELECTED_PRODUCT:
            return { ...state, selectedProduct: action.payload };
        case ProductActionTypes.SET_PRODUCT_UNITS:
            return { ...state, productUnits: action.payload };
          case ProductActionTypes.SET_PRODUCTS_BY_CATEGORIES:
            return { ...state, productsByCategories: action.payload };
        default:
            return state;
    }
};
