/* tslint:disable */
/* eslint-disable */
/**
 * FOPkasa.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CloseWorkShiftRequest } from '../models';
import { OpenWorkShiftRequest } from '../models';
import { WorkShiftCashRequest } from '../models';
import { WorkShiftIdResponse } from '../models';
import { WorkShiftReportResponse } from '../models';
import { WorkShiftResponse } from '../models';
/**
 * WorkShiftApi - axios parameter creator
 * @export
 */
export const WorkShiftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkShiftCashRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftAddCashPost: async (body?: WorkShiftCashRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift/add-cash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CloseWorkShiftRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftClosePost: async (body?: CloseWorkShiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftGet: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkShiftCashRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftGetCashPost: async (body?: WorkShiftCashRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift/get-cash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OpenWorkShiftRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftOpenPost: async (body?: OpenWorkShiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftReportGet: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshiftXReportGet: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshift/x-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkShiftApi - functional programming interface
 * @export
 */
export const WorkShiftApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkShiftCashRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftAddCashPost(body?: WorkShiftCashRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await WorkShiftApiAxiosParamCreator(configuration).apiWorkshiftAddCashPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CloseWorkShiftRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftClosePost(body?: CloseWorkShiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await WorkShiftApiAxiosParamCreator(configuration).apiWorkshiftClosePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftGet(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkShiftResponse>>> {
            const localVarAxiosArgs = await WorkShiftApiAxiosParamCreator(configuration).apiWorkshiftGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {WorkShiftCashRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftGetCashPost(body?: WorkShiftCashRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await WorkShiftApiAxiosParamCreator(configuration).apiWorkshiftGetCashPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OpenWorkShiftRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftOpenPost(body?: OpenWorkShiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkShiftIdResponse>>> {
            const localVarAxiosArgs = await WorkShiftApiAxiosParamCreator(configuration).apiWorkshiftOpenPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftReportGet(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkShiftReportResponse>>> {
            const localVarAxiosArgs = await WorkShiftApiAxiosParamCreator(configuration).apiWorkshiftReportGet(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftXReportGet(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkShiftReportResponse>>> {
            const localVarAxiosArgs = await WorkShiftApiAxiosParamCreator(configuration).apiWorkshiftXReportGet(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkShiftApi - factory interface
 * @export
 */
export const WorkShiftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {WorkShiftCashRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftAddCashPost(body?: WorkShiftCashRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return WorkShiftApiFp(configuration).apiWorkshiftAddCashPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CloseWorkShiftRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftClosePost(body?: CloseWorkShiftRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return WorkShiftApiFp(configuration).apiWorkshiftClosePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftGet(id?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkShiftResponse>> {
            return WorkShiftApiFp(configuration).apiWorkshiftGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkShiftCashRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftGetCashPost(body?: WorkShiftCashRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return WorkShiftApiFp(configuration).apiWorkshiftGetCashPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpenWorkShiftRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftOpenPost(body?: OpenWorkShiftRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkShiftIdResponse>> {
            return WorkShiftApiFp(configuration).apiWorkshiftOpenPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftReportGet(uid?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkShiftReportResponse>> {
            return WorkShiftApiFp(configuration).apiWorkshiftReportGet(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshiftXReportGet(uid?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkShiftReportResponse>> {
            return WorkShiftApiFp(configuration).apiWorkshiftXReportGet(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkShiftApi - object-oriented interface
 * @export
 * @class WorkShiftApi
 * @extends {BaseAPI}
 */
export class WorkShiftApi extends BaseAPI {
    /**
     * 
     * @param {WorkShiftCashRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftApi
     */
    public async apiWorkshiftAddCashPost(body?: WorkShiftCashRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return WorkShiftApiFp(this.configuration).apiWorkshiftAddCashPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CloseWorkShiftRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftApi
     */
    public async apiWorkshiftClosePost(body?: CloseWorkShiftRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return WorkShiftApiFp(this.configuration).apiWorkshiftClosePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftApi
     */
    public async apiWorkshiftGet(id?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkShiftResponse>> {
        return WorkShiftApiFp(this.configuration).apiWorkshiftGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {WorkShiftCashRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftApi
     */
    public async apiWorkshiftGetCashPost(body?: WorkShiftCashRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return WorkShiftApiFp(this.configuration).apiWorkshiftGetCashPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {OpenWorkShiftRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftApi
     */
    public async apiWorkshiftOpenPost(body?: OpenWorkShiftRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkShiftIdResponse>> {
        return WorkShiftApiFp(this.configuration).apiWorkshiftOpenPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [uid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftApi
     */
    public async apiWorkshiftReportGet(uid?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkShiftReportResponse>> {
        return WorkShiftApiFp(this.configuration).apiWorkshiftReportGet(uid, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [uid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkShiftApi
     */
    public async apiWorkshiftXReportGet(uid?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkShiftReportResponse>> {
        return WorkShiftApiFp(this.configuration).apiWorkshiftXReportGet(uid, options).then((request) => request(this.axios, this.basePath));
    }
}
