import React, { FC, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { CategoryContext } from "contexts/CategoryContext";
import { FormInput } from "components/FormInput";

import { CreateCategoryRequest } from "api";
import { actionCreators } from "store/Category";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

type FormValues = {
  name: string;
};

interface ReduxProps {
  createCategory: (
    data: CreateCategoryRequest,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  getCategories: () => void;
}

const Create: FC<ReduxProps> = (props) => {
  const { onCloseModal } = useContext(CategoryContext);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<FormValues>({
    mode: "onChange",
  });

  const name = methods.watch("name") || "";

  const onSubmit = (data: FormValues) => {
    setIsLoading(true);

    const { createCategory, getCategories } = props;
    createCategory(
      data,
      () => {
        analytics().sendEvent(AnalyticsEvents.add_category_btn_modal);
        getCategories();
        setIsLoading(false);
        onCloseModal();
        methods.reset({});
      },
      () => {
        setIsLoading(false);
        onCloseModal();
      }
    );
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={0.5} variant="body2">
              НАЗВА КАТЕГОРІЇ
              <Typography sx={{ display: "inline", color: "red" }}>
                *
              </Typography>
              :
            </Typography>
            <FormInput fullWidth name="name" autoFocus={true} />
          </Box>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Box marginLeft="auto">
          <Button
            sx={{ marginRight: "8px" }}
            onClick={onCloseModal}
            size="large"
            variant="contained"
            color="inherit"
          >
            Скасувати
          </Button>
          <LoadingButton
            size="large"
            disabled={!name.trim() || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            loading={isLoading}
          >
            Додати категорію
          </LoadingButton>
        </Box>
      </DialogActions>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createCategory: actionCreators.createCategory,
  getCategories: actionCreators.getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
