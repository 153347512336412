import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Table from "containers/Accountants/Table";
import AccountantModal from "./Accountant";
import { actionCreators } from "store/Accountants";

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onOpenModal: () => void;
}

const Accountants: React.FC<Props> = (props) => {
  const { isOpenModal, onCloseModal, onOpenModal } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionCreators.getAccountants());
  }, []);

  return (
    <>
      <AccountantModal {...{ onCloseModal, isOpenModal }} />
      <Table {...{ onOpenModal }} />
    </>
  );
};

export default Accountants;
