import React, {FC, useContext, useEffect} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { FormInput } from 'components/FormInput';
import { AcquiringContext, Steps } from 'contexts/AcquiringContext';
import { actionCreators } from 'store/Acquiring';

type FormValues = {
  monoAccessToken: string
};

const MonobankToken: FC = () => {
  const dispatch = useDispatch();
  const monoAccessTokenError = useSelector(state => state.acquiring.errors.monoAccessToken);
  const { setStep, onCloseModal } = useContext(AcquiringContext);
  const methods = useForm<FormValues>({
    mode: 'onChange'
  });
  const monoAccessToken = methods.watch('monoAccessToken') || '';

  const onSubmit = (data: FormValues) => {
    dispatch(actionCreators.getAcquiringMonoQrTerminals(data, () => setStep(Steps.terminalConfig)));
  };

  useEffect(() => {
    if (monoAccessTokenError) {
      methods.setError('monoAccessToken', { message: monoAccessTokenError })
    }
    dispatch(actionCreators.removeAcquiringErrors());
  }, [monoAccessTokenError]);

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Typography
            ml={2}
            mb={.5}
            color={({ colors }) => colors.secondaryGrey}
            fontSize='0.8571428571428571rem'>
            MONOBANK ТОКЕН:
          </Typography>
          <FormInput
            defaultValue=''
            fullWidth
            name='monoAccessToken'
            placeholder='Введіть згенерований токен'
            rules={{ required: true }}
          />
        </FormProvider>
        <Box display='flex' flexDirection='row' alignItems='center' mt={1}>
          <Typography fontWeight={300}>
            Монобанк токен можна отримати на порталі
          </Typography>
          <Link
            target='_blank'
            href='https://web.monobank.ua/'
            sx={{ cursor: 'pointer' }}
            fontWeight='300'
            ml={1}
            underline='hover'>web.monobank.ua</Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          onCloseModal();
          //analytics().sendEvent(AnalyticsEvents.cashiers_cashier_edit_phone_verification_send_phone_cancel);
        }} size='large' variant='contained' color='inherit'>Відмінити</Button>
        <Button
          disabled={!monoAccessToken.trim()}
          size='large'
          onClick={methods.handleSubmit(onSubmit)}
        >
          Продовжити
        </Button>
      </DialogActions>
    </>
  );
};

export default MonobankToken;
