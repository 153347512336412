import * as React from "react";
import "./index.scss";

const Differentiation: React.FC = () => (
  <div className="differentiation">
    <div className="differentiation__text-wrapper">
      <h2 className="header-normal differentiation__header">
        Чим різняться РРО та ПРРО?
      </h2>
      <span className="text-normal differentiation__item">
        РРО або ж касовий апарат - передбачає спеціальне устаткування, що має касовий або POS-термінал, 
        який містить фіскальний реєстратор або чековий принтер, що записує дані про грошову операцію і друкує паперові чеки. 
      </span>
      <br />
      <br />
      <span className="text-normal differentiation__item">
        Програмний РРО &#40;скорочено – ПРРО&#41; виконує функції апаратного,
        але створений у вигляді спеціального програмного забезпечення. ПРРО, як
        і інші програмні застосунки, встановлюється на будь-який гаджет
        &#40;телефон, комп&apos;ютер, планшет і т.д.&#41;. Принтер для друку
        чеків застосовувати не обов&apos;язково, бо реєстрація чеків
        відбувається на сервері ДПС автоматично, а покупцеві чек можна надати в
        електронному вигляді &#40;як QR код чи надіслати у месенджер, чи на
        e-mail&#41;.
      </span>
    </div>
  </div>
);

export default Differentiation;
