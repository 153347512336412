import { Action, Reducer } from "redux";
import { AccountantApi } from "api";
import {
  AccountantResponse,
  CreateAccountantRequest,
  DeleteAccountantRequest,
} from "api/models";
import { AppThunkAction } from "./";
import { ApiError } from "types";
import {
  SetNotificationAction,
  actionCreators as notificationActions,
} from "./Notification";

const accountantApi = new AccountantApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

enum ErrorFieldName {
  phoneNumber = "phoneNumber",
  fullName = "fullName",
}

interface ErrorPayload {
  field: ErrorFieldName;
  message: string;
}

export interface AccountantsState {
  accountant: AccountantResponse;
  accountants: AccountantResponse[];
  errors: {
    phoneNumber: string;
    fullName: string;
  };
}

enum AccountantsActionTypes {
  ACCOUNTANTS_SET = "ACCOUNTANTS_SET",
  ACCOUNTANT_SET = "ACCOUNTANT_SET",
  ACCOUNTANT_REMOVE = "ACCOUNTANT_REMOVE",
  ACCOUNTANT_SET_PHONE_NUMBER_ERROR = "ACCOUNTANT_SET_PHONE_NUMBER_ERROR",
  ACCOUNTANT_SET_FULL_NAME_ERROR = "ACCOUNTANT_SET_FULL_NAME_ERROR",
  ACCOUNTANT_REMOVE_ERRORS = "ACCOUNTANT_REMOVE_ERRORS",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AccountantsSetAction {
  type: AccountantsActionTypes.ACCOUNTANTS_SET;
  payload: AccountantResponse[];
}
interface AccountantSetAction {
  type: AccountantsActionTypes.ACCOUNTANT_SET;
  payload: AccountantResponse;
}
interface AccountantRemoveAction {
  type: AccountantsActionTypes.ACCOUNTANT_REMOVE;
}
interface AccountantSetPhoneNumberErrorAction {
  type: AccountantsActionTypes.ACCOUNTANT_SET_PHONE_NUMBER_ERROR;
  payload: ErrorPayload;
}
interface AccountantSetFullNameErrorAction {
  type: AccountantsActionTypes.ACCOUNTANT_SET_FULL_NAME_ERROR;
  payload: ErrorPayload;
}
interface AccountantRemoveErrors {
  type: AccountantsActionTypes.ACCOUNTANT_REMOVE_ERRORS;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | AccountantsSetAction
  | AccountantSetAction
  | AccountantRemoveAction
  | AccountantRemoveErrors
  | AccountantSetFullNameErrorAction
  | AccountantSetPhoneNumberErrorAction
  | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setAccountants: (payload: AccountantResponse[]): AccountantsSetAction => ({
    type: AccountantsActionTypes.ACCOUNTANTS_SET,
    payload,
  }),
  setAccountant: (payload: AccountantResponse): AccountantSetAction => ({
    type: AccountantsActionTypes.ACCOUNTANT_SET,
    payload,
  }),
  removeAccountant: (): AccountantRemoveAction => ({
    type: AccountantsActionTypes.ACCOUNTANT_REMOVE,
  }),
  setPhoneNumberError: (
    payload: ErrorPayload
  ): AccountantSetPhoneNumberErrorAction => ({
    type: AccountantsActionTypes.ACCOUNTANT_SET_PHONE_NUMBER_ERROR,
    payload,
  }),
  setFullNameError: (
    payload: ErrorPayload
  ): AccountantSetFullNameErrorAction => ({
    type: AccountantsActionTypes.ACCOUNTANT_SET_FULL_NAME_ERROR,
    payload,
  }),
  removeErrors: (): AccountantRemoveErrors => ({
    type: AccountantsActionTypes.ACCOUNTANT_REMOVE_ERRORS,
  }),
  getAccountant:
    (userId: string): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await accountantApi.apiAccountantUserIdGet(userId);

        dispatch(actionCreators.setAccountant(data));
      } catch ({ response }) {
        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  getAccountants: (): AppThunkAction<KnownAction> => async (dispatch) => {
    try {
      const { data } = await accountantApi.apiAccountantGet();

      dispatch(actionCreators.setAccountants(data.accountants));
    } catch ({ response }) {
      const { data } = response as ApiError;

      dispatch(
        notificationActions.setNotification({
          message: data.title,
          severity: "error",
        })
      );
    }
  },
  addAccountant:
    (
      body: CreateAccountantRequest,
      callBack?: (hasError: boolean) => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await accountantApi.apiAccountantPost(body);

        const { data } = await accountantApi.apiAccountantGet();
        dispatch(actionCreators.setAccountants(data.accountants));

        if (callBack) {
          callBack(false);
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (callBack) {
          callBack(true);
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  deleteAccountant:
    (
      body: DeleteAccountantRequest,
      callBack?: (hasError: boolean) => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await accountantApi.apiAccountantDelete(body);

        const { data } = await accountantApi.apiAccountantGet();
        dispatch(actionCreators.setAccountants(data.accountants));

        if (callBack) {
          callBack(false);
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (callBack) {
          callBack(true);
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: AccountantsState = {
  accountants: [],
  accountant: { userUid: "", fullName: "", phone: "" },
  errors: {
    phoneNumber: "",
    fullName: "",
  },
};

export const reducer: Reducer<AccountantsState> = (
  state: AccountantsState = defaultState,
  incomingAction: Action
): AccountantsState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case AccountantsActionTypes.ACCOUNTANTS_SET:
      return { ...state, accountants: action.payload };
    case AccountantsActionTypes.ACCOUNTANT_REMOVE:
      return { ...state, accountant: defaultState.accountant };
    case AccountantsActionTypes.ACCOUNTANT_SET:
      return { ...state, accountant: action.payload };
    case AccountantsActionTypes.ACCOUNTANT_SET_PHONE_NUMBER_ERROR:
    case AccountantsActionTypes.ACCOUNTANT_SET_FULL_NAME_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      };
    case AccountantsActionTypes.ACCOUNTANT_REMOVE_ERRORS:
      return { ...state, errors: defaultState.errors };
    default:
      return state;
  }
};
