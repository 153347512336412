import MuiPaper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import theme from "theme";

export const Paper = styled(MuiPaper)(() => ({
  width: "100%",
  height: "180px",
  [theme.breakpoints.up("lg")]: {
    height: "156px",
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "24px",
  margin: "8px 0",
}));
