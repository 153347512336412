import { Action, Reducer } from 'redux';
import { DigitalSignApi } from 'api'
import { DigitalSignInfoResponse } from 'api/models'
import { AppThunkAction } from './';
import { getBase64 } from 'services/file';
import { ApiError } from "../types";

const digitalSignApi = new DigitalSignApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DigitalSignState {
    info: DigitalSignInfoResponse;
    errors: {
        uploadDigitalSign: string
    }
}

enum DigitalSignActionTypes {
    SET_DIGITAL_SIGN_INFO = 'SET_DIGITAL_SIGN_INFO',
    DIGITAL_SIGN_SET_ERROR = 'DIGITAL_SIGN_SET_ERROR',
    DIGITAL_SIGN_REMOVE_ERRORS = 'DIGITAL_SIGN_REMOVE_ERRORS'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetDigitalSignInfoAction { type: DigitalSignActionTypes.SET_DIGITAL_SIGN_INFO, payload: DigitalSignInfoResponse, callBack?: () => void}
export interface DigitalSignSetErrorAction { type: DigitalSignActionTypes.DIGITAL_SIGN_SET_ERROR, payload: ErrorPayload }
export interface DigitalSignRemoveErrorsAction { type: DigitalSignActionTypes.DIGITAL_SIGN_REMOVE_ERRORS }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    SetDigitalSignInfoAction |
    DigitalSignSetErrorAction |
    DigitalSignRemoveErrorsAction;

export type FormValues = {
    files: File[],
    password: string,
};

enum ErrorFieldName {
    uploadDigitalSign = 'uploadDigitalSign'
}

type ErrorPayload = {
    field: ErrorFieldName,
    message: string
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setDigitalSignInfo: (payload: DigitalSignInfoResponse): SetDigitalSignInfoAction => ({
        type: DigitalSignActionTypes.SET_DIGITAL_SIGN_INFO,
        payload,
    }),
    uploadDigitalSign: ({ files, password }: FormValues, callBack?: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            const { data } = await digitalSignApi.apiDigitalSignUploadPost({
                files: await Promise.all(Array.from(files).map(async (file) => ({
                    fileName: file.name,
                    contentBase64: await getBase64(file),
                }))),
                password,
            });

            dispatch(actionCreators.setDigitalSignInfo(data));
            if (callBack) {
                callBack();
            }
        } catch ({ response }) {
            const { data } = response as ApiError;
            dispatch(actionCreators.setDigitalSignError({ message: data.title, field: ErrorFieldName.uploadDigitalSign }));
        }
    },
    uploadDigitalSignAndUpdateData: ({ files, password }: FormValues, callBack?: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            const { data } = await digitalSignApi.apiDigitalSignUploadAndUpdateDataPost({
                files: await Promise.all(Array.from(files).map(async (file) => ({
                    fileName: file.name,
                    contentBase64: await getBase64(file),
                }))),
                password,
            });

            dispatch(actionCreators.setDigitalSignInfo(data));
            if (callBack) {
                callBack();
            }
        } catch ({ response }) {
            const { data } = response as ApiError;
            dispatch(actionCreators.setDigitalSignError({ message: data.title, field: ErrorFieldName.uploadDigitalSign }));
        }
    },
    setDigitalSignError: (payload: ErrorPayload): DigitalSignSetErrorAction => ({ type: DigitalSignActionTypes.DIGITAL_SIGN_SET_ERROR, payload }),
    removeErrors: (): DigitalSignRemoveErrorsAction => ({ type: DigitalSignActionTypes.DIGITAL_SIGN_REMOVE_ERRORS })
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: DigitalSignState = {
    info: {},
    errors: {
        uploadDigitalSign: ''
    }
};

export const reducer: Reducer<DigitalSignState> = (state: DigitalSignState = defaultState, incomingAction: Action): DigitalSignState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case DigitalSignActionTypes.SET_DIGITAL_SIGN_INFO:
            return { ...state, info: action.payload };
        case DigitalSignActionTypes.DIGITAL_SIGN_SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.field]: action.payload.message
                }
            };
        case DigitalSignActionTypes.DIGITAL_SIGN_REMOVE_ERRORS:
            return { ...state, errors: defaultState.errors };
        default:
            return state;
    }
};
