import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { FormInput } from "components/FormInput";
import Loader from "components/Loader";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { ApplicationState } from "store";

interface ReduxProps {
  receiptUid: string;
  receiptReturnUid: string;
}

interface Props {
  isReturnReceipt?: boolean;
  isOpenModal: boolean;
  onCloseModal: () => void;
  sendReceipt: (
    receiptUid: string,
    email: string,
    fullName: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
}

type FormValues = {
  email: string;
  fullName: string;
};

const SendReceiptDialog: FC<Props & ReduxProps> = (props) => {
  const { isOpenModal, onCloseModal, sendReceipt } = props;
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<FormValues>({
    mode: "onSubmit",
  });

  const email = methods.watch("email");
  const fullName = methods.watch("fullName");

  const onSendReceipt = () => {
    const { isReturnReceipt = false, receiptUid, receiptReturnUid } = props;

    setIsLoading(true);

    sendReceipt(
      isReturnReceipt ? receiptReturnUid : receiptUid,
      email,
      fullName,
      () => {
        setIsLoading(false);
        onCloseModal();
      },
      () => {
        setIsLoading(false);
        onCloseModal();
      }
    );
  };

  const onSubmit = () => {
    methods.handleSubmit(onSendReceipt)();
  };

  useEffect(() => {
    isOpenModal && methods.reset();
  }, [isOpenModal]);

  return (
    <>
      <Dialog
        fullWidth
        open={isOpenModal}
        PaperProps={{ sx: { minHeight: 420, maxWidth: 424 } }}
      >
        <DialogTitle>
          Надіслати чек
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {isLoading ? (
            <Loader />
          ) : (
            <FormProvider {...methods}>
              <Box>
                <Typography mb={0.5} variant="body2">
                  ЕЛЕКТРОННА ПОШТА
                  <Typography sx={{ display: "inline", color: "red" }}>
                    *
                  </Typography>
                  :
                </Typography>
                <FormInput
                  fullWidth
                  name="email"
                  autoFocus={true}
                  type="email"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Не правильний формат електронної пошти",
                    },
                  }}
                />
              </Box>
              <Box mt={2}>
                <Typography mb={0.5} variant="body2">
                  ПРІЗВИЩЕ ІМ&apos;Я КЛІЄНТА(ОДЕРЖУВАЧА)
                  <Typography sx={{ display: "inline", color: "red" }}>
                    *
                  </Typography>
                  :
                </Typography>
                <FormInput fullWidth name="fullName" />
              </Box>
            </FormProvider>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            sx={{ margin: "16px" }}
            fullWidth
            size="large"
            variant="contained"
            disabled={!email || isLoading}
            onClick={onSubmit}
          >
            Відправити
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ receipt, receiptReturn }: ApplicationState) => ({
  receiptUid: receipt.receipt?.receiptUid || "",
  receiptReturnUid: receiptReturn.receiptReturn?.receiptUid || "",
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SendReceiptDialog);
