import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Link from "@mui/material/Link";

import { AccountantResponse } from "api";
import { actionCreators } from "store/Accountants";
import { ReportTable, HeadCell } from "components/Table";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface Props {
  onOpenModal: () => void;
}

const Table: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const accountants = useSelector((state) => state.accountants.accountants);

  const columns: HeadCell[] = [
    {
      id: "fullName",
      label: "Прізвище імʼя по-батькові бухгалтера",
      cell: (cell) => cell || "–",
    },
    {
      id: "phone",
      label: "Телефон бухгалтера",
      cell: (cell) => cell || "–",
    },
    {
      id: "settings",
      label: "",
      width: 100,
      cell: (cell, row) => (
        <Link
          onClick={() => {
            dispatch(actionCreators.setAccountant(row as AccountantResponse));
            props.onOpenModal();
            analytics().sendEvent(AnalyticsEvents.accountants_accountant_info_modal);
          }}
          component="button"
        >
          Редагувати
        </Link>
      ),
    },
  ];

  return (
    <ReportTable
      idName="userUid"
      enablePagination={false}
      rows={accountants}
      columns={columns}
    />
  );
};

export default Table;
