import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Paper } from "./styles";

import { WorkShiftResponse } from "api";
import { Routes } from "shared/constants/routes";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

const calculateReceiptCount = (workShift: WorkShiftResponse | null) => {
  if (!workShift) {
    return 0;
  }

  const { cashReceiptsCount, cardReceiptsCount } = workShift;
  const amount = cashReceiptsCount + cardReceiptsCount;

  return amount;
};

const ReceiptBlock: React.FC = () => {
  const history = useHistory();
  const workShift = useSelector((state) => state.workShift.workShift);

  const onProductOrder = () => {
    analytics().sendEvent(AnalyticsEvents.sales_start_order_btn);
    history.push(
      `${Routes.workShift}/${workShift?.workShiftId}${Routes.order}`
    );
  };

  return (
    <Box width={{ xs: "90%", sm: "48%", md: "30%" }}>
      <Paper variant="outlined">
        <Typography variant="body2">Чеки:</Typography>
        <Typography variant="body1">
          Видано чеків за зміну: {calculateReceiptCount(workShift)}
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          alignItems={{ xs: "flex-start", lg: "flex-end" }}
          justifyContent="space-between"
        >
          <Box pt={{ xs: 1, lg: 0 }} pb={{ xs: 1, lg: 0 }}>
            <Typography
              variant="body1"
              color={({ colors }) => colors.secondaryGrey}
              fontWeight="100"
            >
              Готівка: {workShift?.cashReceiptsCount || 0}
            </Typography>
            <Typography
              variant="body1"
              color={({ colors }) => colors.secondaryGrey}
              fontWeight="100"
            >
              Безготівка: {workShift?.cardReceiptsCount || 0}
            </Typography>
          </Box>
          <Button
            size="medium"
            variant="contained"
            onClick={() => onProductOrder()}
          >
            Продаж товарів
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ReceiptBlock;
