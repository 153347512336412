import React, { FC } from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { Article } from './blogMockData'
import { Routes } from 'shared/constants/routes';

const Blog: FC<Article> = ({ imageSmall, title, chips, date, articleId }) => {
  const history = useHistory();

  return (
    <Box
      width={372}
      display='flex'
      flexDirection='column'
      mb={3} mr={3} ml={3} mt={3} 
      onClick={() => history.push(`${Routes.blogArticle}/${articleId}`)}
      sx={{ cursor: 'pointer' }}  
    >
      <img
        style={{ width: 372 , height: 250 }}
        src={imageSmall}
        alt={title}
        loading="lazy"
      />
      <Box>
        {chips.map((label) => <Chip sx={{ marginTop: 2, marginBottom: 1, marginRight: 1 }} key={label} label={label} size='small' />)}
      </Box>
      <Link
        mt={1}
        lineHeight={1.1}
        color={(theme) => theme.colors.body}
        fontSize={21}
        href={`/blog/article/${articleId}`}
        underline="none"
      >
        {title}
      </Link>
      <Typography mt={1} fontWeight='300' color={({ colors }) => colors.secondaryGrey}>
        {date}
      </Typography>
    </Box>
  );
};

export default Blog;
