import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';

import UserMenu from './UserMenu';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

import { Routes } from 'shared/constants/routes';
import ResponsiveMenu from './ResponsiveMenu';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';
import { UserRoleEnum } from 'api';

const AppBarMui = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  height: 56,
  background: theme.palette.common.white,
  display: 'flex',
  boxShadow: '0 4px 8px rgba(95, 98, 115, 0.16)',
  flexDirection: 'row',
}));

const styles = {
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  linksContainer: {
    marginLeft: '36px',
    display: { xs: 'none', md: 'block' }
  },
  buttonsContainer: {
    marginLeft: 'auto',
    display: { xs: 'none', md: 'block' }
  },
  responsiveMenuContainer: {
    marginLeft: 'auto',
    display: { xs: 'block', md: 'none' }
  }
};

interface HeaderProps {
  website?: boolean,
}

type AllProps = HeaderProps & RouteComponentProps;

const Header: FC<AllProps> = (props) => {
  const { website } = props;
  const authorized = useSelector((state) => state.user.authorized);
  const userRole = useSelector((state) => state.user.data.userRole);
  const tariffName = useSelector(
    (state) => state.subscription.subscription.paymentPlan.name
  );
  const route =
    UserRoleEnum.Accountant === userRole ? '/reports' : '/business-structure';
  const websitePages = props.location.pathname == '/' || website;

  const renderLinks = () => {
    if (authorized && !websitePages) {
      return (
        tariffName && (
          <Box sx={{ marginLeft: '36px' }}>
            <Chip
              label={`Тариф ${tariffName}`}
              color='secondary'
              size='medium'
              onClick={() => props.history.push(Routes.subscription)}
            />
          </Box>
        )
      );
    }

    return (
      <Box sx={styles.linksContainer}>
        <Link
          href='#about'
          sx={{ marginRight: '40px' }}
          underline='none'
          color={(theme) => theme.colors.body}
        >
          Що таке ФОПкаса
        </Link>
        <Link
          href='#organization'
          sx={{ marginRight: '40px' }}
          underline="none"
          color={(theme) => theme.colors.body}
        >
          Переваги
        </Link>
        <Link
          href='/blog'
          sx={{ marginRight: '40px' }}
          underline="none"
          color={(theme) => theme.colors.body}
        >
          Блог
        </Link>
        <Link
          href='/iban-oplata'
          sx={{ marginRight: '40px' }}
          underline="none"
          color={(theme) => theme.colors.body}
        >
          Iban оплата
        </Link>
      </Box>
    )
  };

  const renderButtons = () => {
    if (websitePages && authorized) {
      return (
        <Button
          onClick={() => {
            props.history.push(route);
            analytics().sendEvent(AnalyticsEvents.menu_login_to_myaccount);}}
          variant='contained'
          color='secondary'
        >
          Увійти в кабінет
        </Button>
      );
    }

    if (authorized) {
      return <UserMenu />;
    }

    return (
      <>
        <Button
          onClick={() => {
            props.history.push(Routes.login);          
            analytics().sendEvent(AnalyticsEvents.hp_login);}}
          variant='contained'
          color='secondary'
        >
          Увійти в кабінет
        </Button>
      </>
    )
  }

  return (
    <AppBarMui>
      <Container sx={styles.headerContainer}>
        <Logo
          style={{ cursor: 'pointer' }}
          onClick={() => props.history.push(Routes.home)}/>
          {renderLinks()}
        <Box sx={styles.buttonsContainer}>
          {renderButtons()}
        </Box>
        <Box sx={styles.responsiveMenuContainer}>
          <ResponsiveMenu website={websitePages} />
        </Box>
      </Container>
    </AppBarMui>
  )
};

export default withRouter(Header);
