import React from 'react';
import { styled } from '@mui/material/styles';
import TextField, { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from '@mui/material/TextField';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker, { DesktopDatePickerProps } from '@mui/lab/DesktopDatePicker';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import ukLocale from 'dayjs/locale/uk';

dayjs.extend(isBetween);

interface IProps {
  startDate: Date,
  endDate: Date,
  onClose: ([start, end]: [Date, Date]) => void
  onChange: ([start, end]: [Date, Date]) => void
}

type CustomPickerDayProps = PickersDayProps<Dayjs> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay"
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%"
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
})) as React.ComponentType<CustomPickerDayProps>;

const DateRangePicker: React.FC<IProps> = ({onClose, onChange, startDate, endDate}) => {
  const renderWeekPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    if (!startDate || !endDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = dayjs(startDate).startOf('day');
    const end = dayjs(endDate).endOf('day');

    const dayIsBetween = dayjs(date).isBetween(start, end, null, '[]');
    const isFirstDay = dayjs(date).isSame(start, 'day');
    const isLastDay = dayjs(date).isSame(end, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const onChangeCallback: DesktopDatePickerProps<Dayjs>['onChange'] = (value: Dayjs | null) => {
    if (!value) return;

    const newValue = value.toDate();

    if (newValue < startDate) {
      onChange([newValue, endDate]);
    } else if (newValue > endDate) {
      onChange([startDate, newValue]);
    } else {
      onChange([newValue, newValue]);
    }
  };

  const rifmFormatter = () => {
    const format = (date: Date) => dayjs(date).locale('uk').format('DD MMMM YYYY');

    if (dayjs(startDate).isSame(endDate, 'day')) {
      return format(startDate);
    }

    return `${format(startDate)} - ${format(endDate)}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={ukLocale}>
      <DesktopDatePicker
        disableCloseOnSelect
        allowSameDateSelection
        disableFuture
        mask='__.__.____'
        value={null}
        rifmFormatter={rifmFormatter}
        onChange={onChangeCallback}
        onClose={() => onClose([startDate, endDate])}
        renderDay={renderWeekPickerDay}
        renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
};

export default DateRangePicker;
