import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

import { ReactComponent as IconUpload } from "assets/images/icon_upload.svg";
import { ReactComponent as IconDownload } from "assets/images/icon_download.svg";

import { ProductApi } from "api";
import { actionCreators } from "store/Notification";
import { actionCreators as productActions } from "store/Product";
import { actionCreators as categoryActions } from "store/Category";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";
import { Tooltip } from "@mui/material";

const productApi = new ProductApi();

const Input = styled("input")({
  display: "none",
});

const ExportImportBtns: React.FC = () => {
  const dispatch = useDispatch();
  const [isImportingProducts, setIsImportingProducts] = useState(false);
  const [isExportingProducts, setIsExportingProducts] = useState(false);

  const onExportProducts = () => {
    setIsExportingProducts(true);
    productApi
      .productExportGet({ responseType: "blob" })
      .then(({ data }) => {
        const blob = new Blob([data], { type: "application/vnd.ms-excel" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = "товари.xlsx";
        a.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 0);
        setIsExportingProducts(false);
      })
      .catch(({ response }) => {
        dispatch(
          actionCreators.setNotification({
            message: response.data.title,
            severity: "error",
          })
        );
        setIsExportingProducts(false);
      });
    analytics().sendEvent(AnalyticsEvents.products_export_products);
  };

  const onImportProducts = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files.length) {
      return;
    }

    setIsImportingProducts(true);

    productApi
      .productImportPostForm(event.target.files[0])
      .then(() => {
        dispatch(
          actionCreators.setNotification({
            message: "Товари успішно імпортовано",
            severity: "success",
          })
        );
        dispatch(productActions.getProducts());
        dispatch(categoryActions.getCategories());
      })
      .catch(({ response }) => {
        dispatch(
          actionCreators.setNotification({
            message: response.data.title,
            severity: "error",
          })
        );
      })
      .finally(() => setIsImportingProducts(false));
    analytics().sendEvent(AnalyticsEvents.products_import_products);
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Експорт товарів в Excel файл">
        {isExportingProducts ? (
          <Box sx={styles.loaderWrapper}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <IconButton
            sx={styles.button}
            disableRipple
            disableTouchRipple
            onClick={onExportProducts}
            disabled={isExportingProducts}
          >
            <IconDownload />
          </IconButton>
        )}
      </Tooltip>
      <Box display="inline-block">
        {isImportingProducts ? (
          <Box sx={styles.loaderWrapper}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <label htmlFor="import-products-file">
            <Input
              value=""
              accept="*"
              id="import-products-file"
              type="file"
              onChange={onImportProducts}
            />
            <Tooltip title="Імпорт товарів з Excel файлу">
              <IconButton
                sx={styles.button}
                disableRipple
                disableTouchRipple
                component="span"
                disabled={isImportingProducts}
              >
                <IconUpload />
              </IconButton>
            </Tooltip>
          </label>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  button: {
    backgroundColor: "#DCEEF6",
    borderRadius: "4px",
    marginRight: "16px",
    padding: "10px",
  },
  loaderWrapper: {
    backgroundColor: "#DCEEF6",
    borderRadius: "4px",
    marginRight: "16px",
    padding: "8px 10px",
  },
};

export default ExportImportBtns;
