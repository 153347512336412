import React, { FC } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { actionCreators } from "store/Order";

interface ReduxProps {
  cleanOrder: () => void;
}

interface Props {
  path: string;
  isOpenModal: boolean;
  onCloseModal: () => void;
}

const OrderRefreshDialog: FC<Props & ReduxProps> = (props) => {
  const history = useHistory();
  const { path, isOpenModal, onCloseModal, cleanOrder } = props;

  const onConfirm = async () => {
    await cleanOrder();
    onCloseModal();
    if (path) {
      history.push(path);
    }
  };

  return (
    <Dialog open={isOpenModal} fullWidth>
      <DialogTitle>
        Видалення товарів з кошика
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography fontWeight={300} paragraph>
          Ви не завершили оформлення замовлення. Усі товари, які знаходяться в
          кошику, буде втрачено. Підтвердіть, будь ласка, видалення товарів з
          кошика.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseModal}
          size="large"
          variant="contained"
          color="inherit"
        >
          Скасувати
        </Button>
        <Button
          size="large"
          variant="contained"
          color="error"
          onClick={onConfirm}
        >
          Підтвердити
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  cleanOrder: actionCreators.cleanOrder,
};

export default connect(() => ({}), mapDispatchToProps)(OrderRefreshDialog);
