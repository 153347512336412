/* tslint:disable */
/* eslint-disable */
/**
 * FOPkasa.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateReceiptRequest } from '../models';
import { CreateReceiptResponse } from '../models';
import { ReceiptResponse } from '../models';
import { SendReceiptByEmailRequest } from '../models';
/**
 * ReceiptApi - axios parameter creator
 * @export
 */
export const ReceiptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [crUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCrGet: async (crUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Receipt/cr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (crUid !== undefined) {
                localVarQueryParameter['crUid'] = crUid;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [receiptNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptFindGet: async (receiptNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Receipt/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (receiptNumber !== undefined) {
                localVarQueryParameter['receiptNumber'] = receiptNumber;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateReceiptRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptPost: async (body?: CreateReceiptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendReceiptByEmailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptSendEmailPost: async (body?: SendReceiptByEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Receipt/send/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceiptApi - functional programming interface
 * @export
 */
export const ReceiptApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [crUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCrGet(crUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ReceiptResponse>>> {
            const localVarAxiosArgs = await ReceiptApiAxiosParamCreator(configuration).receiptCrGet(crUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [receiptNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptFindGet(receiptNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ReceiptResponse>>> {
            const localVarAxiosArgs = await ReceiptApiAxiosParamCreator(configuration).receiptFindGet(receiptNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ReceiptResponse>>> {
            const localVarAxiosArgs = await ReceiptApiAxiosParamCreator(configuration).receiptGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateReceiptRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptPost(body?: CreateReceiptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CreateReceiptResponse>>> {
            const localVarAxiosArgs = await ReceiptApiAxiosParamCreator(configuration).receiptPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SendReceiptByEmailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptSendEmailPost(body?: SendReceiptByEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await ReceiptApiAxiosParamCreator(configuration).receiptSendEmailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReceiptApi - factory interface
 * @export
 */
export const ReceiptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [crUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCrGet(crUid?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ReceiptResponse>> {
            return ReceiptApiFp(configuration).receiptCrGet(crUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [receiptNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptFindGet(receiptNumber?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ReceiptResponse>> {
            return ReceiptApiFp(configuration).receiptFindGet(receiptNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptGet(id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ReceiptResponse>> {
            return ReceiptApiFp(configuration).receiptGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateReceiptRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptPost(body?: CreateReceiptRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<CreateReceiptResponse>> {
            return ReceiptApiFp(configuration).receiptPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendReceiptByEmailRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptSendEmailPost(body?: SendReceiptByEmailRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return ReceiptApiFp(configuration).receiptSendEmailPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceiptApi - object-oriented interface
 * @export
 * @class ReceiptApi
 * @extends {BaseAPI}
 */
export class ReceiptApi extends BaseAPI {
    /**
     * 
     * @param {string} [crUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptApi
     */
    public async receiptCrGet(crUid?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ReceiptResponse>> {
        return ReceiptApiFp(this.configuration).receiptCrGet(crUid, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [receiptNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptApi
     */
    public async receiptFindGet(receiptNumber?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ReceiptResponse>> {
        return ReceiptApiFp(this.configuration).receiptFindGet(receiptNumber, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptApi
     */
    public async receiptGet(id?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ReceiptResponse>> {
        return ReceiptApiFp(this.configuration).receiptGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CreateReceiptRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptApi
     */
    public async receiptPost(body?: CreateReceiptRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<CreateReceiptResponse>> {
        return ReceiptApiFp(this.configuration).receiptPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {SendReceiptByEmailRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptApi
     */
    public async receiptSendEmailPost(body?: SendReceiptByEmailRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return ReceiptApiFp(this.configuration).receiptSendEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
