import React, { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DeleteButton from "components/DeleteButton";
import FormSelect from "components/FormInput/FormSelect";
import { FormCashInput, FormInput } from "components/FormInput";
import { ProductContext } from "contexts/ProductContext";

import { actionCreators } from "store/Product";

import { ApplicationState } from "store";
import {
  CategoryResponse,
  ProductResponse,
  ProductUnitResponse,
  UpdateProductRequest,
} from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

type FormValues = {
  name: string;
  price: string;
  code: string;
  barcode: string;
  productUnitId: number;
  productCategoryId: number;
};

interface ReduxProps {
  updateProduct: (
    data: UpdateProductRequest,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  deleteProducts: (
    productIds: number[],
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  getProducts: () => void;
  setSelectedProduct: (product: ProductResponse | null) => void;
  productUnits: ProductUnitResponse[];
  selectedProduct: ProductResponse | null;
  categories: CategoryResponse[];
}

const Edit: FC<ReduxProps> = (props) => {
  const { productUnits, categories } = props;
  const { onCloseModal } = useContext(ProductContext);

  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const methods = useForm<FormValues>({
    mode: "onChange",
  });

  const name = methods.watch("name") || "";
  const price = methods.watch("price") || "";

  useEffect(() => {
    const { setSelectedProduct, selectedProduct } = props;

    methods.reset({
      name: selectedProduct?.name || "",
      price: selectedProduct?.price?.toString(),
      code: selectedProduct?.code || "",
      barcode: selectedProduct?.barcode || "",
      productUnitId: selectedProduct?.unit.productUnitId,
      productCategoryId: selectedProduct?.category?.categoryId,
    });
    return () => {
      methods.reset({});
      setSelectedProduct(null);
    };
  }, []);

  const onDeleteProduct = () => {
    const { deleteProducts, getProducts, selectedProduct } = props;
    setIsDeleteInProgress(true);

    selectedProduct?.productId &&
      deleteProducts(
        [selectedProduct.productId],
        () => {
          analytics().sendEvent(AnalyticsEvents.delete_product_btn_modal);
          getProducts();
          setIsDeleteInProgress(false);
          onCloseModal();
        },
        () => {
          setIsDeleteInProgress(false);
          onCloseModal();
        }
      );
  };

  const onSubmit = (data: FormValues) => {
    setIsUpdateInProgress(true);

    const price = parseFloat(data.price);
    const { updateProduct, getProducts, setSelectedProduct, selectedProduct } =
      props;

    selectedProduct?.productId &&
      updateProduct(
        { ...data, price, productId: selectedProduct?.productId },
        () => {
          analytics().sendEvent(AnalyticsEvents.edit_product_btn_modal);
          getProducts();
          setIsUpdateInProgress(false);
          onCloseModal();
          methods.reset({});
          setSelectedProduct(null);
        },
        () => {
          setIsUpdateInProgress(false);
          onCloseModal();
        }
      );
  };

  const onCancel = () => {
    onCloseModal();
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={0.5} variant="body2">
              НАЗВА ТОВАРУ
              <Typography sx={{ display: "inline", color: "red" }}>
                *
              </Typography>
              :
            </Typography>
            <FormInput fullWidth name="name" autoFocus={true} />
          </Box>
          <Box display="flex">
            <Box mt={2} mr={2} flex="1">
              <Typography mb={0.5} variant="body2">
                ЦІНА ТОВАРУ
                <Typography sx={{ display: "inline", color: "red" }}>
                  *
                </Typography>
                :
              </Typography>
              <FormCashInput fullWidth name="price" />
            </Box>
            <Box mt={2} flex="1">
              <Typography mb={0.5} variant="body2">
                ШТРИХКОД:
              </Typography>
              <FormInput fullWidth name="barcode" />
            </Box>
          </Box>
          <Box display="flex">
            <Box mt={2} mr={2} flex="1">
              <Typography mb={0.5} variant="body2">
                КОД:
              </Typography>
              <FormInput fullWidth name="code" />
            </Box>
            <Box mt={2} flex="1">
              <Typography mb={0.5} variant="body2">
                ОДИНИЦЯ ВИМІРУ:
              </Typography>
              <FormSelect fullWidth name="productUnitId">
                {productUnits.map((unit) => (
                  <MenuItem key={unit.productUnitId} value={unit.productUnitId}>
                    {unit.symbol}
                  </MenuItem>
                ))}
              </FormSelect>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography mb={0.5} variant="body2">
              КАТЕГОРІЯ:
            </Typography>
            <FormSelect fullWidth name="productCategoryId">
              {categories.map((category) => (
                <MenuItem key={category.categoryId} value={category.categoryId}>
                  {category.name}
                </MenuItem>
              ))}
            </FormSelect>
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <DeleteButton
          disabled={isUpdateInProgress}
          loading={isDeleteInProgress}
          onClick={onDeleteProduct}
        >
          Видалити товар
        </DeleteButton>
        <Box marginLeft="auto">
          <Button
            onClick={onCancel}
            disabled={
              !name.trim() ||
              !price.trim() ||
              price === "0" ||
              isDeleteInProgress ||
              isUpdateInProgress
            }
            size="large"
            variant="contained"
            color="inherit"
            sx={{ marginRight: "8px" }}
          >
            Відмінити
          </Button>
          <LoadingButton
            size="large"
            disabled={
              !name.trim() ||
              !price.trim() ||
              price === "0" ||
              isDeleteInProgress
            }
            onClick={methods.handleSubmit(onSubmit)}
            loading={isUpdateInProgress}
          >
            Зберегти зміни
          </LoadingButton>
        </Box>
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ category, product }: ApplicationState) => ({
  productUnits: product.productUnits,
  selectedProduct: product.selectedProduct,
  categories: category.categories,
});

const mapDispatchToProps = {
  updateProduct: actionCreators.updateProduct,
  deleteProducts: actionCreators.deleteProducts,
  getProducts: actionCreators.getProducts,
  setSelectedProduct: actionCreators.setSelectedProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
