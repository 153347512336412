import { Action, Reducer } from "redux";
import { CashRegisterOverviewApi } from "../api";
import { CashRegisterOverviewResponse } from "../api/models";
import { AppThunkAction } from "./";

const cashRegisterOverviewApi = new CashRegisterOverviewApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface pointOfSaleRegisters {
  pointOfSaleName: string;
  registers: CashRegisterOverviewResponse[];
}

export interface CashRegistersOverviewState {
  cashRegistersOverview: pointOfSaleRegisters[];
  cashRegisterOverview: CashRegisterOverviewResponse | null;
}

enum CashRegistersActionTypes {
  SET_CASH_REGISTERS_OVERVIEW = "SET_CASH_REGISTERS_OVERVIEW",
  SET_CASH_REGISTER_OVERVIEW = "SET_CASH_REGISTER_OVERVIEW",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCashRegistersOverviewAction {
  type: CashRegistersActionTypes.SET_CASH_REGISTERS_OVERVIEW;
  payload: pointOfSaleRegisters[];
}

export interface SetCashRegisterOverviewAction {
  type: CashRegistersActionTypes.SET_CASH_REGISTER_OVERVIEW;
  payload: CashRegisterOverviewResponse | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | SetCashRegistersOverviewAction
  | SetCashRegisterOverviewAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setCashRegistersOverview: (
    payload: pointOfSaleRegisters[]
  ): SetCashRegistersOverviewAction => ({
    type: CashRegistersActionTypes.SET_CASH_REGISTERS_OVERVIEW,
    payload,
  }),
  setCashRegisterOverview: (
    payload: CashRegisterOverviewResponse | null
  ): SetCashRegisterOverviewAction => ({
    type: CashRegistersActionTypes.SET_CASH_REGISTER_OVERVIEW,
    payload,
  }),
  getCashRegistersOverview:
    (): AppThunkAction<KnownAction> => async (dispatch) => {
      const { data } =
        await cashRegisterOverviewApi.apiCashRegisterOverviewGet();
      const grouped: pointOfSaleRegisters[] = [];

      data.cashRegisterOverview.forEach((register) => {
        let existingGroup = grouped.find(
          ({ pointOfSaleName }) => pointOfSaleName === register.pointOfSaleName
        );
        if (existingGroup === undefined) {
          existingGroup = {
            pointOfSaleName: register.pointOfSaleName,
            registers: [],
          };
          grouped.push(existingGroup);
        }

        existingGroup.registers.push(register);
      });

      dispatch(actionCreators.setCashRegistersOverview(grouped));
    },
  getCashRegisterOverview:
    (cashRegisterId: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      const { data } =
        await cashRegisterOverviewApi.apiCashRegisterOverviewCashRegisterIdGet(
          cashRegisterId
        );

      dispatch(actionCreators.setCashRegisterOverview(data));
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: CashRegistersOverviewState = {
  cashRegistersOverview: [],
  cashRegisterOverview: null,
};

export const reducer: Reducer<CashRegistersOverviewState> = (
  state: CashRegistersOverviewState = defaultState,
  incomingAction: Action
): CashRegistersOverviewState => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case CashRegistersActionTypes.SET_CASH_REGISTERS_OVERVIEW:
      return { ...state, cashRegistersOverview: action.payload };
    case CashRegistersActionTypes.SET_CASH_REGISTER_OVERVIEW:
      return { ...state, cashRegisterOverview: action.payload };
    default:
      return state;
  }
};
