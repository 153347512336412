import React from "react";
import { useSelector } from "react-redux";
import { History } from "history";

import Typography from "@mui/material/Typography";

import Logo from "assets/images/logo-outlined.svg";
import Phones from "assets/images/phones-full.png";
import Button from "components/Button";
import Rocket from "assets/images/rocket.png";

import "./index.scss";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";
import { UserRoleEnum } from "api";
import { Routes } from "shared/constants/routes";

interface Props {
  history: History;
}

const Main: React.FC<Props> = (props) => {
  const authorized = useSelector((state) => state.user.authorized);
  const userRole = useSelector(({ user }) => user.data.userRole);
  const route =
    UserRoleEnum.Accountant === userRole ? "/reports" : "/business-structure";

  return (
    <section className="sip-main">
      <Box>
        <img className="sip-main__logo" alt="Logo" src={Logo} />
        <h1 className="main-large sip-main__header">
          ПРРО ФОПкаса: обирайте найкращу онлайн касу для вашого бізнесу{" "}
          <img className="sip-main__rocket" alt="rocket" src={Rocket} />
        </h1>
        <Typography mb={3} variant="h5">
          Каса у смартфоні: аналітика, безкоштовний термінал і все що потрібно
          для фіскалізації чеків.
        </Typography>
        <a className="sip-main__link">
          <Button
            onClick={() => {
              props.history.push(
                authorized ? route : Routes.registrationPhoneNumber
              );
              analytics().sendEvent(AnalyticsEvents.hp_try_for_free);
            }}
            className="sip-main__button"
          >
            Спробувати безкоштовно
          </Button>
        </a>

        <div className="sip-main__about-link-wrapper">
          <Link href="sip/obraty-prro#sip-about" underline="always" color="#ffffff">
            Дізнатись більшe про ПРРО ↓
          </Link>
        </div>
      </Box>
      <div className="sip-main__image-wrapper">
        <img className="sip-main__image" alt="Phones" src={Phones} />
      </div>
    </section>
  );
};

export default Main;
