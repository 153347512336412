import React, { forwardRef } from "react";
import { PatternFormat, PatternFormatProps } from "react-number-format";

interface CustomProps {
  name: string;
  format: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const CustomPatternFormat = forwardRef<PatternFormatProps, CustomProps>(
  function CustomPatternFormat(props, ref) {
    const { onChange, format, ...other } = props;

    return (
      <PatternFormat
        getInputRef={ref}
        format={format}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        {...other}
      />
    );
  }
);

export default CustomPatternFormat;
