import React, { FC } from "react";
import { useDispatch } from "react-redux";

import Link from "@mui/material/Link";

import { ReportTable, HeadCell } from "components/Table";

import { CategoryResponse } from "api";
import { actionCreators } from "store/Category";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface Props {
  categories: CategoryResponse[];
  onOpenModal: () => void;
  onCategoryClick: (category: CategoryResponse | null) => void;
}

const Table: FC<Props> = (props) => {
  const { categories, onOpenModal, onCategoryClick } = props;
  const dispatch = useDispatch();

  const columns: HeadCell[] = [
    {
      id: "name",
      label: "Назва категорії",
      width: "90%",
      cell: (cell, row) => (
        <Link
          onClick={() => {
            analytics().sendEvent(AnalyticsEvents.edit_category_btn);
            onCategoryClick(row as CategoryResponse);
          }}
          component="button"
          sx={{ textDecoration: "none" }}
        >
          {row.name}
        </Link>
      ),
    },
    {
      id: "edit",
      label: "",
      width: "10%",
      cell: (cell, row) => (
        <Link
          onClick={() => {
            dispatch(
              actionCreators.setSelectedCategory(row as CategoryResponse)
            );
            onOpenModal();
          }}
          component="button"
        >
          Редагувати
        </Link>
      ),
    },
  ];

  return (
    <ReportTable idName="categoryId" rows={categories} columns={columns} />
  );
};

export default Table;
