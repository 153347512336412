import React, { useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import RadioButtons from "components/RadioButtons";
import { CustomNumericFormat } from "components/FormInput";

import { ReactComponent as IconBin } from "assets/images/icon_bin.svg";
import { ReactComponent as DiscountIcon } from "assets/images/icon_discount.svg";

import { ApplicationState } from "store";
import { DiscountTypeEnum, actionCreators } from "store/Order";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import theme from "theme";
import { NumberFormatValues } from "react-number-format";

interface ReduxProps {
  setDiscountValue: (payload: number | null) => void;
  setDiscountType: (payload: DiscountTypeEnum) => void;
  discountValue: number | null;
  discountType: DiscountTypeEnum;
  totalAmount: number;
}

const discountButtons = [
  { value: DiscountTypeEnum.Percentage, text: "%" },
  { value: DiscountTypeEnum.Amount, text: "₴" },
];

const DiscountBlock: React.FC<ReduxProps> = (props) => {
  const { discountValue, discountType, totalAmount } = props;
  const [showDiscountInput, setShowDiscountInput] = useState(false);

  const MAX_PERCENTAGE = 100;
  const MAX_AMOUNT = totalAmount;

  const handleDiscountTypeChange = (value: string) => {
    const { setDiscountType, setDiscountValue } = props;
    setDiscountValue(0);
    setDiscountType(value as DiscountTypeEnum);
  };

  const onChangeDiscountValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { setDiscountValue } = props;
    const discount = parseFloat(event.target.value);
    isNaN(discount) ? setDiscountValue(null) : setDiscountValue(discount);
  };

  const onBlurDiscountValue = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const { setDiscountValue } = props;
    const discount = parseFloat(event.target.value);
    isNaN(discount) && setDiscountValue(0);
  };

  const onAddDiscount = () => {
    setShowDiscountInput(true);
    analytics().sendEvent(AnalyticsEvents.sales_add_discount);
  };

  const onRemoveDiscount = () => {
    const { setDiscountValue } = props;
    setDiscountValue(0);
    setShowDiscountInput(false);
    analytics().sendEvent(AnalyticsEvents.sales_remove_discount);
  };

  const isAllowed = (values: NumberFormatValues) => {
    const { discountType } = props;
    const MAX_VALUE =
      discountType === DiscountTypeEnum.Percentage
        ? MAX_PERCENTAGE
        : MAX_AMOUNT;

    const value = values.floatValue || 0;

    return value <= MAX_VALUE;
  };

  return (
    <Box pt={1} pb={1}>
      {showDiscountInput ? (
        <Box
          display="flex"
          justifyContent="space-between"
          bgcolor={theme.colors.blueWhite}
          p={1}
          borderRadius="4px"
        >
          <TextField
            sx={{ backgroundColor: "white" }}
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: CustomNumericFormat as any,
              inputProps: {
                isAllowed: isAllowed,
              },
              startAdornment: (
                <InputAdornment position="start">Знижка:</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <RadioButtons
                    activeValue={discountType}
                    radioButtons={discountButtons}
                    onChange={handleDiscountTypeChange}
                  />
                </InputAdornment>
              ),
            }}
            onChange={onChangeDiscountValue}
            onBlur={onBlurDiscountValue}
            value={discountValue}
          />
          <IconButton disableRipple onClick={onRemoveDiscount}>
            <IconBin />
          </IconButton>
        </Box>
      ) : (
        <Button
          sx={{ color: theme.colors.secondaryBlue }}
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          startIcon={<DiscountIcon />}
          onClick={onAddDiscount}
        >
          Надати знижку
        </Button>
      )}
    </Box>
  );
};

const mapStateToProps = ({ order }: ApplicationState) => ({
  discountValue: order.discount.value,
  discountType: order.discount.type,
  totalAmount: order.totalAmount,
});

const mapDispatchToProps = {
  setDiscountValue: actionCreators.setDiscountValue,
  setDiscountType: actionCreators.setDiscountType,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountBlock);
