import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';

import { FormTimeInputProps } from './types';

const FormTimeField: FC<FormTimeInputProps> = props => {
  const {
    name, rules, defaultValue = '', ...inputProps
  } = props;

  const formContext = useFormContext();
  const {
    control,
    formState: {
      errors: {
        [name]: { message = '' } = {}
      } = {}
    }
  } = formContext;

  const { field } = useController({
    name, control, rules, defaultValue,
  });

  const onChange = (newValue: string) => {
    field.onChange(newValue || dayjs());
  }

  return (
    <TimeField
      {...inputProps}
      ampm={false}
      color={message ? 'error' : 'primary' }
      onChange={onChange}
      value={field.value || dayjs()}
      helperText={message}
    />
  );
};

export default FormTimeField;
