import React, { FC, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { CustomNumericFormat, FormInput, InputEvent } from "./index";

import { TextInputProps } from "./types";

const FormCashInput: FC<TextInputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setValue } = useFormContext();

  const onChange = ({ target }: InputEvent) => {
    setValue(props.name, target.value);
  };

  return (
    <FormInput
      defaultValue=""
      inputRef={inputRef}
      rules={{
        onChange,
      }}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: CustomNumericFormat as any,
      }}
      {...props}
    />
  );
};

export default FormCashInput;
