import { createContext, Dispatch, SetStateAction } from 'react'

enum Steps {
  monobankToken,
  terminalConfig,
}

interface AcquiringContextInterface {
  setStep: Dispatch<SetStateAction<Steps>>
  onCloseModal: () => void
}

const AcquiringContext = createContext<AcquiringContextInterface>({ setStep: () => null, onCloseModal: () => null });

export {
  Steps, AcquiringContext
};
