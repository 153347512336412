import React, { FC } from "react";
import { connect } from "react-redux";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { ReportTable, HeadCell, Row } from "components/Table";

import { ProductResponse } from "api";
import { actionCreators } from "store/Product";

interface ReduxProps {
  setSelectedProduct: (product: ProductResponse) => void;
}

interface Props {
  products: ProductResponse[];
  selected: Row[];
  onOpenModal: () => void;
  onSelectedChange?: (selected: Row[]) => void;
}

type AllProps = ReduxProps & Props;

const Table: FC<AllProps> = (props) => {
  const {
    products,
    selected,
    onSelectedChange,
    setSelectedProduct,
    onOpenModal,
  } = props;
  const columns: HeadCell[] = [
    {
      id: "name",
      label: "Назва",
      width: "70%",
      cell: (cell, row) => (
        <Link
          onClick={() => {
            setSelectedProduct(row as ProductResponse);
            onOpenModal();
          }}
          component="button"
          sx={{ textAlign: "left", textDecoration: "none" }}
        >
          {row.name}
        </Link>
      ),
    },
    {
      id: "code",
      label: "Код",
      width: "15%",
      cell: (value) => (
        <Typography variant="inherit" sx={{ overflowWrap: "anywhere" }}>
          {value}
        </Typography>
      ),
    },
    {
      id: "price",
      label: "Ціна, ₴",
      width: "15%",
      style: {
        whiteSpace: "nowrap",
      },
    },
  ];

  return (
    <ReportTable
      idName="productUid"
      rows={products}
      columns={columns}
      checkboxSelection
      selected={selected}
      onSelectedChange={onSelectedChange}
    />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setSelectedProduct: actionCreators.setSelectedProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
