import * as React from 'react';
import Phone from 'assets/images/phone-black.png';
import './index.scss';

const Confidentiality: React.FC = () => (
  <div className="confidentiality">
    <div className="confidentiality__text-wrapper">
      <h2 className="header-normal confidentiality__header">
        Ще не готові до
        серйозних змін?
        Тестовий режим!
      </h2>
      <span className="text-normal confidentiality__item">
        ПРРО в смартфоні ФОПкаса працює у двох режимах: фіскальному, який видає електронні чеки 
        та відразу надсилає їх у податкову, та в тестовому режимі. Тестовий режим ідеально підходить для тих, 
        хто хоче опанувати роботу з фіскалізацією та впевнитися в правильності виконання операцій перед початком 
        відправки результатів у ДПС, що є обов&apos;язковим для ведення бізнесу.
      </span>
    </div>
    <img className="confidentiality__image" alt="Phone" src={Phone}/>
  </div>
);

export default Confidentiality;
