import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import TabPanel from "components/TabPanel";

import PageTabs from "./PageTabs";
import ProductsTab from "./ProductsTabContent/ProductsTab";
import CategoryTab from "./CategoriesTabContent/CategoryTab";

import { actionCreators as productActions } from "store/Product";
import { actionCreators as categoriesActions } from "store/Category";

interface ReduxProps {
  getProducts: () => void;
  getProductUnit: () => void;
  getCategories: () => void;
}

const Products: React.FC<ReduxProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState("all_products");

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const { getProducts, getProductUnit, getCategories } = props;
    getProducts();
    getProductUnit();
    getCategories();
  }, []);

  return (
    <>
      <PageTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
      <TabPanel value={selectedTab} index="all_products">
        <ProductsTab />
      </TabPanel>
      <TabPanel value={selectedTab} index="all_categories">
        <CategoryTab />
      </TabPanel>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getProducts: productActions.getProducts,
  getProductUnit: productActions.getProductUnit,
  getCategories: categoriesActions.getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
