import React, { FC, useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Row } from "components/Table";
import ConfirmDeleteDialog from "./ConfirmDeleteModal/ConfirmDeleteDialog";

import { ReactComponent as IconBin } from "assets/images/icon_bin.svg";

import { actionCreators } from "store/Product";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface ReduxProps {
  deleteProducts: (
    productIds: number[],
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  getProducts: () => void;
}

interface Props {
  selected: Row[];
  onSelectedChange: (selected: Row[]) => void;
  clearValue: () => void;
}

type AllProps = ReduxProps & Props;

const ProductsBulkOperations: FC<AllProps> = (props) => {
  const { selected, onSelectedChange, clearValue } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  const onDelete = () => {
    setIsOpenModal(true);
  };

  const onConfirm = () => {
    setIsDeleteInProgress(true);
    const { deleteProducts, getProducts } = props;
    const productIds = selected.map((row) => row.productId);
    deleteProducts(
      productIds,
      () => {
        analytics().sendEvent(AnalyticsEvents.delete_product_btn_bulk);
        getProducts();
        onCloseModal();
        setIsDeleteInProgress(false);
        onSelectedChange([]);
        clearValue();
      },
      () => {
        onCloseModal();
        setIsDeleteInProgress(false);
        onSelectedChange([]);
        clearValue();
      }
    );
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <ConfirmDeleteDialog
        isOpenModal={isOpenModal}
        onCloseModal={onCloseModal}
        onConfirm={onConfirm}
        isDeleteInProgress={isDeleteInProgress}
      />
      <Box sx={styles.wrapper}>
        <Typography mr={1}>Обрано {selected.length} товари:</Typography>
        <Button
          startIcon={<IconBin />}
          onClick={onDelete}
          variant="text"
          color="error"
        >
          Видалити
        </Button>
      </Box>
    </>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    width: "100%",
    margin: "8px 0",
    padding: "8px 12px",
    backgroundColor: "white",
    alignItems: "center",
    borderRadius: "4px",
  },
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  deleteProducts: actionCreators.deleteProducts,
  getProducts: actionCreators.getProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsBulkOperations);
