import React from "react";
import { ReactComponent as MonoLogo } from "assets/images/mono-logo.svg";
import { ReactComponent as PrivatLogo } from "assets/images/privat-logo.svg";
import { ReactComponent as ABankLogo } from "assets/images/abank-logo.svg";
import { ReactComponent as PumbLogo } from "assets/images/pumb-logo.svg";
import { ReactComponent as SenseLogo } from "assets/images/sense-logo.svg";
import { ReactComponent as UkrGasLogo } from "assets/images/ukrgas-logo.svg";
import { ReactComponent as IziLogo } from "assets/images/izi-logo.svg";
import { ReactComponent as CreditDniproLogo } from "assets/images/credit-dnipro-logo.svg";
import { ReactComponent as GlobusLogo } from "assets/images/globus-logo.svg";
import { ReactComponent as AgricoleLogo } from "assets/images/agricole-logo.svg";
import { ReactComponent as OtpLogo } from "assets/images/otp-logo.svg";
import { ReactComponent as VostokLogo } from "assets/images/vostok-logo.svg";

export enum BankKeys {
  PREFERED_BANK = "PREFERED_BANK",
}

export interface Bank {
  logo: JSX.Element;
  name: string;
  shortName: string;
  packageAndroid: string;
  packageIOS: string;
}

export const banks: Bank[] = [
  {
    logo: <MonoLogo />,
    name: "Mono",
    shortName: "mono",
    packageAndroid: "com.ftband.mono",
    packageIOS: "mono", // com.ftband.mono
  },
  {
    logo: <PrivatLogo />,
    name: "Приват24",
    shortName: "privat24",
    packageAndroid: "ua.privatbank.ap24",
    packageIOS: "", // privat24new // ua.pb.privat24
  },
  {
    logo: <ABankLogo />,
    name: "Абанк",
    shortName: "abank",
    packageAndroid: "ua.com.abank",
    packageIOS: "", // abank24 // com.abank24.mobapplication
  },
  {
    logo: <PumbLogo />,
    name: "Пумб",
    shortName: "pumb",
    packageAndroid: "com.fuib.android.spot.online",
    packageIOS: "pumb-online.app", // fuib.pumb-ispot
  },
  {
    logo: <SenseLogo />,
    name: "Sense Bank",
    shortName: "sensebank",
    packageAndroid: "ua.alfabank.mobile.android",
    packageIOS: "", // ua.alfabank.mobile.ios
  },
  {
    logo: <UkrGasLogo />,
    name: "Укргазбанк",
    shortName: "ukrgasbank",
    packageAndroid: "com.ugb.app",
    packageIOS: "", // ecobank // ugb.ugb-banking-ios.release
  },
  {
    logo: <IziLogo />,
    name: "izibank",
    shortName: "izibank",
    packageAndroid: "ua.izibank.app",
    packageIOS: "", // izibank // izibank.ua.app
  },
  {
    logo: <CreditDniproLogo />,
    name: "Банк Кредит Дніпро",
    shortName: "creditdnepr",
    packageAndroid: "com.creditdnepr.mb",
    packageIOS: "", // freebank // com.creditdnepr.freebank
  },
  {
    logo: <GlobusLogo />,
    name: "Глобус Банк",
    shortName: "globus",
    packageAndroid: "com.t18.bone.personal.globus",
    packageIOS: "", // com.t18.bone.personal.globus
  },
  {
    logo: <AgricoleLogo />,
    name: "Креді Агріколь Банк",
    shortName: "creditagricole",
    packageAndroid: "ua.creditagricole.mobile.app",
    packageIOS: "", // ua.creditagricole.mobile.app
  },
  {
    logo: <OtpLogo />,
    name: "ОТП БАНК",
    shortName: "otpbank",
    packageAndroid: "ua.otpbank.android",
    packageIOS: "otpbankua", // ua.otpbank.ios
  },
  {
    logo: <VostokLogo />,
    name: "Банк Восток",
    shortName: "vostok",
    packageAndroid: "com.vostok.bv",
    packageIOS: "vostok", // com.vostok.BV
  },
];
