import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "containers/Sales/Table";

import { actionCreators } from "store/CashRegisterOverview";

const Sales: React.FC = () => {
  const dispatch = useDispatch();
  const registersOverview = useSelector(
    (state) => state.cashRegistersOverview.cashRegistersOverview
  );

  useEffect(() => {
    dispatch(actionCreators.getCashRegistersOverview());
  }, []);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h4" mt={3} mb={2} component="h1">
          Продажі
        </Typography>
      </Box>
      {registersOverview.map(({ pointOfSaleName, registers }, index) => {
        return (
          <Box key={pointOfSaleName + index}>
            <Typography variant="body2" mb={1}>
              Торгова точка:
            </Typography>
            <Typography variant="h3" mb={2}>
              {pointOfSaleName}
            </Typography>
            <Table registers={registers} />
          </Box>
        );
      })}
    </>
  );
};

export default Sales;
