import { Action, Reducer } from 'redux';
import { ApiError } from 'types';
import { CompanyApi } from '../api'
import { CompanyResponse, CompanyUpdateTelegramIntegrationEnabledRequest } from '../api/models'
import { AppThunkAction } from './';
import { actionCreators as notificationActions, SetNotificationAction } from './Notification';

const companyApi = new CompanyApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CompanyState {
  isLoading: boolean,
  company: CompanyResponse,
}

enum CompanyActionTypes {
  COMPANY_SET_IS_LOADING = 'COMPANY_SET_IS_LOADING',
  SET_COMPANY = 'SET_COMPANY'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCompanyAction { type: CompanyActionTypes.SET_COMPANY, payload: CompanyResponse }
export interface SetIsLoadingAction { type: CompanyActionTypes.COMPANY_SET_IS_LOADING, payload: boolean }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetCompanyAction | SetNotificationAction | SetIsLoadingAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setIsLoading: (payload: boolean): SetIsLoadingAction => ({ type: CompanyActionTypes.COMPANY_SET_IS_LOADING, payload }),
    setCompany: (payload: CompanyResponse): SetCompanyAction => ({ type: CompanyActionTypes.SET_COMPANY, payload }),
    getCompany: (): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        dispatch(actionCreators.setIsLoading(true));

        const { data } = await companyApi.apiCompanyGet();

        dispatch(actionCreators.setCompany(data));
      } catch ({ response }) {
        const { data } = response as ApiError

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      } finally {
        dispatch(actionCreators.setIsLoading(false));
      }
    },
    updateTelegramIntegration: (body: CompanyUpdateTelegramIntegrationEnabledRequest): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        dispatch(actionCreators.setIsLoading(true));

        await companyApi.apiCompanyEnableAllTelegramChatsPost(body);
        const { data } = await companyApi.apiCompanyGet();

        dispatch(actionCreators.setCompany(data));
      } catch ({ response }) {
        const { data } = response as ApiError

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      } finally {
        dispatch(actionCreators.setIsLoading(false));
      }
    },
    deleteCompany: (callBack?: (hasError: boolean) => void): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        dispatch(actionCreators.setIsLoading(true));

        await companyApi.apiCompanyDelete();

        if (callBack) {
          callBack(false);
        }

      } catch ({ response }) {
        const { data } = response as ApiError;

        if (callBack) {
          callBack(true);
        }

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      } finally {
        dispatch(actionCreators.setIsLoading(false));
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: CompanyState = { company: { telegramIntegrationEnabled: false }, isLoading: false };

export const reducer: Reducer<CompanyState> = (state: CompanyState = defaultState, incomingAction: Action): CompanyState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case CompanyActionTypes.SET_COMPANY:
            return { ...state, company: action.payload };
        case CompanyActionTypes.COMPANY_SET_IS_LOADING:
          return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};
