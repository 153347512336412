import * as React from "react";
import "./index.scss";

const Comparison: React.FC = () => (
  <div className="comparison" id="comparison">
    <div className="comparison__text-wrapper">
      <h2 className="header-normal comparison__header">
        Касові апарати чи ПРРО
      </h2>

      <span className="text-normal comparison__item__header">Доступність</span>
      <ul className="comparison__list">
        <li key="a1" className="text-normal comparison__item">
          Апаратні РРО працюють на ринку з 90-х років і поділяються на касові апарати
           (схожі на кнопкові калькулятори) та фіскальні реєстратори (ззовні схожі на принтери).
        </li>
        <li key="a2" className="text-normal comparison__item">
          1 серпня 2020 року набув чинності закон №128-IX, 
          який дозволив використовувати програмні РРО як альтернативу апаратним.
        </li>
      </ul>

      <span className="text-normal comparison__item__header">Ціна</span>
      <ul className="comparison__list">
        <li key="a1" className="text-normal comparison__item">
          Фізичний фіскальний апарат коштує 6000–20 000 грн., і 200-300 грн на
          місяць коштуватиме обслуговування.
        </li>
        <li key="a2" className="text-normal comparison__item">
          Гнучкий діапазон цін та функцій для комерційних ПРРО - це 150-400 грн
          на місяць, залежно від обраного функціоналу.
        </li>
      </ul>

      <span className="text-normal comparison__item__header">Строк служби</span>
      <ul className="comparison__list">
        <li key="a1" className="text-normal comparison__item">
          Фіскальні реєстратори можна використовувати не більше семи років,
          потім пристрій потрібно міняти.
        </li>
        <li key="a2" className="text-normal comparison__item">
          У ПРРО – необмежений термін служби.
        </li>
      </ul>

      <span className="text-normal comparison__item__header">
        Підтримка оновлень
      </span>
      <ul className="comparison__list">
        <li key="a1" className="text-normal comparison__item">
          Коли щось змінюється у законодавстві, фізичні апарати потрібно
          перепрошивати і, зазвичай, це платна послуга.
        </li>
        <li key="a2" className="text-normal comparison__item">
          ПРРО оновлювати легко, як інші програми у телефоні, обравши опцію
          &quot;Встановити оновлення&quot;.
        </li>
      </ul>

      <span className="text-normal comparison__item__header">Чеки</span>
      <ul className="comparison__list">
        <li key="a1" className="text-normal comparison__item">
          Фізичні реєстратори друкують лише паперові чеки. А це - принтер,
          стрічка, тонер та кошти на обслуговування.
        </li>
        <li key="a2" className="text-normal comparison__item">
          До пристрою з ПРРО можна підключити звичайний принтер або надіслати
          електронну версію чеку у месенджер, смс, на e-mail чи надавати у
          вигляді QR коду.
        </li>
      </ul>

      <span className="text-normal comparison__item__header">
        Простота та мобільність
      </span>
      <ul className="comparison__list">
        <li key="a1" className="text-normal comparison__item">
          Для роботи РРО зазвичай потрібне живлення, мережевий зв&apos;язок, вони займають більше робочого місця. 
          Для його налаштування потрібен спеціаліст.
        </li>
        <li key="a2" className="text-normal comparison__item">
          ПРРО, встановлене на телефон чи планшет, не вимагає постійного підключення до живлення, може використовувати безпровідний інтернет, не займає багато місця. 
          Підприємець сам може встановити та налаштувати дане програмне забезпечення.
        </li>
      </ul>
    </div>
  </div>
);

export default Comparison;
