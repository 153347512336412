import React, { useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Table from "./Table";
import { Row } from "components/Table";
import { SearchInput } from "components/SearchInput";
import ProductDialog from "../ProductsTabContent/ProductModal/ProductDialog";
import ProductsBulkOperations from "../ProductsTabContent/ProductsBulkOperations";

import { ReactComponent as IconPlus } from "assets/images/icon_plus.svg";
import { ReactComponent as IconArrowBack } from "assets/images/icon_arrow_back.svg";

import { CategoryResponse } from "api";
import { ApplicationState } from "store";
import { ProductsByCategories } from "store/Product";

import { filterProducts } from "../ProductsTabContent/filterProducts";

interface ReduxProps {
  categoryProducts: ProductsByCategories | null;
}

interface Props {
  category: CategoryResponse;
  setCategoryDetails: (category: CategoryResponse | null) => void;
}

type AllProps = ReduxProps & Props;

const CategoryProducts: React.FC<AllProps> = (props) => {
  const { category, categoryProducts, setCategoryDetails } = props;

  const [selected, setSelected] = useState<Row[]>([]);
  const [searchText, setSearchText] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onCloseModal = () => setIsOpenModal(false);
  const onOpenModal = () => setIsOpenModal(true);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const clearValue = () => {
    setSearchText("");
  };

  const onSelectedChange = (selected: Row[]) => {
    setSelected(selected);
  };

  const products = categoryProducts ? categoryProducts[category.name] : [];

  const productsFiltered = filterProducts(products, searchText);

  return (
    <>
      <ProductDialog
        {...{
          isOpenModal,
          onCloseModal,
          defaultCategoryId: category.categoryId,
        }}
      />
      <Box display="flex" mt={3} mb={2}>
        <Link
          display="flex"
          alignItems="center"
          underline="none"
          component="button"
          onClick={() => setCategoryDetails(null)}
        >
          <IconArrowBack />
          <Typography pl={1} mr={1}>
            Всі категорії
          </Typography>
        </Link>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "left", md: "center" }}
        mt={3}
        mb={2}
      >
        <Typography variant="h4" component="h1">
          {category.name}
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "start", sm: "center" }}
        >
          <SearchInput
            sx={styles.searchInput}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Пошук товару за назвою та кодом"
            clearValue={clearValue}
          />
          <Button
            sx={styles.addBtn}
            onClick={onOpenModal}
            size="large"
            startIcon={<IconPlus />}
          >
            Додати товар
          </Button>
        </Box>
      </Box>
      {selected.length ? (
        <ProductsBulkOperations
          selected={selected}
          onSelectedChange={onSelectedChange}
          clearValue={clearValue}
        />
      ) : null}
      <Table
        products={productsFiltered}
        onOpenModal={onOpenModal}
        selected={selected}
        onSelectedChange={onSelectedChange}
      />
    </>
  );
};

const styles = {
  searchInput: {
    minWidth: "300px",
    maxHeight: "40px",
    marginTop: "8px",
    marginRight: "16px",
  },
  addBtn: {
    marginTop: "8px",
  },
};

const mapStateToProps = ({ product }: ApplicationState) => ({
  categoryProducts: product.productsByCategories,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProducts);
