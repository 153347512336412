import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';
import { ReportTable, HeadCell } from 'components/Table';
import { useReportColumns } from 'hooks';
import { roundNumber } from 'shared/functions';

const headers: HeadCell[] = [
  {
    id: 'receiptNumber',
    label: '№',
    align: 'right',
    cell: (value, row) => <Link underline='none' target='_blank' href={row.receiptUrl}>{value}</Link>,
  },
  {
    id: 'amount',
    label: 'Сума чеку',
    align: 'right',
  },
  {
    id: 'paymentType',
    label: 'Тип оплати',
  },
  {
    id: 'productsCount',
    label: 'Кількість товарів',
    align: 'right',
  },
  {
    id: 'customerName',
    label: 'Ім’я клієнта',
    cell: (value) => value || '–',
  },
  {
    id: 'customerPhone',
    label: 'Телефон',
    cell: (value) => value || '–',
  },
  {
    id: 'dateTimeUtc',
    label: 'Дата і час',
    cell: (value) => dayjs.utc(value).local().format('DD/MM/YYYY HH:mm'),
  },
  {
    id: 'comment',
    label: 'Коментар',
    cell: (value) => value || '–',
  },
];

const ReceiptReport: React.FC = () => {
  const rows = useSelector(state => state.reports.receiptReport);
  const columns = useReportColumns(headers, rows);
  
  const { avgAmount, avgProductsCount } = useMemo(() => ({
    avgAmount: rows.length && roundNumber(rows.reduce((sum, { amount = 0 }) => sum + amount, 0) / rows.length),
    avgProductsCount: rows.length && roundNumber(rows.reduce((sum, { productsCount = 0 }) => sum + productsCount, 0) / rows.length),
  }), [rows]);

  const colSpan = useMemo(() => columns.findIndex(({ id }) => id == 'amount'), [columns]);

  return (
    <ReportTable
      idName='order'
      rows={rows}
      columns={columns}
      footer={() => (
        <TableRow>
          <TableCell colSpan={colSpan} />
          <TableCell align='right'>Середнє: {avgAmount}</TableCell>
          <TableCell />
          <TableCell align='right'>Середнє: {avgProductsCount}</TableCell>
          <TableCell colSpan={columns.length - colSpan - 3} />
        </TableRow>
      )}
    />
  )
};

export default ReceiptReport;
