import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import Loader from "components/Loader";
import WorkShiftReport from "containers/WorkSiftReportDoc/WorkShiftReport";
import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { actionCreators } from "store/WorkShift";
import { WorkShiftReportResponse } from "api";
import { Routes } from "shared/constants/routes";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

export enum ReportType {
  xReport = "xReport",
  zReport = "zReport",
}

interface Props {
  type: ReportType;
  isOpenModal: boolean;
  onCloseModal: () => void;
}

const WorkShiftReportDialog: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { type, isOpenModal, onCloseModal } = props;
  const workShift = useSelector(({ workShift }) => workShift.workShift);

  const [report, setReport] = useState<WorkShiftReportResponse | null>(null);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpenModal && workShift?.workShiftUid) {
      setIsReportLoading(true);
      dispatch(
        actionCreators.getXReport(
          workShift?.workShiftUid,
          (data) => {
            data && setReport(data);
            setIsReportLoading(false);
          },
          () => {
            setIsReportLoading(false);
          }
        )
      );
    }
    return () => setReport(null);
  }, [isOpenModal]);

  const closeWorkShift = () => {
    setIsLoading(true);
    analytics().sendEvent(AnalyticsEvents.sales_close_shift_modal_confirm);
    dispatch(
      actionCreators.closeWorkShift(
        workShift!.workShiftId,
        () => {
          history.push(Routes.sales);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      )
    );
  };

  const renderTitle = () => {
    switch (type) {
      case ReportType.xReport:
        return "Проміжний звіт";
      case ReportType.zReport:
        return "Закриття зміни";
    }
  };

  const renderActions = () => {
    switch (type) {
      case ReportType.xReport:
        return (
          <Button
            onClick={onCloseModal}
            size="large"
            variant="contained"
            color="inherit"
          >
            Закрити
          </Button>
        );
      case ReportType.zReport:
        return (
          <>
            <Button
              onClick={onCloseModal}
              size="large"
              variant="contained"
              color="inherit"
            >
              Скасувати
            </Button>
            <Button
              size="large"
              variant="contained"
              color="error"
              onClick={closeWorkShift}
              disabled={isLoading}
            >
              Сформувати Z-звіт та закрити зміну
            </Button>
          </>
        );
    }
  };

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { minHeight: 420 } }}
    >
      <DialogTitle>
        {renderTitle()}
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isReportLoading && <Loader />}
        <WorkShiftReport report={report} type={ReportType.xReport} />
      </DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </Dialog>
  );
};

export default WorkShiftReportDialog;
