import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import EasyPay from "assets/images/easy-pay.jpg";
import BankAccount from "assets/images/bank-account.jpg";
import Coins from "assets/images/coins.jpg";
import Repeat from "assets/images/repeat.jpg";

import { grey900, mainStyles } from "../styles";

const proofs = [
  {
    icon: EasyPay,
    bgColor: "#EFE6EC",
    title: "Легка оплата",
    text: "Для використання IBAN oplata не потрібно реєструвати ПРРО ФОПкаса.",
  },
  {
    icon: BankAccount,
    bgColor: "#E6EFEA",
    title: "Рахунок в будь-якому банку",
    text: "Використовуйте рахунок організації чи ФОП відкритий в будь-якому банку України.",
  },
  {
    icon: Coins,
    bgColor: "#EFECE6",
    title: "Безкоштовне використання",
    text: "Створюйте QR коди для швидких оплат повністю безкоштовно. Час дії QR коду необмежений.",
  },
  {
    icon: Repeat,
    bgColor: "#E3E4EA",
    title: "Повторення без меж",
    text: "Якщо сума та реквізити залишаються актуальними, ви можете надсилати QR код необмежену кількість разів.",
  },
];

const SocialProof: React.FC = () => {
  return (
    <Container sx={styles.socialBlock}>
      <Box sx={styles.topHeading}>
        <Typography sx={mainStyles.h2} mb={2.5}>
          Чому саме ми?
        </Typography>
        <Typography sx={mainStyles.h5}>
          Відчуйте поєднання зручності, безпеки та ефективності своїх операцій.
        </Typography>
      </Box>
      <Box sx={styles.proofsWrapper}>
        {proofs.map((proof, index) => (
          <Box
            key={index}
            sx={[styles.proof, { backgroundColor: proof.bgColor }]}
          >
            <img style={styles.image} src={proof.icon} />
            <Typography sx={mainStyles.h3} mb={1.5}>
              {proof.title}
            </Typography>
            <Typography sx={[mainStyles.body1, { color: grey900 }]}>
              {proof.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

const styles = {
  socialBlock: {
    textAlign: "center",
    paddingTop: { xs: "56px", sm: "120px" },
    paddingBottom: { xs: "56px", sm: "120px" },
  },
  image: {
    borderRadius: "100px",
    marginBottom: "20px",
  },
  topHeading: {
    textAlign: "center",
    marginBottom: "60px",
  },
  proofsWrapper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  proof: {
    width: { xs: "100%", sm: "48%" },
    textAlign: "left",
    borderRadius: "8px",
    padding: "20px",
    margin: "10px",
  },
};

export default SocialProof;
