export const AnalyticsEvents = {
  // components //
  cp_key_upload_choose_digital_key: 'cp_key_upload_choose_digital_key',
  // homepage //
  hp_try_for_free: 'hp_try_for_free',
  hp_call_me_back: 'hp_call_me_back',
  hp_login: 'hp_login',
  hp_registration: 'hp_registration',
  menu_login_to_myaccount: 'menu_login_to_myaccount',
  menu_registration: 'menu_registration',
  menu_login: 'menu_login',
  menu_about: 'menu_about',
  menu_organization: 'menu_organization',
  // login events //
  login_phone_verification_send_phone: 'login_phone_verif_send_phone',
  login_phone_verification_redirect_to_registration: 'login_phone_verif_redirect_to_reg',
  login_code_verification_send_code: 'login_code_verif_send_code',
  login_code_verification_redirect_to_registration: 'login_code_verif_redirect_to_reg',
  // registration events //
  registration_init: 'registration_init',
  registration_phone_provided: 'registration_phone_provided',
  registration_code_provided: 'registration_code_provided',
  registration_phone_verification_send_phone: 'registr_phone_verif_send_phone',
  registration_phone_verification_send_phone_go_back: 'registr_phone_verif_send_phone_back',
  registration_code_verification_send_code: 'registr_code_verif_send_code',
  registration_code_verification_go_back: 'registr_code_verif_go_back',
  // reports events //
  reports_on_export_excel: 'reports_on_export_excel',
  // sales events //
  sales_open_register_btn: 'sales_open_register_btn',
  sales_modal_app_btn: 'sales_modal_app_btn',
  sales_modal_close_btn: 'sales_modal_close_btn',
  sales_add_cash_btn: 'sales_add_cash_btn',
  sales_add_cash_modal_cancel: 'sales_add_cash_modal_cancel',
  sales_add_cash_modal_confirm: 'sales_add_cash_modal_confirm',
  sales_remove_cash_btn: 'sales_remove_cash_btn',
  sales_remove_cash_modal_cancel: 'sales_remove_cash_modal_cancel',
  sales_remove_cash_modal_confirm: 'sales_remove_cash_modal_confirm',
  sales_close_shift_btn: 'sales_close_shift_btn',
  sales_close_shift_modal_cancel: 'sales_close_shift_modal_cancel',
  sales_close_shift_modal_confirm: 'sales_close_shift_modal_confirm',
  sales_x_report_btn: 'sales_x_report_btn',
  sales_x_report_close: 'sales_x_report_close',
  sales_cancel_operation_btn: 'sales_cancel_operation_btn',
  sales_cancel_operation_modal_cancel: 'sales_cancel_operation_modal_cancel',
  sales_cancel_operation_modal_confirm: 'sales_cancel_operation_modal_confirm',
  sales_start_order_btn: 'sales_start_order_btn',
  sales_add_product_to_order: 'sales_add_product_to_order',
  sales_remove_product_from_order: 'sales_remove_product_from_order',
  sales_order_payment_btn: 'sales_order_payment_btn',
  sales_payment_modal_close_btn: 'sales_payment_modal_close_btn',
  sales_add_discount: 'sales_add_discount',
  sales_remove_discount: 'sales_remove_discount',
  sales_payment_type_cash: 'sales_payment_type_cash',
  sales_payment_type_card: 'sales_payment_type_card',
  sales_payment_type_mono_qr: 'sales_payment_type_mono_qr',
  sales_create_receipt: 'sales_create_receipt',
  sales_cancel_mono_qr: 'sales_cancel_mono_qr',
  sales_send_receipt_modal_btn: 'sales_send_receipt_modal_btn',
  sales_send_receipt: 'sales_send_receipt',
  // return //
  return_find_receipt: 'return_find_receipt',
  return_products_btn: 'return_products_btn',
  return_products: 'return_products',
  return_send_receipt: 'return_send_receipt',
  // iban events //
  iban_payment: 'iban_payment',
  // business structure events //
  business_structure_upload_key: 'bs_upload_key',
  business_structure_add_partner: 'bs_add_partner',
  business_structure_add_partner_modal_send_phone: 'bs_modal_add_partner_send_phone',
  business_structure_partner_info: 'bs_partner_info',
  business_structure_partner_info_modal_remove_partner: 'bs_partner_info_modal_remove',
  business_structure_partner_info_modal_cancel: 'bs_partner_info_modal_cancel',
  business_structure_modal_add_partner_send_phone_cancel: 'bs_modal_add_partner_send_phone_cnl',
  business_structure_modal_add_partner_code_verification: 'bs_modal_add_partner_code_verif',
  business_structure_modal_add_partner_code_verification_cancel: 'bs_modal_add_partner_code_verif_cnl',
  business_structure_welcome_modal_app_link_click: 'bs_modal_welcome_app_link_click',
  business_structure_welcome_modal_app_btn_click: 'bs_modal_welcome_app_btn_click',
  business_structure_welcome_modal_close: 'business_structure_welcome_modal_close',
  // cashiers //
  cashiers_cashier_edit_upload_key: 'cashiers_edit_upload_key',
  cashiers_cashier_edit_upload_key_cancel: 'cashiers_edit_upload_key_cnl',
  cashiers_cashier_edit_phone_verification_send_phone: 'cashiers_edit_phone_verif_send_phone',
  cashiers_cashier_edit_phone_verification_send_phone_cancel: 'cashiers_edit_phone_verif_send_phone_cnl',
  cashiers_cashier_edit_code_verification_send_code: 'cashiers_edit_code_verif_send_code',
  cashiers_cashier_edit_code_verification_send_code_cancel: 'cashiers_edit_code_verif_send_code_cnl',
  cashiers_cashier_edit_code_verification_send_code_go_back: 'cashiers_edit_code_verif_send_code_back',
   // accountants //
   accountants_add_accountant: 'accountants_add_accountant',
   accountants_add_accountant_save: 'accountants_add_accountant_save',
   accountants_add_accountant_cancel: 'accountants_add_accountant_cancel',
   accountants_accountant_info_modal: 'accountants_accountant_info_modal',
   accountants_accountant_info_modal_remove: 'accountants_accountant_info_modal_remove',
   accountants_accountant_info_modal_cancel: 'accountants_accountant_info_modal_cancel',
  // products events //
  products_export_products: 'products_export_products',
  products_import_products: 'products_import_products',
  add_product_btn: 'add_product_btn',
  add_product_btn_modal: 'add_product_btn_modal',
  edit_product_btn: 'edit_product_btn',
  edit_product_btn_modal: 'edit_product_btn_modal',
  delete_product_btn_modal: 'delete_product_btn_modal',
  delete_product_btn_bulk: 'delete_product_btn_bulk',
  add_category_btn: 'add_category_btn',
  add_category_btn_modal: 'add_category_btn_modal',
  edit_category_btn: 'edit_category_btn',
  edit_category_btn_modal: 'edit_category_btn_modal',
  delete_category_btn_modal: 'delete_category_btn_modal',

  // channels //
  channels_add_channel: 'tgch_add_channel',
  channels_add_channel_modal_add_channel: 'tgch_add_channel_modal_add_channel',
  channels_add_channel_modal_cancel: 'tgch_add_channel_modal_cancel',
  channels_edit_channel_modal_save_channel: 'tgch_edit_channel_modal_save_channel',
  channels_edit_channel_modal_cancel: 'tgch_edit_channel_modal_cancel',
  channels_edit_channel_modal_delete_channel: 'tgch_edit_channel_modal_delete_channel',
  // subscription events //
  subscription_payment_success: 'subscr_payment_success',
  subscription_payment_fail: 'subscr_payment_fail',
  subscription_payment_plan_free: 'subscription_payment_plan_free',
  subscription_payment_plan_start: 'subscription_payment_plan_start',
  subscription_payment_plan_success: 'subscription_payment_plan_success',
  subscription_payment_plan_successful_year: 'subscription_payment_plan_successful_yr',
  // settings //
  settings_work_shift_save_btn: 'settings_work_shift_save_btn',
  // iban //
  iban_oplata_download_app: 'iban_oplata_download_app',
  iban_oplata_download_app_ios: 'iban_oplata_download_app_ios',
  iban_oplata_download_app_andr: 'iban_oplata_download_app_andr',

  iban_qr_pay_default: 'iban_qr_pay_default',
  iban_qr_pay_: 'iban_qr_pay_',  
};
