import { createContext, Dispatch, SetStateAction } from "react";

enum Steps {
  edit,
  create,
}

interface ProductContextInterface {
  setStep: Dispatch<SetStateAction<Steps>>;
  onCloseModal: () => void;
}

const ProductContext = createContext<ProductContextInterface>({
  setStep: () => null,
  onCloseModal: () => null,
});

export { Steps, ProductContext };
