import React from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DeleteButton from "components/DeleteButton";
import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import * as Company from "store/Company";
import * as User from "store/User";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const DeleteModal: React.FC<IProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const deleteCallBack = (hasError: boolean) => {
    if (hasError) {
      onClose();
    } else {
      dispatch(User.actionCreators.clearAppData("/"));
    }
  };

  const onDeleteClick = () => {
    dispatch(Company.actionCreators.deleteCompany(deleteCallBack));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Видалити профіль
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight={300} paragraph>
          Ви збираєтесь назавжди видалити всі дані з ФОПкаса. Усі фіскальні дані
          надіслані в ДПС не можуть бути видалені з бази ДПС. Видалити назавжди
          дані з ФОПкаса?
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DeleteButton onClick={onDeleteClick} style={{ marginRight: 0 }}>
            <Typography fontSize={14}>Так</Typography>
          </DeleteButton>
          <Button onClick={onClose}>
            <Typography fontSize={14}>Ні</Typography>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
