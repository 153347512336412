import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import { Location } from "history";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Header from "components/Header";
import Footer from "components/Footer";
import OrderReceipt from "./OrderReceipt";
import CategoriesTabs from "./CategoriesTabs";
import Table from "containers/Sales/Order/Table";
import SideMenu from "components/Header/SideMenu";
import { SearchInput } from "components/SearchInput";
import OrderRefreshDialog from "./OrderRefreshModal/OrderRefreshDialog";

import { ReactComponent as IconArrowBack } from "assets/images/icon_arrow_back.svg";

import {
  CashRegisterOverviewResponse,
  CategoryResponse,
  UserRoleEnum,
  WorkShiftResponse,
} from "api";

import authService from "services/auth";

import { Routes } from "shared/constants/routes";
import { ApplicationState } from "store";
import { OrderProduct } from "store/Order";
import { actionCreators as userActions } from "store/User";
import {
  ProductsByCategories,
  actionCreators as productActions,
} from "store/Product";
import { actionCreators as orderActions } from "store/Order";
import { actionCreators as categoryActions } from "store/Category";
import { actionCreators as workShiftActions } from "store/WorkShift";
import { actionCreators as subscriptionActions } from "store/Subscription";
import { actionCreators as cashRegisterActions } from "store/CashRegisterOverview";

import { filterProducts } from "./filterProducts";

import theme from "theme";

interface Params {
  id: string;
}

interface ReduxProps {
  getUser: () => void;
  getProducts: () => void;
  getCategories: () => void;
  getWorkShift: (
    workShiftId: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  setWorkShift: (payload: WorkShiftResponse | null) => void;
  getSubscription: () => void;
  setCashRegisterOverview: (
    payload: CashRegisterOverviewResponse | null
  ) => void;
  getCashRegisterOverview: (cashRegisterId: number) => void;
  userRole: UserRoleEnum | undefined;
  orderProducts: OrderProduct[];
  workShift: WorkShiftResponse | null;
  products: ProductsByCategories | null;
  categories: CategoryResponse[];
  cashRegisterOverview: CashRegisterOverviewResponse | null;
}

const Order: React.FC<ReduxProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [path, setPath] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const { id } = useParams<Params>();

  const {
    userRole,
    orderProducts,
    workShift,
    products,
    categories,
    cashRegisterOverview,
  } = props;

  const productsFiltered = filterProducts(products, selectedTab, searchText);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const clearValue = () => {
    setSearchText("");
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (orderProducts.length > 0) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [orderProducts]);

  useEffect(() => {
    const unblock = history.block((location: Location) => {
      if (orderProducts.length > 0) {
        setShowRefreshModal(true);
        setPath(location.pathname);
        return false;
      }
      return undefined;
    });

    return () => {
      unblock();
    };
  }, [orderProducts, history]);

  useEffect(() => {
    if (Number(id)) {
      dispatch(workShiftActions.getWorkShift(Number(id)));
    }

    if (authService().getAccessToken()) {
      dispatch(userActions.getUser());
    }

    dispatch(productActions.getProducts());
    dispatch(categoryActions.getCategories());

    return () => {
      dispatch(workShiftActions.setWorkShift(null));
      dispatch(cashRegisterActions.setCashRegisterOverview(null));
      dispatch(orderActions.cleanOrder());
    };
  }, []);

  useEffect(() => {
    if (Number(workShift?.cashRegisterId)) {
      dispatch(
        cashRegisterActions.getCashRegisterOverview(
          Number(workShift?.cashRegisterId)
        )
      );
    }
  }, [workShift?.cashRegisterId]);

  useEffect(() => {
    if (authService().getAccessToken() && userRole === UserRoleEnum.Owner) {
      dispatch(subscriptionActions.getSubscription());
    }
  }, [userRole]);

  return (
    <>
      <OrderRefreshDialog
        path={path}
        isOpenModal={showRefreshModal}
        onCloseModal={() => setShowRefreshModal(false)}
      />
      <Header />
      <Container sx={styles.pageContainer} disableGutters>
        <SideMenu />

        <Container sx={styles.contentContainer}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" mt={3} mb={2}>
              <Link
                display="flex"
                alignItems="center"
                underline="none"
                component="button"
                onClick={() => history.replace(`${Routes.workShift}/${id}`)}
              >
                <IconArrowBack />
                <Typography pl={1} mr={1}>
                  {cashRegisterOverview?.name}
                </Typography>
              </Link>
              {cashRegisterOverview && !cashRegisterOverview?.fiscalNumber && (
                <Chip label="Нефіскальна" size="small" />
              )}
            </Box>
            <Typography variant="h4" mb={2}>
              Продаж товару
            </Typography>
          </Box>
          <CategoriesTabs
            categories={categories}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
          />
          <SearchInput
            fullWidth
            value={searchText}
            onChange={onSearchChange}
            placeholder="Пошук товару за назвою або кодом"
            clearValue={clearValue}
          />
          <Box mt={3}>
            <Table products={productsFiltered} />
          </Box>
        </Container>
        <OrderReceipt />
      </Container>

      <Footer />
    </>
  );
};

const styles = {
  tab: {
    "&.Mui-selected": {
      bgcolor: "inherit",
      border: "none",
      borderRadius: 0,
      borderBottom: `2px ${theme.colors.secondaryBlue} solid`,
    },
  },
  pageContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
  },
  contentContainer: {
    flexGrow: 1,
    overflow: "auto",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "solid 1px #A4C7D0",
    marginBottom: "24px",
  },
};

const mapStateToProps = ({
  user,
  order,
  workShift,
  product,
  category,
  cashRegistersOverview,
}: ApplicationState) => ({
  orderProducts: order.products,
  userRole: user.data.userRole,
  workShift: workShift.workShift,
  products: product.productsByCategories,
  categories: category.categories,
  cashRegisterOverview: cashRegistersOverview.cashRegisterOverview,
});

const mapDispatchToProps = {
  getUser: userActions.getUser,
  getProducts: productActions.getProducts,
  getCategories: categoryActions.getCategories,
  getWorkShift: workShiftActions.getWorkShift,
  setWorkShift: workShiftActions.setWorkShift,
  getSubscription: subscriptionActions.getSubscription,
  setCashRegisterOverview: cashRegisterActions.setCashRegisterOverview,
  getCashRegisterOverview: cashRegisterActions.getCashRegisterOverview,
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
