import React, { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import ReactPixel from "react-facebook-pixel";

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';

import { FormPhoneInput } from 'components/FormInput';

import { ReactComponent as DeleteInput } from 'assets/images/icon_delete_input.svg';

import {
    removeSpacesFormString,
} from 'shared/functions';

import { actionCreators } from 'store/Registration';
import { SendCodeRequest } from 'api/models';
import { ApplicationState } from 'store';
import { Routes } from 'shared/constants/routes';

import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';
import { FbPixelAnalyticsEvents } from 'shared/constants/fb-pixel-analytics';

const styles = {
  input: {
    '& .MuiOutlinedInput-input': {
      fontSize: '16px',
    },
  },
};

interface VWOSettings {
  dataStore: {
    campaigns: {
      [key: string]: {
        combination_chosen: string
            }
        }
    }
}

declare global {
  interface Window {
    VWO: VWOSettings;
  }
}

type FormValues = {
  phoneNumber: string;
};

interface ReduxProps {
  getRegistrationCode: (data: SendCodeRequest, callBack: () => void) => void,
  removeErrors: () => void,
  phoneNumberError: string
}

const PhoneNumber: FC<ReduxProps> = props => {
  const { phoneNumberError } = props;
  const history = useHistory();
  const location = useLocation();

  const methods = useForm<FormValues>({
    mode: 'onChange'
  });

  const params = new URLSearchParams(location.search);
  const redirectRoute = location.state?.from || params.get("ref");

  useEffect(() => {
    ReactPixel.track(FbPixelAnalyticsEvents.registration_init);
    analytics().sendEvent(AnalyticsEvents.registration_init);
  }, []);

  useEffect(() => {
    if (phoneNumberError) {
      methods.setError('phoneNumber', { message: phoneNumberError })
    }
    props.removeErrors();
  }, [phoneNumberError]);

  const onSubmit = (data: FormValues) => {
    props.getRegistrationCode(
      { phone: removeSpacesFormString(data.phoneNumber) },
      () => {
        history.push({
          pathname: Routes.registrationPhoneVerification,
          state: { from: redirectRoute }
        });
        ReactPixel.track(FbPixelAnalyticsEvents.registration_phone_provided);
        analytics().sendEvent(AnalyticsEvents.registration_phone_provided);
      });
    analytics().sendEvent(AnalyticsEvents.registration_phone_verification_send_phone);
  };

  const clearValue = () => {
    methods.setValue('phoneNumber', '+380');
    methods.clearErrors();
  };


  return (
    <Card sx={{ width: 648, margin: '0 20px' }}>
      <CardHeader title='Створення профілю ФОПкаса' />
      <CardContent>
        <Typography mt={1} color={({ colors }) => colors.secondaryGrey} fontWeight='lighter'>
          Щоб спробувати тестовий режим нам потрібно мінімум інформації - лише Ваш номер телефону для авторизації в ФОПкасі.<br/><br/>
        </Typography>
        <FormProvider {...methods}>
          <Typography
            ml={2}
            mb={.5}
            color={({ colors }) => colors.secondaryGrey}
            fontSize='0.8571428571428571rem'>
            МОБІЛЬНИЙ НОМЕР ТЕЛЕФОНУ:
          </Typography>
          <FormPhoneInput
            fullWidth
            name='phoneNumber'
            sx={styles.input}
            InputProps={{
              endAdornment: <DeleteInput style={{ cursor: 'pointer' }} onClick={clearValue} />
                          }}
            autoFocus={true}
          />
        </FormProvider>
        <Typography mt={1} color={({ colors }) => colors.secondaryGrey} fontWeight='lighter'>
          <><br/>Ми не надсилаємо рекламних матеріалів і не передаємо Ваш номер іншим організаціям.</>
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => {
            history.goBack();
            analytics().sendEvent(AnalyticsEvents.registration_phone_verification_send_phone_go_back);
          }}
          size='large'
          variant='contained'
          color='inherit'
        >
          Назад
        </Button>
        <Box marginLeft='auto'>
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            disabled={
              !methods.getValues('phoneNumber') ||
              methods.getValues('phoneNumber') === '+380' ||
              !!Object.keys(methods.formState.errors).length
            }
            size='large'
            variant='contained'
          >
            Продовжити
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = ({ registration: { errors } }: ApplicationState) => ({
  phoneNumberError: errors.phoneNumber
});

const mapDispatchToProps = {
  getRegistrationCode: actionCreators.getRegistrationCode,
  removeErrors: actionCreators.removeErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber)