import React, { useState } from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { actionCreators } from "store/WorkShift";
import { ApplicationState } from "store";
import {
  CancelWorkShiftOperationRequest,
  WorkShiftOperationResponse,
  WorkShiftResponse,
} from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface IProps {
  open: boolean;
  onClose: () => void;
}

interface ReduxProps {
  cancelOperation: (
    data: CancelWorkShiftOperationRequest,
    onSucess?: () => void,
    onError?: () => void
  ) => void;
  getWorkShift: (workShiftId: number) => void;
  getWorkShiftOperations: (workShiftUid: string) => void;
  setSelectedOperation: (payload: WorkShiftOperationResponse | null) => void;
  workShift: WorkShiftResponse | null;
  selectedOperation: WorkShiftOperationResponse | null;
}

const CancelOperationModal: React.FC<IProps & ReduxProps> = (props) => {
  const { open, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onCancelOperation = () => {
    setIsLoading(true);
    const { workShift, selectedOperation } = props;
    const {
      cancelOperation,
      getWorkShift,
      getWorkShiftOperations,
      setSelectedOperation,
    } = props;

    const workShiftUid = workShift?.workShiftUid || "";
    const workShiftId = workShift?.workShiftId || 0;

    cancelOperation(
      {
        workShiftUid,
        operationUid: selectedOperation?.operationUid,
        operationType: selectedOperation?.operationType,
      },
      () => {
        getWorkShift(workShiftId);
        getWorkShiftOperations(workShiftUid);
        analytics().sendEvent(
          AnalyticsEvents.sales_cancel_operation_modal_confirm
        );
        onClose();
        setIsLoading(false);
        setSelectedOperation(null);
      },
      () => {
        onClose();
        setIsLoading(false);
        setSelectedOperation(null);
      }
    );
  };

  const onCloseModal = () => {
    analytics().sendEvent(AnalyticsEvents.sales_cancel_operation_modal_cancel);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Скасування операції
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight={300} paragraph>
          Ви дійсно хочете скасувати операцію?
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button onClick={onCloseModal} size="large" variant="contained">
            <Typography fontSize={14}>Ні</Typography>
          </Button>
          <LoadingButton
            onClick={onCancelOperation}
            size="large"
            loading={isLoading}
            variant="contained"
          >
            <Typography fontSize={14}>Так</Typography>
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  workShift: state.workShift.workShift,
  selectedOperation: state.workShift.selectedOperation,
});

const mapDispatchToProps = {
  cancelOperation: actionCreators.cancelOperation,
  getWorkShift: actionCreators.getWorkShift,
  getWorkShiftOperations: actionCreators.getWorkShiftOperations,
  setSelectedOperation: actionCreators.setSelectedOperation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelOperationModal);
