import React, { useMemo } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";

import Table from "./Table";
import { Row } from "components/Table";

import { ReceiptResponse } from "api";

import { getPriceFormat } from "shared/functions";

interface Props {
  receipt: ReceiptResponse | null;
  selected: Row[];
  setSelected: (selected: Row[]) => void;
  onReturnProducts: () => void;
  isReceiptReturnLoading: boolean;
}

const ReceiptReturnDetails: React.FC<Props> = (props) => {
  const {
    receipt,
    selected,
    isReceiptReturnLoading,
    setSelected,
    onReturnProducts,
  } = props;

  const returnTotal = useMemo(
    () =>
      selected.reduce(
        (acc, item) => (item.quantity || 0) * (item.price || 0) + acc,
        0
      ),
    [selected]
  );

  return (
    <>
      <Box sx={styles.blocksWrapper}>
        <Paper sx={styles.paperContainer} variant="outlined">
          <Box mb={1}>
            <Typography variant="body2">Виданий:</Typography>
            <Typography variant="body1">
              {dayjs
                .utc(receipt?.receiptTimeUtc)
                .local()
                .format("DD.MM.YYYY, HH:mm:ss")}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="body2">Касир:</Typography>
            <Typography variant="body1">{receipt?.cashierName}</Typography>
          </Box>
        </Paper>
        <Paper sx={styles.paperContainer} variant="outlined">
          <Box mb={1}>
            <Typography variant="body2">ФН ПРРО:</Typography>
            <Typography variant="body1">
              {receipt?.cashRegisterFiscalNumber || "-"}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="body2">ФІСКАЛЬНИЙ ЧЕК:</Typography>
            <Typography variant="body1">
              {receipt?.fiscalNumber || "-"}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="body2">Тип оплати:</Typography>
            <Typography variant="body1">
              {receipt?.isCashPayment ? "Готівка" : "Картка"}
            </Typography>
          </Box>
        </Paper>
        <Paper sx={styles.paperContainer} variant="outlined">
          <Box display="flex" flexDirection="column" m="16px 0">
            <Box display="flex" flexDirection="column" mb={4}>
              <Typography variant="h3" mr={1.5}>
                Сума повернення:
              </Typography>
              <Typography variant="h2" fontSize="24px" mt={2}>
                {getPriceFormat(returnTotal)} ₴
              </Typography>
            </Box>
            <LoadingButton
              sx={{ marginRight: "auto" }}
              size="large"
              variant="contained"
              onClick={onReturnProducts}
              loading={isReceiptReturnLoading}
            >
              Повернути обрані товари
            </LoadingButton>
          </Box>
        </Paper>
      </Box>
      <Table receipt={receipt!} selected={selected} setSelected={setSelected} />
    </>
  );
};

const styles = {
  blocksWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: { xs: "space-between", md: "space-between" },
    m: "16px 0",
  },
  paperContainer: {
    mb: { xs: "16px", md: "0" },
    p: "16px",
    width: { xs: "80%", sm: "48%", md: "32%" },
  },
};

export default ReceiptReturnDetails;
