import { Action, Reducer } from 'redux';
import { AuthenticationApi } from 'api'
import { AppThunkAction } from './';
import { CheckCodeRequest, SendCodeRequest, TokenResponse } from 'api/models';

import authService from 'services/auth';
import analyticsService from 'services/analytics';
import { actionCreators as userActionCreators, SetAuthorizedAction } from 'store/User';
import { ApiError } from "../types";

const authenticationApi = new AuthenticationApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

enum ErrorFieldName {
    phoneNumber = 'phoneNumber',
    code = 'code'
}

interface ErrorPayload {
    field: ErrorFieldName,
    message: string
}

export interface AuthenticationState {
    phoneNumber: string;
    errors: {
        phoneNumber: string;
        code: string;
    }
}

enum AuthenticationActionTypes {
    LOGIN_SET_PHONE_NUMBER = 'LOGIN_SET_PHONE_NUMBER',
    LOGIN_GET_AUTH_CODE = 'LOGIN_GET_AUTH_CODE',
    LOGIN_SEND_AUTH_CODE = 'LOGIN_SEND_AUTH_CODE',
    LOGIN_SET_PHONE_NUMBER_ERROR = 'LOGIN_SET_PHONE_NUMBER_ERROR',
    LOGIN_SET_CODE_ERROR = 'LOGIN_SET_CODE_ERROR',
    LOGIN_REMOVE_ERRORS = 'LOGIN_REMOVE_ERRORS'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginSetPhoneNumberAction { type: AuthenticationActionTypes.LOGIN_SET_PHONE_NUMBER, payload: SendCodeRequest }
export interface LoginGetAuthCodeAction { type: AuthenticationActionTypes.LOGIN_GET_AUTH_CODE, payload: SendCodeRequest, callBack: () => void }
export interface LoginSendAuthCodeAction { type: AuthenticationActionTypes.LOGIN_SEND_AUTH_CODE, payload: CheckCodeRequest, callBack: () => void }
export interface LoginSetPhoneNumberErrorAction { type: AuthenticationActionTypes.LOGIN_SET_PHONE_NUMBER_ERROR, payload: ErrorPayload }
export interface LoginSetCodeErrorAction { type: AuthenticationActionTypes.LOGIN_SET_CODE_ERROR, payload: ErrorPayload }
export interface LoginRemoveErrors { type: AuthenticationActionTypes.LOGIN_REMOVE_ERRORS }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    LoginSetPhoneNumberAction |
    LoginGetAuthCodeAction |
    LoginSendAuthCodeAction |
    LoginSetPhoneNumberErrorAction |
    LoginSetCodeErrorAction |
    LoginRemoveErrors |
    SetAuthorizedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setPhoneNumber: (payload: SendCodeRequest): LoginSetPhoneNumberAction => ({ type: AuthenticationActionTypes.LOGIN_SET_PHONE_NUMBER, payload }),
    sendAuthCode: (payload: CheckCodeRequest, callBack: (data?: TokenResponse) => void): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            const { data } = await authenticationApi.apiAuthenticationAuthenticatePost(payload);
            const { accessToken, refreshToken, userUid = '' } = data;

            authService().setAccessToken(accessToken || '');
            authService().setRefreshToken(refreshToken || '');
            analyticsService().setUserId(userUid);
            dispatch(userActionCreators.setAuthorized(true));
            callBack(data);
        } catch ({ response }) {
            const { data } = response as ApiError;

            dispatch(actionCreators.setCodeError({ message: data.title, field: ErrorFieldName.code }));
        }
    },
    getAuthCode: (payload: SendCodeRequest, callBack: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            await authenticationApi.apiAuthenticationSendCodePost(payload);
            dispatch(actionCreators.setPhoneNumber(payload));
            callBack();
        } catch ({ response }) {
            const { data } = response as ApiError;
            dispatch(actionCreators.setPhoneNumberError({ message: data.title, field: ErrorFieldName.phoneNumber }));
        }
    },
    setPhoneNumberError: (payload: ErrorPayload): LoginSetPhoneNumberErrorAction => ({ type: AuthenticationActionTypes.LOGIN_SET_PHONE_NUMBER_ERROR, payload }),
    setCodeError: (payload: ErrorPayload): LoginSetCodeErrorAction => ({ type: AuthenticationActionTypes.LOGIN_SET_CODE_ERROR, payload }),
    removeErrors: (): LoginRemoveErrors => ({ type: AuthenticationActionTypes.LOGIN_REMOVE_ERRORS })
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: AuthenticationState = {
    phoneNumber: '',
    errors: {
        phoneNumber: '',
        code: ''
    }

};

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState = defaultState, incomingAction: Action): AuthenticationState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case AuthenticationActionTypes.LOGIN_SET_PHONE_NUMBER:
            return { ...state, phoneNumber: action.payload.phone };
        case AuthenticationActionTypes.LOGIN_SET_CODE_ERROR:
        case AuthenticationActionTypes.LOGIN_SET_PHONE_NUMBER_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.field]: action.payload.message
                }
            };
        case AuthenticationActionTypes.LOGIN_REMOVE_ERRORS:
            return { ...state, errors: defaultState.errors }
        default:
            return state;
    }
};
