import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import Edit from "./Edit";
import Create from "./Create";

import { ProductContext, Steps } from "contexts/ProductContext";
import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { ProductResponse } from "api";
import { ApplicationState } from "store";

interface ReduxProps {
  selectedProduct: ProductResponse | null;
}

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
  defaultCategoryId?: number;
}

type AllProps = ReduxProps & Props;

const ProductDialog: FC<AllProps> = (props) => {
  const { selectedProduct, isOpenModal, onCloseModal, defaultCategoryId } =
    props;
  const [step, setStep] = useState(Steps.create);
  const isEdit = !!selectedProduct;

  useEffect(() => {
    isEdit ? setStep(Steps.edit) : setStep(Steps.create);
  }, [isOpenModal]);

  const renderStep = () => {
    switch (step) {
      case Steps.create:
        return <Create defaultCategoryId={defaultCategoryId} />;
      case Steps.edit:
        return <Edit />;
    }
  };

  return (
    <Dialog open={isOpenModal} fullWidth PaperProps={{ sx: styles.dialog }}>
      <DialogTitle>
        {step === Steps.create ? "Додати новий товар" : " Редагувати товар"}
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={styles.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ProductContext.Provider value={{ setStep, onCloseModal }}>
        {renderStep()}
      </ProductContext.Provider>
    </Dialog>
  );
};

const styles = {
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 12,
  },
  dialog: { minHeight: 420 },
};

const mapStateToProps = ({ product }: ApplicationState) => ({
  selectedProduct: product.selectedProduct,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDialog);
